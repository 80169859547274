import React from "react";
import {Tag} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import i18n from "i18next";

const isPublishedCell = (row: any) => {
    switch (row?.is_published?.value) {
        case true: {
            return <Tag icon={<CheckCircleOutlined/>} color="success">
                {i18n.t("publish.published")}
            </Tag>
        }
        case false: {
            return <Tag icon={<CloseCircleOutlined/>} color="error">
                {i18n.t("publish.draft")}
            </Tag>
        }
        default: {
            return <></>
        }
    }
}

const Cells = {
    byID: {
        "is_published": isPublishedCell
    }
}

export {Cells}