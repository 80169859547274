import React from 'react'
import {Flex, Button, Card, Drawer, Input, Badge, Affix, Tag} from "antd";
import {
    SearchOutlined,
    SettingOutlined,
    FilterOutlined
} from "@ant-design/icons";
import i18n from "i18next";
import {Request} from "kaiju.core";
import {useOutletContext, useParams, useNavigate, generatePath} from "react-router-dom";
import {Table} from "src/components/Table";
import {ROUTE} from "src/routing";
import Plugin from "src/plugin";
import {ConditionFilters} from 'src/components/ConditionFilters'
import {ColumnContent, useColumns} from 'src/components/Columns'
import {isPermitted, Permissions} from 'src/components/Permissions'
import {FiltersComponent} from './filters'
import {HeaderComponent} from "src/components/Header";
import {Actions} from "./Actions";
import {CreateItem} from '../CreateItem'
import {Cells} from './Cells'
import {BulkActions} from "./BulkActions"

interface Params {
    contentId: string,
    appId: string,
}

interface SelectedProps {
    allSelected: boolean,
    id: string[],
    count: number
}

const ContentListPage: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [openColumns, setOpenColumns] = React.useState<boolean>(false)
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)
    const [selectedState, setSelectedState] = React.useState<SelectedProps>({
        allSelected: false,
        id: [],
        count: 0,
    })
    const {breadcrumbs, builderStore, contentData} = useOutletContext<{
        breadcrumbs: any,
        builderStore: any,
        contentData: any
    }>()
    const params = useParams<keyof Params>() as Params
    const current = builderStore.data.collection.filter((el: any) => (el._id === (params.contentId ? params.contentId : undefined)))[0]
    const navigate = useNavigate()

    const store = Table.useStore(params.contentId, {
        request: {
            method: "ContentGui.item.grid",
            params: {
                content: params.contentId,
            }
        },
        onClick: (row: any) => {
            if (isPermitted(params.contentId, ["update"])) {
                const key = Object.keys(row)
                navigate(generatePath(ROUTE.ContentItem, {...params, itemId: row[key[0]]._id}))
            }
        },
        onSelectedRows: ({allSelected, selectedRows, selectedCount}) => {
            setSelectedState({
                count: selectedCount,
                allSelected: allSelected,
                id: selectedRows.map((row: any) => {
                    const id = Object.keys(row)[0]
                    return row[id]._id
                })
            })
        },
        onColumnOrderChange: (newColumns) => {
            Request("ContentGui.column.user.set", {
                content: params.contentId,
                columns: newColumns
            }).then(() => {

            })
        }

    })

    const filterStore = ConditionFilters.useFilterStore({data: []})

    const columnStore = useColumns({
        id: params.contentId,
        loadRequest: {
            method: "ContentGui.column.list",
            params: {content: params.contentId}
        },
        loadCurrentRequest: {
            method: "ContentGui.column.current.get",
            params: {content: params.contentId}
        },
        onChange: (newColumns) => {
            Request("ContentGui.column.user.set", {
                content: params.contentId,
                columns: newColumns
            }).then(() => {
                store.fetchData(true)
            })
        }
    })
    const extraButtons: any = []
    const extraFilters: any = []
    Plugin.invoke("collection.extraButtons", extraButtons, contentData, store)
    Plugin.invoke("collection.extraFilters", extraFilters, contentData, store)
    const onCreate = () => {
        store.fetchData()
        setOpen(false)
    }

    const useActions = (row: any, index: number, column: any, id: any) => {
        return <Actions collectionId={params.contentId} row={row} column={column} id={id} index={index} store={store}/>
    }

    const bulkActionCallback = () => {
        store.fetchData()
        setSelectedState({
            allSelected: false,
            count: 0,
            id: []
        })
    }

    return (
        <>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs
            ]}/>
            <Flex justify={"space-between"} align={"center"} style={{paddingTop: 0, paddingBottom: 20}}>
                <h1 style={{marginBottom: 0, marginTop: 0}}>{current ? current.label : "[...]"}</h1>

                <Flex gap={"small"}>
                    {extraButtons.map((Comp: React.FC) => (Comp))}
                    <Permissions root={params.contentId} permissions={["create"]}>

                        <Button type={"primary"} onClick={() => {
                            setOpen(true)
                        }}>{i18n.t("item.create")}</Button>

                    </Permissions>
                </Flex>

            </Flex>

            <Flex justify={"space-between"}>
                <div style={{paddingBottom: 20, maxWidth: 500}}>
                    <Input prefix={<SearchOutlined/>} style={{maxWidth: 500}} onChange={(event: any) => {
                        store.querySearch(event.currentTarget.value)
                    }} placeholder={i18n.t("common.search")}/>
                </div>
                <Flex wrap gap="small">
                    {extraFilters.map((Comp: React.FC) => (Comp))}

                    <Badge count={filterStore.data?.length}>
                        <Button onClick={() => {
                            setOpenFilters(true)
                        }} icon={<FilterOutlined/>}/>
                    </Badge>

                    <Button onClick={() => {
                        setOpenColumns(true)
                    }} icon={<SettingOutlined/>}/>
                </Flex>

            </Flex>
            <Card>
                <Table store={store} cells={Cells} actions={useActions}/>
            </Card>

            {params.contentId &&
                <Permissions root={params.contentId} permissions={["create"]}>
                    <CreateItem appId={params.appId} contentId={params.contentId} collectionData={contentData}
                                open={open} onClose={() => {
                        setOpen(false)
                    }} onCreate={onCreate}/>
                </Permissions>
            }

            <Drawer
                key={"column-" + params.contentId}
                title={i18n.t("column.select-columns")}
                width={500}
                onClose={() => {
                    setOpenColumns(false)
                }}
                open={openColumns}
                extra={
                    <div>
                        <Button type={"default"} onClick={columnStore.reset}>
                            {i18n.t("column.reset-default")}
                        </Button>
                    </div>
                }
            >
                <ColumnContent key={"column-content-" + columnStore.id} store={columnStore}/>
            </Drawer>
            <FiltersComponent key={"filter-" + params.contentId} setFilter={(filters: any[]) => {
                store.addFilters(filters)
            }} params={{content: params.contentId,}} filterStore={filterStore} openFilters={openFilters}
                              setOpenFilters={setOpenFilters}/>
            <BulkActions collectionId={params.contentId} appId={params.appId} selectedState={selectedState}
                         successCallback={bulkActionCallback}/>
        </>
    )
}

export {ContentListPage}
