import {ManufacturerFilter} from "./Filters"

import filters from "core/src/components/ConditionFilters/types"


export const getFilters = () => {
    filters["manufacturer"] = ManufacturerFilter
}


