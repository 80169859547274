import React from 'react'
import i18next from "i18next";
import {Form, Request} from 'kaiju.core'
import {App, Divider, Modal} from 'antd';
import i18n from "i18next";
import {useParams} from "react-router-dom";


export const ComponentGroupCreate: React.FC<{ open: boolean, onClose: any, onCreate: Function }> = ({
                                                                                                        open,
                                                                                                        onClose,
                                                                                                        onCreate
                                                                                                    }) => {
    const {appId} = useParams();

    const {notification} = App.useApp()
    const FormConf = {
        fields: [{
            id: "create",
            fields: [
                {
                    id: "label",
                    kind: "string",
                    required: true,
                    max: 36,
                    label: i18n.t("common.label")
                },
            ]
        }],
        disableGroups: true,
        onSubmit: (values: any, formikState: any) => {
            Request('ContentTypeBuilder.component.group.create', {
                ...values,
                application: appId,
            }).then((data: any) => {
                const error = data.error;
                if (error) {
                    const code = error.code;
                    const message = i18n.t("component.error.duplicate");
                    if (code === 409) {
                        formikState.setFieldError("id", message)
                        notification.error({
                            message: message,
                            placement: 'topRight',
                            duration: 2
                        })
                    }
                } else {
                    notification.success({
                        message: i18n.t('common.created'),
                        placement: 'topRight',
                    });
                    onCreate(data)
                    close()
                }
            })
        }
    };

    const formStore = Form.useFormStore(FormConf)
    const close = () => {
        onClose()
        formStore.reset({})
    }

    return (
        <>
            <Modal title={i18next.t("component.create-new-group")} open={open} onOk={formStore.submit} onCancel={close}
                   okText={i18next.t("common.create")} cancelText={i18next.t("common.cancel")}>
                <Divider/>
                <Form store={formStore} key={"content-create"}/>
            </Modal>
        </>
    )
}
