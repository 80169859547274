import {redirect} from "react-router-dom";
import {LoadI18n} from 'src/loaders/i18n'
import user from 'src/components/User'

const signInLoader = async (props: any) => {
    const url = new URL(props.request.url);
    const redirectTo = url.searchParams.get("redirectTo") || '/';
    await user.setData(()=> {})
    if (user.isAuth){
        return redirect(redirectTo)
    }
    await LoadI18n();
    return {}
};

export {signInLoader};