import React from 'react'
import {Layout} from "antd";
import {useOutletContext, useParams, Outlet} from "react-router-dom";
import {ContentTypeBuilder, useStore} from "src/components/ContentTypeBuilder";
import user from "src/components/User"
import {Permission403} from "src/components/Permissions";
import './style.scss'

const ContentTypeBuilderPage: React.FC = () => {
    const {breadcrumbs} = useOutletContext<any>();
    const {appId} = useParams<{appId: string}>()
    const [collapsed, setCollapsed] = React.useState(false);
    const store = useStore({appId})

    return (<Permission403 permissions={[]} root={""}>
        <Layout
            style={{background: "wite", borderRadius: "5px"}} className={"h-100"}
        >
            <ContentTypeBuilder store={store} appId={appId}/>
            <Layout.Content className={"settings-content"}>
                <Outlet context={{breadcrumbs, builderStore: store}}/>
            </Layout.Content>
        </Layout>


    </Permission403>)
}

export {ContentTypeBuilderPage}