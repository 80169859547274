import React from "react";
import Icon from '@ant-design/icons';
import type {GetProps} from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const RepairSvg = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="1em" height="1em" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="currentColor" stroke="none">
                <path d="M1045 5109 c-142 -19 -338 -85 -426 -143 -47 -31 -75 -97 -64 -153 6
-33 48 -79 320 -353 270 -272 314 -320 320 -352 22 -118 -65 -205 -182 -183
-33 6 -81 50 -353 320 -350 348 -345 345 -436 310 -53 -20 -79 -55 -128 -171
-135 -322 -125 -683 26 -989 63 -127 125 -213 228 -316 212 -214 463 -327 780
-354 77 -7 233 6 328 27 l53 11 47 -46 47 -47 -717 -717 c-542 -544 -727 -735
-761 -788 -49 -77 -87 -162 -108 -246 -18 -68 -18 -278 0 -339 80 -280 259
-466 531 -551 63 -20 96 -24 205 -23 120 0 137 3 225 33 185 63 174 53 968
845 l722 721 47 -47 46 -47 -11 -53 c-38 -173 -35 -383 7 -550 89 -347 315
-626 636 -786 168 -84 317 -117 525 -117 201 0 342 32 517 116 85 41 115 71
128 127 15 73 10 80 -320 412 -270 272 -314 320 -320 352 -22 118 65 205 182
183 33 -6 81 -50 353 -320 350 -348 345 -345 436 -310 53 20 79 55 128 171
194 463 84 988 -279 1333 -164 155 -376 264 -602 307 -119 23 -320 23 -442 -1
l-93 -17 -149 148 c-82 82 -149 153 -149 159 0 6 182 192 405 415 l405 405 57
-56 c82 -79 99 -89 161 -89 48 0 57 4 91 38 56 53 681 1101 688 1152 3 23 -1
50 -11 72 -9 18 -123 140 -254 270 -207 207 -242 237 -276 244 -24 4 -52 2
-73 -6 -67 -24 -1087 -641 -1125 -681 -34 -34 -38 -43 -38 -91 0 -62 10 -79
89 -161 l56 -57 -405 -405 c-223 -223 -409 -405 -415 -405 -6 0 -77 67 -159
149 l-149 150 11 53 c86 390 -27 797 -301 1085 -167 176 -387 298 -627 348
-92 20 -301 27 -395 14z m380 -318 c264 -70 485 -259 593 -508 59 -136 66
-174 67 -358 0 -148 -3 -180 -22 -246 -25 -83 -29 -139 -12 -172 16 -31 1418
-1435 1451 -1453 38 -20 90 -18 177 8 65 20 99 23 241 23 143 0 175 -3 242
-23 342 -102 582 -365 644 -707 21 -117 15 -277 -12 -369 -5 -14 -52 27 -232
207 -181 179 -239 231 -287 254 -203 101 -423 52 -564 -127 -75 -94 -108 -245
-81 -361 28 -119 58 -160 297 -400 155 -157 220 -228 210 -232 -42 -16 -190
-29 -271 -24 -383 24 -696 278 -808 655 -20 67 -23 99 -23 242 0 142 3 176 23
241 24 83 28 139 11 172 -16 31 -1418 1435 -1451 1453 -38 20 -90 18 -177 -8
-66 -20 -98 -23 -246 -23 -183 0 -229 9 -369 71 -240 107 -426 327 -497 589
-27 98 -32 317 -10 403 l13 54 227 -225 c236 -234 274 -264 386 -293 101 -27
228 -11 327 43 58 31 147 123 177 181 49 95 63 220 37 317 -29 112 -59 150
-293 387 l-225 226 53 11 c30 6 63 13 74 15 49 12 254 -3 330 -23z m3228 -139
l128 -128 -238 -397 -238 -397 -287 287 c-190 190 -283 290 -275 295 6 4 183
110 392 237 209 126 382 230 385 230 3 1 63 -57 133 -127z m-1153 -1367 l-410
-410 -107 108 -108 107 410 410 410 410 107 -107 108 -108 -410 -410z m-1360
-1150 l315 -315 -720 -720 c-672 -671 -724 -721 -787 -750 -181 -85 -375 -52
-513 85 -135 135 -168 325 -89 507 26 59 76 111 748 786 396 397 723 722 726
722 3 0 147 -142 320 -315z"/>
                <path d="M1755 1932 c-69 -52 -953 -946 -964 -975 -49 -130 79 -251 203 -192
48 23 983 964 992 998 14 57 -1 117 -39 155 -31 31 -44 36 -94 40 -52 3 -62 1
-98 -26z"/>
            </g>
        </svg>
    )
}

export const RepairIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={RepairSvg} {...props} />
);