import React from 'react'
import {generatePath, Outlet, useMatches, useNavigate} from 'react-router-dom';
import {Divider, Layout, Menu} from 'antd';
import {ROUTE} from "src/routing";
import i18n from "i18next";
import {TeamOutlined} from "@ant-design/icons"
import {Sidebar} from "src/components/Sidebar";


const SystemSettingsPage: React.FC<{}> = () => {
    const menuItems: any[] = [

        {
            key: 'user-group',
            label: i18n.t("system-settings.user-group"),
            type: 'group',
            children: [
                {
                    key: generatePath(ROUTE.Users),
                    label: i18n.t("user.label"),
                },
                {
                    key: generatePath(ROUTE.Roles),
                    label: i18n.t("role.label"),
                }
            ],
        },

    ]

    const breadcrumbs = [
        {
            title: <a href={generatePath(ROUTE.SystemSettings)}>{i18n.t("system-settings.label")}</a>,
        },
    ]

    const navigate = useNavigate();
    const matches = useMatches();
    const defaultSelectedKeys: string[] = []
    menuItems.forEach((el: any) => {
        if (matches.some((y: any) => (y.pathname === el.key))) {
            defaultSelectedKeys.push(el.key)
        }
        if (el.children) {
            el.children.forEach((v: any) => {
                if (matches.some((y: any) => (y.pathname === v.key))) {
                    defaultSelectedKeys.push(v.key)
                }
            })
        }
    })

    // i fmenuItems ? menuItems.filter((el: any) => (
    //
    //     matches.some((y: any) => (y.pathname === el.key))
    // )).map(({key}: any) => key) : []

    return (
        <>
            <Sidebar/>
            <Layout hasSider={true} style={{minHeight: '100vh'}}>
                <Layout.Sider width={250} style={{
                    marginLeft: 60,
                    background: "white",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingBottom: "1rem"
                }}>
                    <h3>{i18n.t("system-settings.label")}</h3>
                    <Divider style={{marginBottom: 0}}/>
                    <Menu
                        style={{borderInlineEnd: "unset"}}
                        mode="inline"
                        defaultSelectedKeys={defaultSelectedKeys}
                        items={menuItems}
                        onClick={({key}: any) => {
                            navigate(key)
                        }}
                    />
                </Layout.Sider>
                <Layout.Content className={"p-3"}>
                    <Outlet context={{breadcrumbs}}/>
                </Layout.Content>
            </Layout>
        </>

    );
};

export {SystemSettingsPage};
