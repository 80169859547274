import React from 'react'
import {Outlet, useLoaderData} from 'react-router-dom';
import {Layout} from 'antd';


const RootPage: React.FC<{}> = () => {
    useLoaderData();
    return (
        <Layout hasSider={true} style={{minHeight: '100vh'}}>
            <Outlet/>
        </Layout>
    );
};

export {RootPage};
