import React from "react";
import {DeleteAction} from "./delete";
import type {ActionProps} from "./Types";
import Plugin from "src/plugin";

export const Actions: React.FC<ActionProps> = (props) => {
    const anyKey = Object.keys(props.row)[0]
    const rowId = props.row[anyKey]._id
    const extraActions: React.FC<ActionProps>[] = []
    Plugin.invoke("collection.actions", extraActions, props)

    return <div key={"action-" + rowId}>
        {extraActions.map(Comp => (<Comp {...props}/>))}
        <DeleteAction {...props}/>
    </div>
}