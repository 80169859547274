import React from "react";
import { Flex, Layout, Upload, Button, Card } from "antd";
import { UploadOutlined } from '@ant-design/icons'
import i18next from "i18next";
import user from 'src/components/User'
import { ApplicationsComponent, useApplicationStore } from "src/components/Application";
import { Sidebar } from "src/components/Sidebar";
import { UserBlock } from "src/components/Header/Header";
import { ApplicationCreate } from './Create'
import './style.scss'


const ApplicationsPage: React.FC<{}> = () => {
    const [open, setOpen] = React.useState(false);
    const [data, store] = useApplicationStore({})
    React.useEffect(() => {
        store.loadData()
    }, [])
    const onClose = () => {
        setOpen(false);
        store.loadData()
    };
    return (<>
        <Sidebar />
        <Layout className={"app-layout"}>
            <div className={"app-page-layout"}>
                <Flex justify={"space-between"} align={"flex-start"}>
                    <h2 className={"fw-normal"} style={{ margin: 0 }}>{i18next.t("user.hi")} {user.fullName}</h2>
                    <UserBlock />
                </Flex>
                <h3 className={"fw-normal"}>{i18next.t("user.welcome")}</h3>
                <ApplicationsComponent data={data} createCallback={() => {
                    setOpen(true)
                }} />
                <ApplicationCreate open={open} onClose={onClose} />
            </div>
        </Layout>
    </>);
};

export { ApplicationsPage };
