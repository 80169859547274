import React from 'react'
import {DatePicker} from "antd"
import {BaseFilter} from "./Base";
import {getConditions} from "../utils";

const prefixMap: { [key: string]: string } = {
    gte: ">=",
    lte: "<=",
    equal: "=",
    not_equal: "!=",
}

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "gte":
        case "lte":
        case "equal":
        case "not_equal": {
            if ((row.value && typeof row.value === 'object')) {
                store.setValue(row.key, undefined)
            }
            return <div style={{padding: 20}}>
                <DatePicker style={{width: "100%"}}
                            prefix={prefixMap[condition] || ''}
                            onChange={(_: any, val: string | string[]) => {
                                store.setValue(row.key, val)
                            }}
                            defaultValue={row.value}/>
            </div>
        }
        case "between": {
            if ((row.value && typeof row.value !== 'object') || !row.value) {
                store.setValue(row.key, {})
            }

            const onRangeChange = (dates: any, dateStrings: any) => {
                if (dates) {
                    store.setValue(row.key, {
                        from: dateStrings[0],
                        to: dateStrings[1]
                    })
                } else {
                    store.setValue(row.key, {})
                }
            };

            return <div style={{padding: 20}}>
                <DatePicker.RangePicker style={{width: "100%"}} onChange={onRangeChange}/>
            </div>
        }
    }

    return (
        <></>
    )
}


const DateFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter store={store} row={row} availableConditions={availableConditions}
                    ValueConstructor={ValueConstructor}/>
    </>
}

export {DateFilter}
