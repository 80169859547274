import {observable, makeAutoObservable, action, computed} from "mobx";
import request from "src/utils/Request";

class User {
    data: any;
    meta: any;
    isAuth: boolean = false;
    permissions: string[] = [];

    constructor() {
        makeAutoObservable(this, {
            meta: observable,
            data: observable,
            setData: action.bound,
            loadMeta: action.bound,
            fullName: computed,
        })

    }

    setData = async (errorCallback: any) => {
        await request('auth.user_info')
            .then(data => {
                if (data.result) {
                    this.permissions = data.result.permissions;
                    this.data = data.result;
                    this.isAuth = true
                } else if (data.error) {
                    errorCallback()
                }
            });
    };

    loadMeta = async () => {
        await request('UserSettings.get')
            .then(data => {
                if (data.result) {
                    this.meta = data.result;
                }
            });

    };

    get fullName() {
        return this.data?.full_name
    }
    get username(){
        return this.data?.username
    }

}
const user = new User()
export default user