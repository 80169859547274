import React from "react";
import {getConditions} from "core/src/components/ConditionFilters/utils";
import {BaseFilter} from "core/src/components/ConditionFilters/types/Base";
import {Flex, Select, Alert} from "antd";


const ValueConstructor = ({condition, row, store}: any) => {
    const [models, setModels] = React.useState<string[]>([])
    const [currentValue, setCurrentValue] = React.useState<{ [p: string]: any }>({
        manufacturer: null,
        model: null,
    })


    switch (condition) {
        case "in": {
            return <div style={{padding: 20}}>
                <Flex gap={"middle"} justify={"space-between"}>
                    <div style={{width: "100%"}}>
                        <label>Производитель</label>
                        <Select placeholder={"ПРОИЗВОДИТЕЛЬ"}
                                allowClear
                                style={{width: "100%"}}
                                onClear={() => {
                                    currentValue["model"] = null
                                    currentValue["manufacturer"] = null
                                    setCurrentValue(currentValue)
                                    setModels([])
                                }}
                                onChange={(value: string) => {
                                    currentValue["manufacturer"] = value
                                    store.setValue(row.key, currentValue)
                                    setCurrentValue(currentValue)
                                    setModels(row.models[value])
                                }}
                                options={row.manufacturers.map((el: string) => ({value: el, label: el}))}/>
                    </div>
                    <div style={{width: "100%"}}>
                        <label>Модель</label>
                        <Select placeholder={"МОДЕЛЬ"}
                                mode={"multiple"}
                                style={{width: "100%"}}
                                onChange={(value) => {
                                    currentValue["model"] = value
                                    setCurrentValue(currentValue)
                                    store.setValue(row.key, currentValue)
                                }}
                                value={currentValue.model || []}
                                options={models ? models.map((el: string) => ({value: el, label: el})) : []}
                                loading={false}
                        />
                    </div>
                </Flex>
                <Alert style={{marginTop: 20}} message="Поиск по производителю и его моделям" type="info"/>
            </div>
        }
        case "manufacturer_no_model": {
            return <div style={{padding: 20}}>
                <label>Производитель</label>
                <Select placeholder={"ПРОИЗВОДИТЕЛЬ"}
                        allowClear
                        style={{width: "100%"}}
                        mode={"multiple"}
                        onClear={() => {
                            currentValue["model"] = null
                            currentValue["manufacturer"] = null
                            setCurrentValue(currentValue)
                            setModels([])
                        }}
                        onChange={(value: string) => {
                            currentValue["manufacturer"] = value
                            currentValue["model"] = null
                            store.setValue(row.key, currentValue)
                            setCurrentValue(currentValue)
                        }}
                        options={row.manufacturers.map((el: string) => ({value: el, label: el}))}/>
                <Alert style={{marginTop: 20}} message="Поиск по производителю, в котором не заполнена модель" type="info"/>

            </div>
        }
        case "no_model": {
            return <div style={{padding: 20}}>
                <Alert message="Поиск по оборудованию, где заполнен производитель, но не заполнена модель" type="info"/>
            </div>
        }
    }

    return (
        <></>
    )
}


export const ManufacturerFilter = ({row, store}: any) => {


    const availableConditions = getConditions(row)
    return <>
        <BaseFilter row={row} store={store} availableConditions={availableConditions}
                    ValueConstructor={ValueConstructor}/>
    </>
}