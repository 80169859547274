import React from "react";
import Icon from '@ant-design/icons';
import type {GetProps} from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const CartSvg = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="1em" height="1em" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="currentColor" stroke="none">
                <path d="M575 4626 c-94 -41 -124 -168 -58 -247 42 -49 77 -59 212 -59 l119 0
17 -42 c8 -24 197 -527 419 -1118 l403 -1075 -91 -6 c-112 -7 -182 -29 -266
-85 -203 -133 -268 -392 -155 -613 49 -98 171 -205 258 -226 15 -4 29 -11 32
-15 3 -5 -2 -34 -10 -66 -88 -337 215 -661 552 -590 273 57 441 314 379 578
l-13 57 283 3 c267 3 284 4 310 24 53 39 69 71 69 134 0 63 -16 95 -69 134
-27 21 -40 21 -726 26 -686 5 -699 5 -726 26 -53 39 -69 71 -69 134 0 63 16
95 69 134 27 21 34 21 1364 26 1051 4 1342 8 1359 18 12 7 34 28 48 47 25 31
38 101 192 1025 112 675 163 1002 158 1026 -8 46 -58 101 -103 114 -26 8 -449
10 -1354 8 -1312 -3 -1317 -3 -1344 -24 -53 -39 -69 -71 -69 -134 0 -63 16
-95 69 -134 27 -21 33 -21 1243 -24 l1215 -2 -5 -28 c-3 -15 -63 -375 -133
-799 l-129 -773 -997 0 -997 0 -19 53 c-11 28 -221 587 -466 1242 -278 739
-455 1198 -469 1213 -42 46 -72 52 -279 52 -138 -1 -202 -5 -223 -14z m1409
-3520 c44 -18 86 -75 93 -124 20 -152 -160 -241 -268 -133 -124 124 13 325
175 257z"/>
                <path d="M3721 1424 c-253 -68 -410 -331 -346 -579 67 -256 329 -415 580 -350
237 62 393 291 358 529 -40 283 -322 473 -592 400z m182 -318 c103 -43 128
-177 48 -257 -65 -65 -157 -65 -222 0 -124 123 13 325 174 257z"/>
            </g>
        </svg>
    )
}

export const CartIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={CartSvg} {...props} />
);