import React from 'react'
import {Request} from "kaiju.core";
import {SortOrder, TableColumn} from "react-data-table-component/dist/DataTable/types";

interface TableProps {
    request: {
        method: string,
        params?: { [p: string]: any } | undefined
    }
    onSelectedRows?: (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: any[];
    }) => void,
    onClick?: Function | undefined,
    onColumnOrderChange?: (columns: string[]) => void
    paginationRowsPerPageOptions?: number[],
    preFilters?: any[]
}

interface PaginationProps {
    page: number,
    per_page: number,
    count: number
}

interface StoreInterface {
    id: string | undefined,
    data: any[],
    columns: any[],
    onSelectedRows?: (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: any[];
    }) => void,
    pagination: PaginationProps,
    fetchData: Function,
    onClick: (row: any, event: any) => void,
    querySearch: (q: string | undefined | null) => void,
    addFilters: (filters: any[]) => void,
    onSort: (selectedColumn: TableColumn<any>, sortDirection: SortOrder, sortedRows: any[]) => void,
    onColumnOrderChange?: (nextOrder: TableColumn<any>[]) => void,
    onChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => void,
    onChangePage: (page: number) => void,
    updateExtraParams: (params: { [p: string]: any }) => void,
    progressPending: boolean
    reorderColumn: boolean
    currentPage: number
    paginationRowsPerPageOptions: number[]
}

const useStore = (id: string | undefined, props: TableProps): StoreInterface => {
    let currentQ: string | null | undefined = null
    let currentFilters: any[] = [];
    const [progressPending, setProgressPending] = React.useState<boolean>(true)
    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [perPage, setPerPage] = React.useState<number>(24)
    const [data, setData] = React.useState<any[]>([])
    const [columns, setColumns] = React.useState<any[]>([])
    const [extraParams, setExtraParams] = React.useState<{ [p: string]: any }>({})
    const [sort, setSort] = React.useState<{ [p: string]: any }>({})
    const [pagination, setPagination] = React.useState<PaginationProps>({
        page: 1,
        per_page: 24,
        count: 0
    })
    const onClick = (row: any, event: any) => {
        if (props.onClick) {
            props.onClick(row, event)
        }
    }

    const fetchData = async (force: boolean = false) => {
        setProgressPending(true)
        await Request(props.request.method, {
            ...props.request.params,
            ...extraParams,
            page: currentPage,
            per_page: perPage,
            filters: [...(props.preFilters ? props.preFilters : []), ...currentFilters],
            q: currentQ,
            sort: sort
        }).then((resp: any) => {
            if (resp.result) {
                if (resp.result.pagination) {
                    setPagination(resp.result.pagination)
                }

                if (columns?.length === 0 && resp?.result?.columns?.length > 0 && !force) {
                    setColumns(resp.result?.columns)
                } else if (force) {
                    setColumns(resp?.result?.columns || [])
                }

                setData(resp?.result?.data || [])
            } else {
                setData([])
            }
        })
        setProgressPending(false)
    }

    const querySearch = (q: string | null | undefined) => {
        currentQ = q
        fetchData(false)
    }
    const addFilters = (filters: any[]) => {
        currentFilters = filters
        fetchData(false)
    }

    const onSort = (selectedColumn: any, sortDirection: any) => {
        // call back for sorting
        setSort({
            column: selectedColumn.id,
            direction: sortDirection,
        })
    }
    const onColumnOrderChange = (nextOrder: TableColumn<any>[]) => {
        if (props.onColumnOrderChange) {
            props.onColumnOrderChange(nextOrder.map((row: any) => row.id))
        }
        return nextOrder
    }
    React.useEffect(() => {
        fetchData(true)
    }, [id, extraParams])

    React.useEffect(() => {
        fetchData()
    }, [currentPage, perPage, sort])
    const onChangeRowsPerPage = (currentRowsPerPage: number) => {
        if (perPage !== currentRowsPerPage) {
            setCurrentPage(1)
            setPerPage(currentRowsPerPage)
        }
    }
    const onChangePage = async (page: number) => {
        setCurrentPage(page)
    }

    const updateExtraParams = (params: { [p: string]: any }) => {
        setExtraParams({...extraParams, ...params})
    }

    return {
        id: id,
        addFilters,
        currentPage,
        updateExtraParams,
        querySearch,
        onSelectedRows: props.onSelectedRows,
        paginationRowsPerPageOptions: props.paginationRowsPerPageOptions || [24, 48, 100],
        onChangePage,
        onClick,
        progressPending,
        onSort,
        data,
        columns,
        pagination,
        onChangeRowsPerPage,
        onColumnOrderChange,
        fetchData,
        reorderColumn: !!props.onColumnOrderChange
    }
}

export {useStore}
export type {StoreInterface}
