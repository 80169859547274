import React, {useEffect} from 'react';
import {useRouteError, isRouteErrorResponse, useNavigate, useLocation} from "react-router-dom";

function ErrorBoundary() {
    const error = useRouteError();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isRouteErrorResponse(error)) {
            if (error.status === 401) {
                const redirectTo = location.pathname + location.search
                navigate(`/login?redirectTo=${redirectTo}`);
            }
        }

    }, [error]);

    if (isRouteErrorResponse(error)) {
        switch (error.status) {
            case 404:
            case 400:
                return <div>400</div>;
            // break;
            case 503:
                return <div>503</div>
            case 418:
                return <div>418</div>
            default:
                return <div>some error</div>
        }
    }


    throw error;
}

export {ErrorBoundary};
