import React from 'react'
import {Request} from "kaiju.core";

interface RequestInterface {
    method: string
    params?: { [key: string]: any } | undefined
}

interface ListInterface {
    request: RequestInterface,
    requestSort?: RequestInterface,
    searchKeys?: string[]
}

const useList = (id: string | undefined, settings: ListInterface) => {
    const [data, setData] = React.useState<any[]>([])
    const [searchValue, setSearchValue] = React.useState<string>("")
    const searchKeys = settings.searchKeys || ["id", "label"]
    const loadData = () => {
        Request(settings.request.method, settings.request.params || {}).then((response: any) => {
            if (response.result) {
                setData(response.result.data)
            }
        })
    }
    const changeSort = (newData: any[], sortable: any, replace: boolean = true) => {
        if (searchValue && searchValue.length > 0){
            setData(data)
            return;
        }

        if (!sortable){
            return
        }
        setData(newData)
        if (settings.requestSort) {
            const requestParams = {
                ...(settings.requestSort.params || {}),
                data: newData,
            }
            Request(settings.requestSort.method, requestParams).then((response: any) => {
                if (response.result) {
                    if(replace) setData(response.result.data)
                }
            })
        }

    }

    const search = (value: string) => {
        setSearchValue(value)
    }

    React.useEffect(() => {
        loadData()
    }, [id])

    const dataMap = ()=> {
        return data.filter((value: any) => {
            if (!searchValue && searchValue.length === 0){
                return true
            }
            const bind = searchKeys.filter((key: string) => {
                return value[key].toLowerCase().search(searchValue) !== -1
            })
            return bind.length > 0
        })
    }

    return [{
        changeSort,
        search,
        dataMap,
        data,
        loadData
    }]
}
export {useList}