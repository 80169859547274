import React from 'react'
import {Input, Select, Tag} from "antd";
import {BaseFilter} from "./Base";
import type { SelectProps } from 'antd';
import {getConditions} from "../utils";

type TagRender = SelectProps['tagRender'];
const tagRender: TagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"geekblue"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "contains":
        case "not_contains":
        case "start_with":
        case "end_with":
        case "equal":
        case "not_equal": {
            if (Array.isArray(row.value)) {
                store.setValue(row.key, row.value.toString())
            }
            return <div style={{padding: 20}}><Input defaultValue={row.value} onChange={(val) => {
                store.setValue(row.key, val.currentTarget.value)
            }}/></div>
        }
        case "in_list":
        case "tag_list": {
            if (row.value && !Array.isArray(row.value)) {
                store.setValue(row.key, [row.value])
            }
            return <div style={{padding: 20}}><Select tagRender={tagRender} mode="tags" defaultValue={row.value} style={{width: "100%"}} onChange={(val: any) => {
                store.setValue(row.key, val)
            }}/></div>
        }
    }

    return (
        <></>
    )
}

const StringFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter row={row} store={store} availableConditions={availableConditions} ValueConstructor={ValueConstructor}/>
    </>
}

export {StringFilter}
