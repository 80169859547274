import React from "react";
import {Layout, Flex, Divider, Collapse, Badge} from 'antd'
import {CloseOutlined} from "@ant-design/icons";
import {ContentComponent, ComponentsBlock, MediaLibraryComponent, SingleComponent} from "./Types";
import {generatePath, useMatches, useNavigate} from "react-router-dom";
import i18n from "i18next";
import './style.scss'
import {ROUTE} from "src/routing";


const ContentTypeBuilder: React.FC<{ appId: any, store: any }> = ({appId, store}) => {
    const navigate = useNavigate()
    const matches = useMatches()

    const contentOnClick = (el: any) => {
        navigate(generatePath(ROUTE.ctbContent, {appId: appId, contentId: el._id}))
    }
    const singleOnClick = (el: any) => {
        navigate(generatePath(ROUTE.ctbSingle, {appId: appId, singleId: el.id}))
    }
    const componentOnClick = (el: any) => {
        navigate(generatePath(ROUTE.ctbComponent, {appId: appId, componentId: el.id}))
    }
    const mediaOnClick = (el: any) => {
        navigate(generatePath(ROUTE.ctbMedia, {appId: appId, mediaId: el._id}))
    }

    const onCreate = (data: any) => {
        store.refresh()
    }
    const items: any[] = [
        {
            key: 'collection',
            label: i18n.t("collection.label").toUpperCase(),
            children: <ContentComponent key={"ContentComponent"} onCreate={onCreate} data={store.data.contents}
                                        onClick={contentOnClick}
                                        isActive={(el: any) => (
                                            matches.some((y: any) => (y.pathname === generatePath(ROUTE.ctbContent, {
                                                appId: appId,
                                                contentId: el._id
                                            })))
                                        )}/>,
            extra: <Badge count={store.data.contents.length} showZero color={"blue"}/>
        },
        {
            key: 'singleType',
            label: i18n.t("single.label").toUpperCase(),
            children: <SingleComponent key={"SingleTypeComponent"} onCreate={onCreate} data={store.data.single}
                                       onClick={singleOnClick}
                                       isActive={(el: any) => (
                                           matches.some((y: any) => (y.pathname === generatePath(ROUTE.ctbSingle, {
                                               appId: appId,
                                               singleId: el.id
                                           })))
                                       )}/>,
            extra: <Badge count={store.data.single.length} showZero color={"blue"}/>
        },

        {
            key: 'component',
            label: i18n.t("component.label").toUpperCase(),
            children: <ComponentsBlock key={"ComponentsBlock"} onCreate={onCreate} data={store.data.components}
                                       onClick={componentOnClick}/>,
            extra: <Badge count={store.data.components.length} showZero color={"blue"}/>
        },
        {
            key: 'media',
            label: i18n.t("media.label").toUpperCase(),
            children: <MediaLibraryComponent key={"MediaLibraryComponent"} onCreate={onCreate} data={store.data.media}
                                             onClick={mediaOnClick}
                                             isActive={(el: any) => (
                                                 matches.some((y: any) => (y.pathname === generatePath(ROUTE.ctbMedia, {
                                                     appId: appId,
                                                     mediaId: el._id
                                                 })))
                                             )}/>,
            extra: <Badge count={store.data.media.length} showZero color={"blue"}/>
        },

    ];


    return (
        <>
            {store.open &&
                <Layout.Sider theme={"light"} collapsed={false} className={"c-builder-menu"}
                              width={250}
                >
                    <Flex justify={"space-between"}>
                        <h3>Content-type Builder</h3>
                        <CloseOutlined className={"c-builder-close"} onClick={store.onClose}/>
                    </Flex>
                    <Divider style={{margin: 0}}/>
                    <Collapse className={"c-builder-c"} defaultActiveKey={items.map((el: any) => (el.key))}
                              expandIconPosition={"end"}
                              ghost items={items}/>
                </Layout.Sider>
            }
        </>

    )
}

export {ContentTypeBuilder}