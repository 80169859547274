import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {Request} from "kaiju.core";

const LoadI18n = async () => {
    await Request('i18n.get')
        .then((response) => {
            i18n.use(initReactI18next)
                .init({
                    resources: response.result?.translations || {},
                    lng: response.result.default,
                });
        })

};

export {LoadI18n};
