import {Request} from "kaiju.core";

const loader = async ({params}: any) => {
    const {contentId} = params
    const attributeList = await Request("ContentGui.attribute.list", {
        content: contentId,
    }).then((resp: any) => {
        return resp.result
    })
    return {
        attributeList
    }
}

export {loader}