import React from 'react'
import {Layout} from "antd";
import {useOutletContext, useParams, Outlet} from "react-router-dom";
import {ContentMenu, useStore} from "src/components/ContentMenu";
import {Permission403} from 'src/components/Permissions'
import {ROUTE} from "src/routing";
import user from 'src/components/User'


const ContentRootPage: React.FC = () => {
    const {breadcrumbs} = useOutletContext<any>();
    const {appId} = useParams<{ appId: string}>()
    const store = useStore({appId})

    return (<>
        <Layout
            style={{borderRadius: "5px"}} className={"h-100"}
        >
            <ContentMenu store={store} route={ROUTE.ContentList} title={"Content"}/>
            <Layout.Content className={"settings-content"}>
                <Outlet context={{breadcrumbs, builderStore: store}}/>
                {/*{contentId &&*/}
                {/*    <Permission403 root={contentId} permissions={["read"]}>*/}
                {/*        <Outlet context={{breadcrumbs, builderStore: store}}/>*/}
                {/*    </Permission403>*/}
                {/*}*/}
            </Layout.Content>
        </Layout>


    </>)
}

export {ContentRootPage}