import React from 'react'
import {Empty, Space} from "antd";
import i18n from "i18next";
import {ReactSortable} from "react-sortablejs";


interface ListComponentInterface {
    store: any,
    empty?: any,
    children?: any,
    renderItem: (item: any, index: number) => React.ReactNode,
    onSortReplace?: boolean
}

const ListComponent: React.FC<ListComponentInterface> = ({
                                                             store,
                                                             empty,
                                                             onSortReplace,
                                                             renderItem
                                                         }) => {
    let tempData: any[] = []
    const replace = onSortReplace === undefined? true : onSortReplace
    return (<>
        {!!store.data?.length &&
            <ReactSortable list={store.data} setList={(newState: any[], sortable: any)=>{
                if (sortable){
                    tempData = newState
                }
            }} onEnd={()=> {
                store.changeSort(tempData, true, replace)
            }}>
                {store.dataMap().map((item: any, index: number) => (
                    <div key={`${store.id}__${index}`} style={{
                        background: "white",
                        borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
                        paddingTop: "18px",
                        paddingBottom: "6px"
                    }}>
                        {renderItem(item, index)}
                    </div>
                ))}
            </ReactSortable>
        }

        {!store.data?.length &&
            <Space direction="vertical" style={{width: '100%'}} align={"center"}>
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{height: 200}}
                    description={
                        <h2 className={"fw-normal"}>
                            {empty ? empty : i18n.t("common.empty_list")}
                        </h2>
                    }
                />
            </Space>
        }
    </>)
}

export {ListComponent}