import React from "react";
import {Layout} from "antd";
import i18n from "i18next";
import {useOutletContext} from "react-router-dom";
import {HeaderComponent} from "src/components/Header";


export const Dashboard: React.FC<{}> = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()

    return (<>

        <Layout
            className={"h-100"} style={{padding: "1.5rem"}}
        >
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: i18n.t("dashboard.label"),
                },

            ]}/>

            Dashbord
        </Layout>

    </>)
}