import React from 'react'
import {PlusOutlined} from "@ant-design/icons";
import i18n from "i18next";
import {SingleCreate} from "./Create";

export const SingleComponent: React.FC<{
    data: any[],
    isActive?: Function,
    onClick: Function,
    onCreate: Function
}> = (props) => {
    const [open, setOpen] = React.useState(false)

    const onClose = () => {
        setOpen(false)
    }

    return (
        <div style={{paddingBottom: 20}}>
            <ul className="cc-ul">
                {props.data.map((el: any) => (
                    <li
                        key={'cc-li-' + el.id}
                        className={`li-row ${(props.isActive && props.isActive(el)) ? "cc-active" : ""}`}
                        onClick={() => {
                            props.onClick(el)
                        }}
                    > {el.label}</li>
                ))}
            </ul>
            <div style={{paddingTop: 10}}>
                <a style={{paddingLeft: 0, marginTop: 10}} onClick={() => {
                    setOpen(true)
                }}><PlusOutlined/> {i18n.t("collection.add")}</a>
            </div>

            <SingleCreate open={open} onClose={onClose} onCreate={props.onCreate}/>
        </div>
    )
}
