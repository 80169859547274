import React from "react";
import {Layout, Card, Flex, Input, Badge, Button, Table} from "antd"
import {useOutletContext} from "react-router-dom";
import {HeaderComponent} from "core/src/components/Header";


const data8: any[] = [
    {
        key: '1',
        name: 'Оплата',
        to_pay: 10000,
        pay: 10000,
        ost: 0
    },
    {
        key: '2',
        name: 'Ремонт',
        to_pay: 8000,
        pay: 8000,
        ost: 0

    }
];

const data7: any[] = [
    {
        key: '1',
        name: 'Оплата',
        to_pay: 10800,
        pay: 11000,
        ost: 200

    },
    {
        key: '2',
        name: 'Доставка',
        to_pay: 8000,
        pay: 8000,
        ost: 0

    },
    {
        key: '3',
        name: 'Оплата',
        to_pay: 10000,
        pay: 10000,
        ost: 0

    },
    {
        key: '4',
        name: 'Депозит',
        to_pay: 6600,
        pay: 6600,
        ost: 0

    },

];

const months: any = [
    {
        label: "08.2024",
        data: data8
    },
    {
        label: "07.2024",
        data: data7
    },

]

export const PayHistoryPage: React.FC<{}> = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()


    return <>
        <Layout
            className={"h-100"} style={{padding: "1.5rem"}}
        >
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Мониторинг",
                },
                {
                    title: "История платежей",
                }
            ]}/>
            <h2>История платежей: Фомин Дмитрий</h2>
            <div style={{maxWidth: 800}}>
                {months.map((el: any) => {
                    const columns: any[] = [
                        {
                            title: el.label,
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'К оплате',
                            dataIndex: 'to_pay',
                            key: 'to_pay',
                        },
                        {
                            title: 'Оплачено',
                            dataIndex: 'pay',
                            key: 'pay'
                        },
                        {
                            title: 'Остаток',
                            dataIndex: 'ost',
                            key: 'ost'
                        },

                    ];
                    return <>
                        {/*<h3>{el.label}</h3>*/}
                        <Table pagination={false} columns={columns} dataSource={el.data} style={{marginBottom: 5}}/>
                    </>
                })}
            </div>
        </Layout>
    </>
}