import React from 'react'
import {Drawer, Space, Button, Flex, Tag, Card, Divider, App} from "antd";
import {SettingOutlined, NumberOutlined, ArrowLeftOutlined, FileImageOutlined} from "@ant-design/icons";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";
import Plugin from "src/plugin";


const AttributeKinds: any[] = [
    {
        kind: "string",
        label: "attribute-kind.string",
        icon: <Tag className={"attr-kind-icon"} color={"magenta"}><SettingOutlined/></Tag>,
        description: "attribute-kind.description.string"
    },
    {
        kind: "text",
        label: "attribute-kind.text",
        icon: <Tag className={"attr-kind-icon"} color={"green"}><SettingOutlined/></Tag>
    },
    {
        kind: "integer",
        label: "attribute-kind.integer",
        icon: <Tag className={"attr-kind-icon"} color={"cyan"}><NumberOutlined/></Tag>
    },
    {
        kind: "decimal",
        label: "attribute-kind.decimal",
        icon: <Tag className={"attr-kind-icon"} color={"gold"}><SettingOutlined/></Tag>
    },
    {
        kind: "boolean",
        label: "attribute-kind.boolean",
        icon: <Tag className={"attr-kind-icon"} color={"purple"}><SettingOutlined/></Tag>
    },
    {
        kind: "collection_select",
        label: "attribute-kind.collection_select",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "collection_multiselect",
        label: "attribute-kind.collection_multiselect",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },

    {
        kind: "json_object",
        label: "attribute-kind.json_object",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "select",
        label: "attribute-kind.select",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "multiselect",
        label: "attribute-kind.multiselect",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "date",
        label: "attribute-kind.date",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "datetime",
        label: "attribute-kind.datetime",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "media",
        label: "attribute-kind.media",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><FileImageOutlined/></Tag>
    },
    {
        kind: "component",
        label: "attribute-kind.component",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "component_list",
        label: "attribute-kind.component_list",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },
    {
        kind: "component_any",
        label: "attribute-kind.component_any",
        icon: <Tag className={"attr-kind-icon"} color={"blue"}><SettingOutlined/></Tag>
    },

]

const AttrForm: React.FC<{ appId?: string, kind: string, componentId: string, onClose: any }> = ({
                                                                                                   appId,
                                                                                                   kind,
                                                                                                   componentId,
                                                                                                   onClose
                                                                                               }) => {
    const {notification} = App.useApp();

    const [store] = useDynamicForm({
        autoLoad: true,
        loadRequest: {
            method: 'ContentTypeBuilder.component.attribute.model',
            params: {
                kind: kind
            }
        },
        isNested: true,
        saveDiff: false,
        loadExtraParams: {
            application: appId,
            component: componentId,
        },
        saveRequest: {
            method: 'ContentTypeBuilder.component.attribute.create',
            params: {
                application: appId,
                component: componentId,
                kind: kind
            }
        },
        successCallback: (response: any) => {
            notification.success({
                message: i18n.t('message.attribute-success-create'),
                placement: 'topRight',
            });
            onClose()
        }
    });
    return <Form store={store.formStore}>
        <Space>
            <Button onClick={() => {
                store.submit()
            }} type="primary">
                {i18n.t("common.add")}
            </Button>
        </Space>
    </Form>
}


const CreateAttribute: React.FC<{ appId?: string, onClose: any, open: boolean, componentId?: string }> = ({
                                                                                                            appId,
                                                                                                            componentId,
                                                                                                            open,
                                                                                                            onClose
                                                                                                        }) => {
    Plugin.invoke("component.attributeKind", AttributeKinds)
    const [currentKind, setKind] = React.useState<string>("")
    const handleClose = () => {
        onClose()
        setKind("")
    }
    return <>
        <Drawer
            title={
                <>
                    <span>{i18n.t("attribute.create-new")}</span>
                </>}
            onClose={handleClose}
            width={900}
            open={open}
            size={"large"}
        >
            <div className={"attr-create-body"}>
                {currentKind.length === 0 &&
                    <>
                        <h3>{i18n.t("attribute-kind.choice")}</h3>
                        <Card>
                            {AttributeKinds.map((val: any) => {
                                return <Card.Grid className={"attr-kind-grid"} onClick={() => {
                                    setKind(val.kind)
                                }}>
                                    <Flex>
                                        <div>{val.icon}</div>
                                        <div>
                                            <div className={"fw-500"}>{i18n.t(val.label)}</div>
                                            <div
                                                className={"description"}>{val.description ? i18n.t(val.description) : ""}</div>
                                        </div>
                                    </Flex>
                                </Card.Grid>
                            })}
                        </Card>
                    </>
                }
                {(currentKind.length > 1 && componentId) &&
                    <>
                        <div style={{marginBottom: "2rem"}}><a onClick={() => {
                            setKind((""))
                        }}><ArrowLeftOutlined/> {i18n.t("common.back")}</a></div>

                        {AttributeKinds.filter((val) => (val.kind === currentKind)).map((val: any) => {
                            return (<Flex>
                                <div>{val.icon}</div>
                                <div>
                                    <h3 className={"fw-500"}
                                        style={{margin: 0, paddingBottom: 5}}>{i18n.t(val.label)}</h3>
                                    <div
                                        className={"description"}>{val.description ? i18n.t(val.description) : ""}</div>
                                </div>
                            </Flex>)
                        })}
                        <Divider/>
                        <AttrForm kind={currentKind} componentId={componentId} appId={appId} onClose={handleClose}/>
                    </>
                }
            </div>


        </Drawer>
    </>
}

export {CreateAttribute}