import React from 'react'
import {Drawer, Space, Button, App} from "antd";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";


interface Props {
    open: boolean
    attrId: string
    appId: string
    singleId: string
    onClose: () => void
}

export const AttributeEdit: React.FC<Props> = (props) => {
    const handleClose = () => {
        props.onClose()
    }
    const {notification} = App.useApp();

    const [store] = useDynamicForm({
        autoLoad: true,
        loadRequest: {
            method: 'ContentTypeBuilder.single.attribute.get',
            params: {
                single: props.singleId,
                application: props.appId,
                id: props.attrId
            }
        },
        isNested: true,
        saveDiff: false,
        loadExtraParams: {
            application: props.appId,
        },
        saveRequest: {
            method: 'ContentTypeBuilder.single.attribute.update',
            params: {
                application: props.appId,
                single: props.singleId,
            }
        },
        successCallback: (response: any) => {
            notification.success({
                message: i18n.t('common.updated'),
                placement: 'topRight',
            });
            handleClose()
        }
    });

    return <>
        <Drawer
            title={
                <>
                    <span>{i18n.t("attribute.create-new")}</span>
                </>}
            onClose={handleClose}
            width={900}
            open={props.open}
            size={"large"}
        >
            <Form store={store.formStore}>
                <Space>
                    <Button onClick={() => {
                        store.submit()
                    }} type="primary">
                        {i18n.t("common.save")}
                    </Button>
                </Space>
            </Form>
        </Drawer>
    </>
}
