import {Form, Input} from "antd";
import {Form as KForm} from "kaiju.core"
import React from "react";
import * as Yup from 'yup';
// import FieldStore from './base';
// import {Error} from '../error';
// import {Helper} from "../utils";


export class TextStore extends KForm.Fields.FieldStore {
    validator: any;

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || "";
        this.validator = Yup.string().nullable();
        this.makeValidator(props)
    }

    Component: React.FC = (props: any) => {
        const errors = props.errors || props.formik.errors;
        const values = props.values || props.formik.values;
        const _id = this._id(props);

        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Input.TextArea
                        count={{
                            show: typeof this.props.max === 'number',
                            max: this.props.max,
                        }}
                        style={{minHeight: 150}}
                        id={this.id}
                        name={this.id}
                        disabled={this.disabled}
                        placeholder={this.placeholder}
                        onChange={(event: any) => {
                            props.formik.setFieldValue(_id, event.target.value);
                        }}
                        value={values[this.id]}
                        onBlur={props.formik.handleBlur}
                    />
                    <KForm.Error message={errors[this.id]}/>
                </Form.Item>
            </>
        )
    }

}
