import React from "react";
import {Form as KForm} from "kaiju.core"
import * as Yup from 'yup';


export class StaticZoneStore extends KForm.Fields.FieldStore {
    validator: any;
    fields: any[] = []

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.value = typeof props.value === 'object' && !Array.isArray(props.value) ? props.value : {};
        this.validator = Yup.object().nullable();
        this.initNested(props.fields);
        this.makeValidator(props);
    }

    initNested(fields: any[]) {
        const _validators: { [key: string]: any } = {};
        fields.map((field: any) => {
            const fStore = this.form.kindMap[field.kind] ? this.form.kindMap[field.kind] : undefined;
            if (fStore) {
                const _store = new fStore({
                    form: this.form,
                    parentId: this.id,
                    ...field,
                    loadExtraParams: this.loadExtraParams
                });
                this.fields.push(_store);
                _validators[field.id] = _store.validator;

                if (!(field.id in this.value)) {
                    this.value[field.id] = _store.value
                }
            }
        });
        this.validator = this.validator.shape(
            _validators
        )
    }


    Component: React.FC = (props: any) => {
        const errors = props.errors || props.formik.errors;

        return (
            <>

                {/*<Form.Item label={this.label} required={this.props.required}*/}
                {/*           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>*/}
                {this.fields.map((fieldStore: any) => {
                    return <fieldStore.Component key={`${props.index}-form-field-nested-${fieldStore._id()}`}
                                                 formik={props.formik}
                                                 index={props.index}
                                                 values={props.values}
                                                 errors={errors}
                    />
                })}
                {/*</Form.Item>*/}

            </>
        )
    }

}

