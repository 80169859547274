import React from "react";
import {App, Dropdown, Modal} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, EditOutlined, MoreOutlined} from "@ant-design/icons";
import i18n from "i18next";
import {Request} from "kaiju.core";
import {GroupEdit} from "./GroupEdit";

export const GroupActions: React.FC<{
    node: any,
    componentId: string,
    appId: string,
    onCreate: Function
}> = (props) => {
    const [openEdit, setOpenEdit] = React.useState<boolean>(false)
    const {notification} = App.useApp()
    const [modal, contextHolder] = Modal.useModal();

    const deleteGroup = () => {
        Request("ContentTypeBuilder.component.group.delete", {
            application: props.appId,
            id: props.componentId
        }).then(({result}) => {
            if (result) {
                notification.success({
                    message: i18n.t('common.deleted'),
                    placement: 'topRight',
                });
                props.onCreate()
            }
        })
    }

    const openDelete = () => {
        if (props.node?.children?.length === 0) {
            modal.confirm({
                title: props.node.label,
                icon: <ExclamationCircleOutlined/>,
                content: i18n.t("common.confirm-delete"),
                onOk: deleteGroup,
                okText: i18n.t("common.delete"),
                cancelText: i18n.t("common.cancel"),
            });
        } else {
            notification.error({
                message: i18n.t('component.error.group-not-empty'),
                placement: 'topRight',
            });
        }
    }

    const items: any[] = [
        {
            label: <span onClick={() => {
                setOpenEdit(true)
            }}>{i18n.t("common.edit")}</span>,
            key: 'edit',
            icon: <EditOutlined/>
        },
        {
            label: <span onClick={() => {
                openDelete()
            }}>{i18n.t("common.delete")}</span>,
            key: 'delete',
            danger: true,
            icon: <DeleteOutlined/>
        },
    ];
    return (
        <>
            <Dropdown key={props.componentId} menu={{items}} trigger={['click']}>
                <span style={{marginLeft: 10}}><MoreOutlined/></span>
            </Dropdown>
            {openEdit && <GroupEdit open={openEdit} id={props.componentId} appId={props.appId} onClose={() => {
                setOpenEdit(false);
                props.onCreate()
            }}/>}
            {contextHolder}
        </>
    )
}