import React from "react";
import {Form as KForm} from "kaiju.core"
import {Card, Divider, Popconfirm, Flex, Form, Typography} from "antd";
import {getIn} from 'formik';
// import * as Yup from 'yup';
import {DeleteOutlined} from "@ant-design/icons"
import MD5 from "crypto-js/md5";
import i18n from "i18next";


export class ComponentAny extends KForm.Fields.FieldStore {
    validator: any;
    nested: { [p: string]: any }
    components: string[]
    fields: any[]

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.nested = props.nested_forms
        this.components = props.reference_components
        this.value = props.value
        // this.validator = Yup.array().nullable();
        this.fields = []
        // this.initNested();
        // this.makeValidator(props);
    }

    getFields = (id: string) => {
        if (this.fields.length === 0) {
            const _nested = this.nested[id]

            _nested.fields.map((field: any) => {
                const fStore = this.form.kindMap[field.kind] ? this.form.kindMap[field.kind] : undefined;
                if (fStore) {
                    const _store = new fStore({
                        form: this.form,
                        ...field,
                        loadExtraParams: this.loadExtraParams
                    });
                    this.fields.push(_store);
                    // _validators[field.id] = _store.validator;
                }
            });
        }

        return this.fields
    }

    emptyValue = (id: string) => {
        const resp: { [key: string]: any } = {
            _component: id,
            _uid: MD5((new Date()).toString()).toString()
        };
        return resp
    };

    renderFields: React.FC<{ value: any, _id: string, props: any }> = ({value, _id, props}) => {
        const nested = this.nested[value._component]
        const confirm = () => {
            this.fields = []
            this.props.form.formik.setFieldValue(_id, null)
        }
        return <Card size="small"
                     title={nested.label}
                     styles={{header: {background: "#fafafa"}}}
                     extra={
                         <Popconfirm
                             title={i18n.t("common.confirm-delete")}
                             onConfirm={confirm}
                             okText={i18n.t("common.delete")}
                             cancelText={i18n.t("common.cancel")}
                         >
                             <DeleteOutlined/>
                         </Popconfirm>

                     }

        >
            {this.getFields(value._component).map((fieldStore: any) => {
                return <fieldStore.Component
                    key={`${props.index}-form-field-nested-${fieldStore._id()}`}
                    formik={props.formik}
                    parentId={_id}
                    values={getIn(props.formik.values, _id)}
                    errors={getIn(props.formik.errors, _id)}
                />
            })}
        </Card>
    }

    Component: React.FC = (props: any) => {
        const _id = this._id(props)
        const value = getIn(this.props.form.formik.values, _id)

        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Divider style={{marginTop: 5, marginBottom: 20}}/>
                    {value &&
                        <this.renderFields value={value} _id={_id} props={props}/>
                    }

                    {!value &&
                        <Flex wrap gap={"middle"}>
                            {this.components?.map((id: any) => (
                                <Card
                                    style={{
                                        background: "whitesmoke",
                                        cursor: "pointer",
                                        border: "1px dashed #d9d9d9"
                                    }}
                                    key={"btn-" + id}
                                    onClick={() => {
                                        this.props.form.formik.setFieldValue(_id, this.emptyValue(id))
                                    }}>
                                    <Typography.Text>
                                        {this.nested[id].label}
                                    </Typography.Text>
                                </Card>
                            ))}
                        </Flex>
                    }
                </Form.Item>
            </>
        )
    }

}

