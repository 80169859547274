import {Request} from "kaiju.core";

const loader = async ({params}: any) => {
    const {componentId, appId} = params
    const info = await Request("ContentTypeBuilder.component.get", {
        id: componentId,
        application: appId,
    }).then((resp: any) => {
        return resp.result
    })
    return info
}

export {loader}