import React from 'react'
import {App, Button, Card, Flex, Input, Popconfirm} from "antd";
import i18n from "i18next";
import {useOutletContext} from "react-router-dom";
import {Request} from "kaiju.core";
import {Table} from "src/components/Table";
import {Cells} from "src/pages/Application/Content/Collection/Grid/Cells";
import {HeaderComponent} from "src/components/Header";
import {DeleteOutlined, QuestionCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {CreateUser} from './Create'
import {UserEdit} from "./Edit"


export const UsersPage: React.FC<{}> = () => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [userId, setUserId] = React.useState<string|undefined>(undefined)
    const [deleteId, setDeleteId] = React.useState<string | undefined>(undefined)
    const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false)
    const {notification} = App.useApp()

    const {breadcrumbs,} = useOutletContext<{
        breadcrumbs: any[],
    }>()

    const store = Table.useStore("users", {

        request: {
            method: "users.grid",
            params: {}
        },
        onClick: (row: any) => {
            setUserId(row.email._id)
        }

    })
    const deleteUser = (id: string) => {
        setConfirmLoading(true)
        Request("users.delete", {id: id}).then((response) => {
            if (response.result) {
                store.fetchData()
                notification.success({
                    message: i18n.t('user.deleted'),
                    placement: 'topRight',
                });
            }
            setDeleteId(undefined)
            setConfirmLoading(false)
        })

    }

    const actions = (row: any, index: number, column: any, id: any) => {
        const email = row.email
        return <div key={"actions-del-" + row._id}>
            <Popconfirm
                key={"user-delete-" + email._id}
                title={row.full_name.value}
                description={i18n.t("common.confirm-delete")}
                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}

                open={deleteId === email._id}
                onConfirm={() => {
                    if (deleteId) deleteUser(deleteId)
                }}
                cancelText={i18n.t("common.cancel")}
                okText={i18n.t("common.delete")}
                cancelButtonProps={{loading: confirmLoading}}
                okButtonProps={{loading: confirmLoading}}
                onCancel={() => {
                    setDeleteId(undefined)
                }}
            >
                <DeleteOutlined key={"user-delete-icon-" + email._id} onClick={() => {
                    setDeleteId(email._id)
                }}/>
            </Popconfirm>
        </div>
    }

    return (
        <>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: i18n.t("user.label"),
                },
            ]}/>
            <Flex justify={"space-between"} align={"center"}>
                <h1 style={{marginBottom: 0, marginTop: 0}}>{i18n.t("user.label")}</h1>
                <Button type={"primary"} onClick={() => {
                    setOpen(true)
                }}>{i18n.t("user.add")}</Button>
            </Flex>
            <div style={{padding: "20px 0"}}>
                <Input prefix={<SearchOutlined/>} style={{width: "300px"}} onChange={(event: any) => {
                    store.querySearch(event.currentTarget.value)
                }} placeholder={i18n.t("common.search")}/>
            </div>

            <Card>
                <Table store={store} cells={Cells} actions={actions}/>
            </Card>
            {open && <CreateUser open={open} onCLose={() => {
                store.fetchData()
                setOpen(false)
            }}/>}
            {userId &&
            <UserEdit open={!!userId} userId={userId} onEdit={()=>{store.fetchData()}} onClose={()=>{setUserId(undefined)}}/>
            }
        </>
    )
}
