import React from 'react';
import i18n from "i18next";
import {useNavigate, useLoaderData, useSearchParams} from "react-router-dom";
import {Form} from 'kaiju.core'
import {App, Button, Layout, Flex, Card} from 'antd';
import signUpStore from "./store"
import {Logo, Brand} from 'src/icons'
import './style.scss'


const SignIn = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {notification} = App.useApp()
    // // get data from loader
    useLoaderData();
    const navigateCallback = () => {
        const redirectTo = searchParams.get('redirectTo') || '/';
        navigate(redirectTo)
    };
    const formStore = Form.useFormStore(signUpStore({notification, navigateCallback}))

    return (
        <Layout.Content>
            <Flex justify={"center"} align={"center"} vertical>
                <Card bordered={false} className={"login-card"}>
                    <Flex justify={"center"} align={"center"} vertical>
                        <Logo/>
                        <h1>{i18n.t("project.label")}</h1>
                        <p style={{paddingTop: 0, marginTop: 0}}>{i18n.t("auth.label")} </p>
                    </Flex>

                    <Form store={formStore} key={"AuthForm"}>
                        <Button type="primary" className={"login-btn"}
                            onClick={formStore.submit}
                        >{i18n.t('auth.sign-in')}</Button>
                    </Form>
                </Card>
                <Brand/>
            </Flex>
        </Layout.Content>
    )
};

export {SignIn};
