import React from "react";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";
import {Button, Space, Drawer} from "antd";


export const CreateRole: React.FC<{ open: boolean, setOpen: any }> = ({open, setOpen}) => {
    const [store] = useDynamicForm({
        autoLoad: true,
        loadRequest: {
            method: 'Role.model',
            params: {}
        },
        saveDiff: false,
        loadExtraParams: {},
        saveRequest: {
            method: 'Role.create',
            params: {}
        },
        successCallback: (response: any) => {

        }
    });
    return (
        <Drawer size={"large"} title={i18n.t("role.create")} open={open} onClose={() => {
            setOpen(false)
        }}>
            <Form store={store.formStore}>
                <Space>
                    <Button onClick={() => {
                        store.submit()
                    }} type="primary">
                        {i18n.t("common.add")}
                    </Button>
                </Space>
            </Form>
        </Drawer>
    )
}