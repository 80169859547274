import React from 'react'
import {Flex, Button, Card, Affix, Divider, App} from "antd";
import i18n from "i18next";
import {useOutletContext, useParams} from "react-router-dom";
import {Form, useDynamicForm} from "kaiju.core";
import {HeaderComponent} from "src/components/Header";
import {Permission403} from "src/components/Permissions";


const ContentItemSource = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const {contentId, itemId, appId} = useParams<{ contentId: string, itemId: string, appId: string }>()
    const {notification} = App.useApp()

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        loadRequest: {
            method: "ContentGui.item.attributes",
            params: {
                application: appId,
                content: contentId,
                item: itemId
            }
        },
        loadExtraParams: {
            application: appId
        },
        saveRequest: {
            method: "ContentGui.item.save",
            params: {
                id: itemId,
                content: contentId
            }
        },
        successCallback: (props) => {
            notification.success({
                duration: 2,
                message: i18n.t("common.updated"),
            });
        }
    })

    return (
        <>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: itemId,
                },

            ]}/>

            <div style={{maxWidth: 800}}>
                {/*<Badge.Ribbon text={<b>IS PUBLISHED</b>} color="green">*/}
                <Card styles={{body: {paddingTop: 0}}}>
                    <Affix offsetTop={0}>
                        <div style={{background: "white", paddingTop: 24}}>
                            <Flex justify={"space-between"}>
                                <h1 style={{marginBottom: 0, marginTop: 0}}>{itemId}</h1>

                                <Button type={"primary"} onClick={() => {
                                    store.submit()
                                }}>{i18n.t("common.save")}</Button>
                            </Flex>
                            <Divider/>
                        </div>
                    </Affix>
                    <Form className={"collection-attrs"} store={store.formStore}/>
                </Card>
                {/*</Badge.Ribbon>*/}

            </div>

        </>
    )
}


const ContentItemPage: React.FC = () => {
    const {contentId} = useParams<{ contentId: string }>()
    return <>
        <Permission403 root={contentId} permissions={["update"]}>
            <ContentItemSource/>
        </Permission403>
    </>
}

export {ContentItemPage}
