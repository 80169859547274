import React from 'react';
import {Drawer, Steps, Flex, Card, Typography, Button} from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import i18n from "i18next";
import {ActionList} from "./Actions";
import './style.scss'


const steps = {
    Init: 0,
    Edit: 1,
    Confirm: 2
}

interface Props {
    collectionId: string,
    successCallback: () => void
    appId: string,
    open: boolean,
    onClose: () => void,
    selectedState: {
        allSelected: boolean,
        id: string[],
        count: number,
    }
}


const FooterMenu = (props: any) => {

    return <div style={{padding: 10}}>
        <Flex justify={"center"}>
            <Flex gap="small">
                <Button ghost type={"primary"}
                        disabled={props.inProcess}
                        size="large"
                        icon={<ArrowLeftOutlined/>}
                        onClick={props.onBack}
                >
                    {i18n.t("common.back")}
                </Button>
                <Button ghost type={"primary"}
                        disabled={props.step >= 2}
                        size="large"
                        icon={<ArrowRightOutlined/>}
                        onClick={props.onNext}
                        iconPosition={"end"}
                >
                    {i18n.t("common.next")}
                </Button>

            </Flex>
        </Flex>

    </div>
}


export const BulkActionMenu: React.FC<Props> = (props) => {
    const [currentAction, setCurrentAction] = React.useState<any>()
    const [currentStep, setCurrentStep] = React.useState(0);
    const [inProcess, setInProcess] = React.useState(false);

    React.useEffect(() => {
        if (!currentAction) {
            setInProcess(false)
        }
    }, [currentAction])
    const items = [
        {
            key: "choice",
            title: i18n.t("bulk-action.choice"),
        },
        {
            key: "configuration",
            title: i18n.t("bulk-action.configuration"),
        },
        {
            key: "approve",
            title: i18n.t("bulk-action.approve"),
        },
    ];
    const initStore = (action: any) => {
        setCurrentAction({
            key: action.key,
            title: action.title,
            Component: action.Component
        })
        setCurrentStep(1)
    }

    const onBack = () => {
        switch (currentStep) {
            case 1:
                setCurrentStep(steps.Init)
                setCurrentAction(undefined)
                break;
            case 2: {
                setCurrentStep(steps.Edit)
                break;
            }
        }
    }

    const toConfirm = () => {
        if (currentAction) {
            setCurrentStep(steps.Confirm)
        }
    }

    return (
        <Drawer
            width={"100%"}
            title={i18n.t("common.bulk-actions")}
            onClose={props.onClose}
            open={props.open}
            footer={currentAction ?
                <FooterMenu inProcess={inProcess} step={currentStep} onBack={onBack} onNext={toConfirm}/> : undefined}
        >
            <Flex justify={"center"}>
                <Flex justify={"center"} vertical>
                    <h2 style={{maxWidth: 1000, textAlign: "center", marginBottom: 0, fontWeight: 600}}>
                        {i18n.t("bulk-action.moto")}
                    </h2>
                    <Typography.Title style={{maxWidth: 1000, textAlign: "center", fontSize: 24, fontWeight: 400}}
                                      type={"secondary"}>
                        {currentAction ? i18n.t(currentAction.title) : i18n.t("bulk-action.choice-action")}
                    </Typography.Title>
                    <div style={{maxWidth: 1000}}>
                        <Steps current={currentStep} items={items}/>
                        <div style={{marginTop: 20}}>
                            {!currentAction &&
                                <Card>
                                    {ActionList.map((action: any) => {
                                        return <Card.Grid className={"action-card"} onClick={() => {
                                            initStore(action)
                                        }}>
                                            <Flex vertical gap={"middle"} align={"center"}>
                                                {action.icon}
                                                <span className={"action-title"}>
                                                {i18n.t(action.title)}
                                            </span>
                                            </Flex>
                                        </Card.Grid>
                                    })}
                                </Card>
                            }
                            {currentAction &&
                                <currentAction.Component
                                    currentStep={currentStep}
                                    selectedState={props.selectedState}
                                    collectionId={props.collectionId}
                                    appId={props.appId}
                                    toConform={() => {
                                        setInProcess(false)
                                        setCurrentStep(steps.Confirm)
                                    }}
                                    toEdit={() => {
                                        setInProcess(false)
                                        setCurrentStep(steps.Edit)
                                    }}
                                    setProcess={(state: boolean) => {
                                        setInProcess(state)
                                    }}
                                    inProcess={inProcess}
                                    isDone={() => {
                                        setInProcess(false)
                                        props.successCallback()
                                        props.onClose()
                                    }}
                                />
                            }
                        </div>
                    </div>
                </Flex>
            </Flex>
        </Drawer>
    )
}