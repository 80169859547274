import React from 'react'
import {Breadcrumb} from "antd";
import i18n from "i18next";
import {useOutletContext} from "react-router-dom";
import {HeaderComponent} from "src/components/Header";

const ContentTypeBuilderWelcomePage: React.FC = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()

    return (<>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("content-builder.label"),
            }
        ]}/>
        <h1 style={{marginBottom: 0}}>Добро пожаловать в констуруктор данных</h1>

    </>)
}

export {ContentTypeBuilderWelcomePage}