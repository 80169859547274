import React from "react";
import {useParams} from "react-router-dom";
import {Typography, Flex, Input, Button, Divider, Row, Col, Tag, Popconfirm} from "antd";
import i18n from "i18next";
import {ListComponent, useList} from "src/components/List";
import {DeleteOutlined, PlusOutlined, QuestionCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {CreateAttribute} from "./CreateAttribute";
import {Request} from "kaiju.core";
import {AttributeEdit} from "./AttributeEdit";


export const ComponentAttributes: React.FC<{ componentId: string, appId: string }> = ({componentId, appId}) => {
    const [open, setOpen] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
    const [deleteID, setDeleteId] = React.useState<string | undefined>(undefined);
    const [attrId, setAttrId] = React.useState<string | undefined>()

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        listStore.loadData()
    };
    // const current = builderStore.data.contents.filter((el: any) => (el._id === (contentId ? contentId : undefined)))[0]
    const onCloseView = () => {
        setAttrId(undefined)
    }
    const deleteAttribute = (componentId: string, id: string) => {
        setConfirmLoading(true)
        Request("ContentTypeBuilder.component.attribute.delete", {
            component: componentId,
            application: appId,
            id: id
        }).then((response: any) => {
            setDeleteId(undefined)
            setConfirmLoading(false)
            listStore.loadData()
        })
    }

    const [listStore] = useList(componentId, {

        request: {
            method: "ContentTypeBuilder.component.attribute.list",
            params: {
                component: componentId,
                application: appId,
            }
        },
        requestSort: {
            method: "ContentTypeBuilder.component.attribute.sort",
            params: {
                component: componentId,
                application: appId,
            }
        }
    })


    return <>
        <Flex justify={"space-between"}>
            <Input prefix={<SearchOutlined/>} style={{maxWidth: 400}} onChange={(event: any) => {
                listStore.search(event.currentTarget.value)
            }} placeholder={i18n.t("common.search")}/>
            <Button icon={<PlusOutlined/>} type={"primary"} onClick={showDrawer}>{i18n.t("common.create")}</Button>
        </Flex>
        <Divider/>
        <ListComponent
            store={listStore}
            onSortReplace={false}
            renderItem={(item, index: number) => {
                return (
                    <Row style={{width: "100%", cursor: "pointer"}} gutter={[8, 0]} onClick={() => {
                        setAttrId(item.id)
                    }}>
                        <Col md={6}>
                            <div style={{display: "flex"}} className={"fw-500"}>
                                <Typography.Paragraph style={{paddingLeft: "0.5rem"}}>
                                    <Tag color="green" style={{marginRight: 20}}>{index + 1}</Tag> {item.id}
                                </Typography.Paragraph>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{display: "flex"}}>
                                <Typography.Paragraph style={{paddingLeft: "0.5rem"}}>
                                    {item.label}
                                </Typography.Paragraph>
                            </div>
                        </Col>
                        <Col md={10}>
                            <Typography.Paragraph>
                                <Tag color="geekblue">{i18n.t("attribute-kind." + item.kind)}</Tag>
                            </Typography.Paragraph>
                        </Col>
                        <Col md={2}>
                            <Popconfirm
                                title={item.label}
                                description={i18n.t("common.confirm-delete")}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}

                                open={deleteID === item.id}
                                onConfirm={() => {
                                    if (componentId) deleteAttribute(componentId, item.id)
                                }}
                                cancelText={i18n.t("common.cancel")}
                                okText={i18n.t("common.delete")}
                                okButtonProps={{loading: confirmLoading}}
                                onCancel={() => {
                                    setDeleteId(undefined)
                                }}
                            >
                                <DeleteOutlined onClick={() => {
                                    setDeleteId(item.id)
                                }}/>
                            </Popconfirm>
                        </Col>
                    </Row>
                )
            }}
        />
        <CreateAttribute open={open} onClose={onClose} appId={appId} componentId={componentId}/>
        {(attrId && appId && componentId) &&
            <AttributeEdit appId={appId} componentId={componentId} onClose={onCloseView} key={"attr-view-" + attrId}
                           open={!!attrId} attrId={attrId} onEdit={onClose}/>
        }
    </>
}
