import React from "react";
import {Layout, Card, Flex, Button, Input} from "antd"
import {PlusOutlined, SearchOutlined} from "@ant-design/icons"
import {HeaderComponent} from "core/src/components/Header";
import {generatePath, useOutletContext} from "react-router-dom";
import {Table} from "core/src/components/Table";
import i18n from "i18next";
import {CreateTask} from "./Create"
import {EditTask} from "./EditTask"


export const TaskBoardPage = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()
    const [open, setOpen] = React.useState(false)
    const [taskId, setTaskId] = React.useState<number|undefined>(undefined)

    const store = Table.useStore("tracker", {
        request: {
            method: "TaskTracker.grid",
            params: {
            }
        },
        onClick: (row: any) => {
            setTaskId(row.id.value)
        }
    })

    const onClose = () => {
        setOpen(false)
    }
    const onCreate = () => {
        onClose()
        store.fetchData()
    }

    return <>
        <Layout
            className={"h-100"} style={{padding: "1.5rem"}}
        >
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Задачи",
                }
            ]}/>
            <h3>Задачи</h3>
            <Card>
                <Flex align={"center"} justify={"space-between"}>
                    <div style={{paddingBottom: 20, maxWidth: 500}}>
                        <Input prefix={<SearchOutlined/>} style={{maxWidth: 500}} onChange={(event: any) => {
                            store.querySearch(event.currentTarget.value)
                        }} placeholder={i18n.t("common.search")}/>
                    </div>
                    <Button type={"primary"} icon={<PlusOutlined/>} onClick={()=>{setOpen(true)}}>Создать задачу</Button>
                </Flex>
                <Table store={store}/>
            </Card>
            <CreateTask open={open} onClose={onClose} onCreate={onCreate}/>
            {taskId &&
                <EditTask taskId={taskId} onClose={()=>{setTaskId(undefined)}} onEdit={()=>{
                    store.fetchData()
                }}/>
            }
        </Layout>
    </>
}