interface MenuItemProps {
    key: string,
    label: string,
    icon: any,
    permissions: string[]
}

export class MenuStore {
    items: MenuItemProps[]

    constructor() {
        this.items = []
    }

    push = (newItem: MenuItemProps) => {
        if (!this.items.some((item: MenuItemProps) => (newItem.key === item.key))) {
            this.items.push(newItem)
        }
    }
    merge = (items: MenuItemProps[]) => {
        items.forEach((newItem: MenuItemProps) => {
            if (!this.items.some((item: MenuItemProps) => (newItem.key === item.key))) {
                this.items.push(newItem)
            }
        })
    }

}

export type {MenuItemProps}