import React from "react";
import {Modal, Button, App} from "antd"
import {UserOutlined} from "@ant-design/icons"
import {Form, Request} from "kaiju.core";
import i18n from "i18next";
import {useParams} from "react-router-dom";

interface Params {
    contentId: string,
}

export const CustomersCreate:React.FC<{tableStore: any}> = ({tableStore}) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const {contentId} = useParams<keyof Params>() as Params

    const {notification} = App.useApp()

    const onClose = () => {
        setOpen(false)
    }
    const formStore = Form.useFormStore({
        fields: [{
            id: "customer-create",
            fields: [
                {
                    id: "name",
                    kind: "string",
                    required: true,
                    label: "ФИО"
                }
            ]
        }],
        disableGroups: true,
        onSubmit: (values: any, formikState: any) => {
            Request("WaymorrGui.customers.create", {
                ...values,
                collection: contentId
            }).then((response) => {
                if (response.result) {
                    notification.success({
                        message: "Пользователь создан",
                        placement: 'topRight',
                    });
                    onClose()
                    tableStore.fetchData()
                    formikState.resetForm({})
                }
            })
        }
    })
    const onCancel = () => {
        onClose()
        formStore.formik.resetForm({})
    }

    return <>
        <Button icon={<UserOutlined/>} type={"primary"} onClick={() => {
            setOpen(true)
        }}>Создать клиента</Button>
        <Modal open={open} onClose={onClose} onCancel={onCancel} onOk={formStore.submit}>
            <Form store={formStore} key={"CreateCustomerForm"}/>

        </Modal>
    </>
}