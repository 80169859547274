import React from "react";
import i18n from "i18next";
import {Permission} from "kaiju.core";
import {Breadcrumb, Flex, Popover, Divider, Badge, Tooltip, Drawer} from "antd";
import {UserOutlined, BellOutlined, SettingOutlined} from "@ant-design/icons";
import {generatePath, useNavigate} from "react-router-dom";
import user from 'src/components/User'
import {ROUTE} from "src/routing";
import Plugin from "src/plugin";

interface BreadcrumbProps {
    title: any
}

const UserMenu: React.FC<{ setOpen: any }> = ({setOpen}) => {
    return (
        <>
            <Divider style={{marginTop: 10, marginBottom: 10}}/>
            <Flex vertical>
                <a onClick={() => {
                    setOpen(true)
                }} style={{paddingBottom: "0.5rem"}}>{i18n.t("user.profile")}</a>
                <a href={"/logout"}>{i18n.t("auth.logout")}</a>
            </Flex>
        </>
    )
}

const NotificationsManu: React.FC<{}> = () => {
    return <>
        <Divider style={{marginTop: 10, marginBottom: 10}}/>
        {i18n.t("common.empty")}
    </>
}

export const UserBlock: React.FC<{}> = () => {
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()
    const extraButtons: any[] = []
    Plugin.invoke("header.extraButtons", extraButtons)

    return (
        <>
            <Flex wrap gap="middle">
                {/*Render custom button for header*/}
                {extraButtons.map((Comp: React.FC)=>(<Comp/>))}
                {/*<Popover title={i18n.t("common.notifications")} content={<NotificationsManu/>}>*/}
                {/*    <Badge count={5}>*/}
                {/*        <BellOutlined style={{fontSize: 24, cursor: "pointer"}}/>*/}
                {/*    </Badge>*/}
                {/*</Popover>*/}
                <Popover title={user.fullName} content={<UserMenu setOpen={setOpen}/>}>
                    <UserOutlined style={{fontSize: 24, cursor: "pointer"}}/>
                </Popover>
                <Permission userPermission={user.permissions} permission={["system", "admin"]}>
                    <Tooltip title={i18n.t("system-settings.label")}>
                        <SettingOutlined style={{fontSize: 24, cursor: "pointer"}} onClick={() => {
                            navigate(generatePath(ROUTE.SystemSettings))
                        }}/>
                    </Tooltip>
                </Permission>
            </Flex>

            <Drawer title={user.fullName} open={open} onClose={() => {
                setOpen(false)
            }}>
                USER SETTING
            </Drawer>
        </>
    )
}

export const HeaderComponent: React.FC<{ breadcrumbs: BreadcrumbProps[] }> = ({breadcrumbs}) => {
    return (
        <>
            <Flex justify={"space-between"} style={{paddingBottom: 20}} align={"flex-start"}>
                <Breadcrumb
                    items={breadcrumbs}
                />
                <UserBlock/>
            </Flex>

        </>


    )
}

export type {BreadcrumbProps}