const confs: {[key: string]: any } = {
    method: "POST",
    path: '/public/rpc',
    headers: {'Content-Type': 'application/json'}
}

interface RequestInterface {
    (method: string, params?: any): Promise<any>;
}

const request: RequestInterface = async (method, params): Promise<any> => {
    const requestOptions = {
        method: confs.method,
        headers: confs.headers,
        body: JSON.stringify({
            method: method,
            params: params
        })
    };
    return await fetch(confs.path, requestOptions)
        .then((response: Response) => {
            const _data: Promise<any> = response.json()
            return _data
        });
};

export default request