import React from "react";
import Uppy from '@uppy/core';
import {Dashboard} from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import MD5 from "crypto-js/md5";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal} from 'antd';
import i18n from "i18next";
import {StoreInterface} from "./store";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const getImgDimension = async (url: any) => {
    return new Promise(resolve => {
        let img = new Image();
        img.onload = function () {
            URL.revokeObjectURL(img.src);
            resolve({
                width: img.width,
                height: img.height
            });
        }
        img.src = url;
    });
}

export const CreateAssets: React.FC<{ store: StoreInterface }> = ({store}) => {
    const [open, setOpen] = React.useState(false);
    const [uppy] = React.useState(() => new Uppy({
            allowMultipleUploadBatches: true,
            autoProceed: true,

            restrictions: {
                // maxFileSize:
                // allowedFileTypes:
                // maxNumberOfFiles:
                // maxNumberOfFiles:
            },

        }).use(XHRUpload, {
            endpoint: '/files',
            withCredentials: true,
            formData: false,
            method: "PUT",
            fieldName: "file",
        })
    );
    React.useEffect(() => {
        let timerId: any = undefined;

        uppy.on("upload-success", () => {
            if (timerId) clearTimeout(timerId)
            timerId = setTimeout(() => {
                store.assetTableStore.fetchData()
            }, 200)

        })
        uppy.addPreProcessor(async (fileIDs) => {
            await Promise.all(fileIDs.map(async (id: string) => {
                const {extension, size, name, type, data} = uppy.getFile(id)

                const _asset: { [p: string]: any } = {
                    name,
                    extension,
                    file_type: type,
                    size: size,
                    md5: null,
                    meta: {
                        ...store.metaData,
                        type: type
                    },
                    folder: store.currentFolder
                }
                const url = URL.createObjectURL(data)

                if (type?.startsWith("image/")) {
                    const dimensions = await getImgDimension(url)
                    if (dimensions) {
                        _asset.meta["dimensions"] = dimensions
                    }
                }

                _asset["md5"] = MD5(await data.text()).toString()

                URL.revokeObjectURL(url)
                const endpoint = await store.createAssets(_asset)
                if (endpoint) {
                    uppy.setFileState(id, {xhrUpload: {endpoint}})
                }
                return id
            }))

        })
    }, [])
    return <>


        <Button type="dashed" icon={<PlusOutlined/>} onClick={() => {
            setOpen(true)
        }}>
            {i18n.t("media.asset-add")}
        </Button>
        <Modal width={800} title={i18n.t("media.asset-add")} onCancel={() => setOpen(false)} footer=""
               open={open}>
            <Dashboard id="dashboard-" uppy={uppy}/>
        </Modal>
    </>
}