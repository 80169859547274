import React from "react";
import {Form as KForm} from "kaiju.core"
import {getIn} from "formik";
import {Form, Divider, Flex, Empty, Spin} from "antd";
import * as Yup from 'yup';
import i18n from "i18next";
import {MediaLibSelector} from './MediaLibSelector'
import {useStore} from "src/components/MediaLibraryFolder";
import {AssetNode} from "src/components/MediaLibraryFolder/AssetView";
import './style.scss'

export class MediaStore extends KForm.Fields.FieldStore {
    validator: any;
    mediaId: string;

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.mediaId = props.reference;
        this.validator = Yup.array().of(Yup.string()).nullable();
        this.makeValidator(props)
    }

    Component: React.FC = (props: any) => {
        const errors = props.errors || props.formik.errors;
        const _id = this._id(props);

        const store = useStore({
            id: this.mediaId,
            onChoice: () => {},
            defaultCheckedValues: getIn(props.formik.values, _id),
            uploadProps: {
                method: "PUT",
                action: "/"
            },
            metaData: {
                entity: this.mediaId
            },
            requests: {
                loadAssets: {
                    method: "MediaLibraryGui.asset.grid",
                    params: {
                        media: this.mediaId
                    }
                },
                loadCheckedAssets: {
                    method: "MediaLibraryGui.asset.list",
                    params: {
                        media: this.mediaId
                    }
                },
                createAssets: {
                    method: "MediaLibraryGui.asset.create",
                    params: {
                        media: this.mediaId
                    }
                },
                loadFolders: {
                    method: "MediaLibraryGui.folder.info",
                    params: {
                        media: this.mediaId
                    }
                },
                createFolder: {
                    method: "MediaLibraryGui.folder.create",
                    params: {
                        media: this.mediaId
                    }
                }
            }
        })

        const onSave = () => {
            props.formik.setFieldValue(_id, store.checkedValues)
        }
        const getValues = () => {
            return getIn(props.formik.values, _id) || []
        }
        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Divider style={{marginTop: 5, marginBottom: 20}}/>
                    <Flex gap={"middle"} className={"media-asset-viewer"}>
                        {(getIn(props.formik.values, _id) && getIn(props.formik.values, _id).length > 0) &&
                            getIn(props.formik.values, _id).map((id: string) => {
                                if (Object.keys(store.assetMap).includes(id)) {
                                    return <AssetNode key={"asset-item-" + id}
                                                      isSimple={true}
                                                      disableCheck={true}
                                                      store={store}
                                                      asset={store.assetMap[id]}/>
                                }
                                return <Spin size="large"/>
                            })
                        }
                    </Flex>
                    <Flex justify={"center"} align={"center"}>
                        {store.loadingMap &&
                            <Spin size="large"/>
                        }
                    </Flex>

                    {(!getIn(props.formik.values, _id) || getIn(props.formik.values, _id).length === 0) &&
                        <Empty style={{paddingBottom: 20}} description={i18n.t("media.no-assets")}/>
                    }

                    <Flex align={"center"} justify={"center"} style={{width: "100%"}}>
                        <MediaLibSelector getValues={getValues} title={this.label} store={store} onSave={onSave} mediaId={this.mediaId}/>
                    </Flex>
                    <KForm.Error message={errors[this.id]}/>
                </Form.Item>
            </>
        )
    }

}

