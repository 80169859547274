import React from 'react'
import {Layout, Flex, Menu} from 'antd';
import {useMatches, useNavigate, useLocation} from 'react-router-dom';
import './style.scss'
import Logo from "src/icons/logo";
import {isPermitted} from "src/components/Permissions";

const handleRoutes = (items: any[]) => {
    return items.filter((item: any) => {
        return true
    })
}

const Sidebar: React.FC<{ data?: any[] }> = ({data}) => {
    const menuData: any[] | undefined = data?.filter((el) => (isPermitted("", el.permissions)))
    const navigate = useNavigate();
    const matches = useMatches();
    const defaultSelectedKeys = menuData ? menuData.filter((el: any) => (
        matches.some((y: any) => (y.pathname === el.key))
    )) : []



    const onClick = ({key}: any) => {
        navigate(key)
    }
    return (
        <Layout.Sider collapsed={true} collapsedWidth={60} className={"sidebar-s"}
                      style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}>
            <div>
                <Logo fill={"white"} width={"100%"} className={"logo"} onClick={() => {
                    navigate("/")
                }}/>
                <Menu theme="dark" defaultSelectedKeys={[defaultSelectedKeys?.[0]?.key]} mode="inline"
                      items={handleRoutes(menuData ? menuData : [])} onClick={onClick}/>
            </div>
        </Layout.Sider>
    )
}

export {Sidebar}