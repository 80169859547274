import React from "react";
import Icon from '@ant-design/icons';
import type {GetProps} from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

const CartSvg = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="1em" height="1em" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="currentColor" stroke="none">
                <path d="M2405 4776 c-295 -58 -544 -262 -653 -536 -45 -113 -56 -179 -56
-320 0 -156 15 -229 74 -357 50 -107 88 -163 169 -246 111 -115 261 -203 416
-243 98 -26 313 -25 410 0 155 41 276 108 388 215 92 88 144 161 197 275 59
127 74 200 74 356 0 156 -15 229 -74 356 -53 114 -105 187 -197 275 -111 106
-236 175 -388 215 -84 21 -273 27 -360 10z m278 -301 c223 -47 406 -244 439
-474 55 -373 -270 -698 -643 -643 -246 36 -445 235 -481 481 -36 244 91 483
314 590 126 60 238 75 371 46z"/>
                <path d="M695 4140 c-198 -44 -352 -180 -417 -368 -32 -95 -32 -249 0 -344 56
-164 180 -288 348 -350 85 -31 263 -31 348 0 168 62 292 186 348 350 32 95 32
249 0 344 -57 164 -183 291 -348 349 -67 23 -215 33 -279 19z m190 -307 c92
-31 165 -134 165 -233 0 -132 -118 -250 -250 -250 -60 0 -130 30 -175 75 -98
98 -98 252 0 350 69 70 164 91 260 58z"/>
                <path d="M4215 4140 c-198 -44 -352 -180 -417 -368 -18 -53 -23 -89 -23 -172
0 -83 5 -119 23 -172 56 -164 180 -288 348 -350 85 -31 263 -31 348 0 168 62
292 186 348 350 32 95 32 249 0 344 -57 164 -183 291 -348 349 -67 23 -215 33
-279 19z m190 -307 c92 -31 165 -134 165 -233 0 -132 -118 -250 -250 -250 -60
0 -130 30 -175 75 -98 98 -98 252 0 350 69 70 164 91 260 58z"/>
                <path d="M535 2693 c-141 -30 -294 -123 -381 -230 -25 -32 -63 -94 -85 -138
-68 -139 -69 -148 -69 -821 0 -677 -1 -666 73 -750 22 -25 63 -57 91 -72 l51
-27 496 -3 497 -3 12 -47 c27 -109 96 -192 199 -241 l56 -26 1085 0 1085 0 56
26 c103 49 172 132 199 241 l12 47 492 3 c478 3 492 4 537 24 60 28 119 87
150 150 l24 49 3 612 c3 680 2 696 -62 828 -95 199 -269 337 -478 379 -106 22
-401 22 -506 0 -190 -39 -355 -158 -449 -323 -15 -25 -28 -48 -30 -50 -1 -2
-36 28 -77 66 -157 150 -349 249 -578 300 -122 27 -634 27 -756 0 -232 -51
-434 -158 -595 -314 -39 -38 -62 -53 -65 -45 -2 8 -27 47 -54 89 -91 135 -255
244 -420 277 -103 21 -414 21 -513 -1z m536 -320 c80 -40 150 -111 187 -191
23 -50 26 -70 27 -162 0 -86 -5 -120 -26 -190 -40 -127 -49 -233 -49 -572 l0
-308 -455 0 -456 0 3 588 3 587 27 57 c48 103 143 186 247 215 32 9 106 12
236 10 l190 -2 66 -32z m1854 9 c254 -69 472 -246 586 -477 92 -187 93 -189
97 -749 3 -450 2 -490 -14 -507 -17 -18 -48 -19 -1035 -19 -925 0 -1019 1
-1034 16 -14 15 -16 65 -13 508 4 469 5 495 26 571 68 248 242 466 460 578
183 94 257 107 587 103 230 -2 269 -5 340 -24z m1676 -9 c80 -39 150 -111 187
-191 l27 -57 3 -587 3 -588 -456 0 -455 0 0 308 c0 339 -9 445 -49 572 -21 70
-26 104 -26 190 1 92 4 112 27 162 48 102 143 186 247 215 32 9 106 12 236 10
l190 -2 66 -32z"/>
            </g>
        </svg>
    )
}

export const CustomersIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={CartSvg} {...props} />
);