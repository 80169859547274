import React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {Tabs, Card, Typography} from "antd";
import i18n from "i18next";
import {Attributes} from "./Attributes";
import {Permissions} from "./Permissions";
import {useList} from "src/components/List";
import {Settings} from "./Settings"
import {HeaderComponent} from "src/components/Header";

export const CTBSingleTypePage: React.FC = () => {
    const {breadcrumbs, builderStore} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const {singleId, appId} = useParams<{ singleId: string, appId: string }>()
    const current = builderStore.data.single.filter((el: any) => (el.id === (singleId ? singleId : undefined)))[0]
    const [listStore] = useList(singleId, {
        request: {
            method: "ContentTypeBuilder.single.attribute.list",
            params: {
                application: appId,
                single: singleId
            }
        },
        requestSort: {
            method: "ContentTypeBuilder.single.attribute.sort",
            params: {
                application: appId,
                single: singleId
            }
        }
    })

    const items = [
        {
            key: 'attributes',
            label: i18n.t("attribute.label"),
            children: <Attributes appId={appId} singleId={singleId} listStore={listStore} key={'attr-' + singleId}/>,
        },
        {
            key: 'settings',
            label: i18n.t("common.settings"),
            children: <Settings appId={appId} singleId={singleId} key={'settings-' + singleId}/>,
        },
        {
            key: 'permissions',
            label: i18n.t("common.permissions"),
            children: <Permissions appId={appId} singleId={singleId}/>,
        },

    ];

    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("content-builder.label"),
            },
            {
                title: i18n.t("single.label"),
            },
            {
                title: current ? current.label : "[...]",
            },
        ]}/>

        <Typography.Title level={1} copyable={{text: current?.id, tooltips: current?.id}} style={{marginTop: 0}}>
            {/*ID: {current ? current.id : "[...]"}*/}
            {current ? current.label : "[...]"}
        </Typography.Title>

        <p style={{marginTop: "1rem", marginBottom: "2rem", color: "rgb(140 140 140)"}}>{i18n.t("single.moto")}</p>
        <Card>
            <Tabs defaultActiveKey="1" items={items}/>
        </Card>
    </>
}
