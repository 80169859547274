import React from 'react'
import i18next from "i18next";
import {Form, Request} from 'kaiju.core'
import {Button, Drawer, Space, notification} from 'antd';
import i18n from "i18next";
import {useParams} from "react-router-dom";


const ContentCreate: React.FC<{ open: boolean, onClose: any, onCreate: Function }> = ({open, onClose, onCreate}) => {
    const {appId} = useParams();

    const [api, contextHolder] = notification.useNotification();
    const FormConf = {
        fields: [{
            id: "create",
            fields: [
                {
                    id: "id",
                    kind: "string",
                    required: true,
                    label: i18n.t("common.id"),
                    max: 36,
                    reg_exp: "^[a-z0-9][a-z0-9_-]+$",
                    reg_exp_text: i18n.t("collection.error.id")
                },
                {
                    id: "label",
                    kind: "string",
                    required: true,
                    max: 40,
                    label: i18n.t("common.label")
                },
            ]
        }],
        disableGroups: true,
        onSubmit: (values: any, formikState: any) => {
            Request('ContentTypeBuilder.medialibrary.create', {
                ...values,
                application: appId,
            }).then((data) => {
                const error = data.error;
                if (error) {
                    const code = error.code;
                    const message = i18n.t("collection.error.duplicate");
                    if (code === 409) {
                        formikState.setFieldError("id", message)
                        api.error({
                            message: message,
                            placement: 'topRight',
                            duration: 2
                        })
                    }
                } else {
                    onCreate(data)
                    close()
                }
            })
        }
    };

    const formStore = Form.useFormStore(FormConf)
    const close = () => {
        onClose()
        formStore.reset({})
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title={i18next.t("collection.create-new")}
                width={720}
                onClose={close}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={close}>{i18next.t("common.cancel")}</Button>
                        <Button onClick={formStore.submit} type="primary">
                            {i18next.t("common.create")}
                        </Button>
                    </Space>
                }
            >
                <Form store={formStore} key={"content-create"}/>
            </Drawer>
        </>
    )
}

export {ContentCreate}