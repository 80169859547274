import React from 'react'

export default function (props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width="72"
             height="72"
             viewBox="0 0 260.000000 260.000000"
             {...props}
        >
            <path
                d="M696 2316c-30-11-117-92-348-323-169-169-315-323-324-342-37-78-26-169 28-235 44-53 95-76 171-76 53 0 70 5 108 29 24 16 175 161 335 322 320 323 331 339 322 438-14 141-161 235-292 187zM1491 2035c-37-12-85-54-284-252-151-151-246-253-258-278-24-51-24-128 0-180 22-49 77-99 126-115 59-19 146-9 193 23 21 15 138 128 259 250 237 239 247 254 246 352-1 143-144 245-282 200zM2295 1821c-27-12-243-222-641-622-557-559-602-607-614-651-24-91 11-188 88-242 58-41 150-48 214-17 52 25 1206 1177 1233 1230 9 19 19 59 22 90 4 48 1 64-24 111-54 102-181 148-278 101zM644 1197c-204-98-138-407 86-407 162 0 265 155 199 300-25 57-44 77-93 102-62 32-132 33-192 5z"
                transform="matrix(.1 0 0 -.1 0 260)"
            />
        </svg>
    )
}
