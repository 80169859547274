import React from 'react'
import {Request} from "kaiju.core";

interface DataType {
    collection: any[]
    single: any[]
}

const useStore = ({appId}: any) => {
    const [data, setData] = React.useState<DataType>({
        collection: [],
        single: []
    })
    const [open, setOpen] = React.useState<boolean>(true)

    const fetchCollection = async () => {
        return await Request('SettingsGuiService.get', {application: appId}).then((resp: any) => {
            return resp.result.content || []
        })
    }

    const fetchSingle = async () => {
        return await Request('ContentTypeBuilder.single.list', {application: appId}).then((resp: any) => {
            return resp.result || []
        })
    }

    const loadAll = async () => {
        const collection = await fetchCollection()
        const single = await fetchSingle()
        setData({
            collection: collection,
            single: single,
        })
    }

    React.useEffect(() => {
        loadAll()
    }, [])

    return {
        appId,
        data,
        open,
        loadAll,
        setOpen,
        onClose: () => {
            setOpen(false)
        },
        refresh: () => {
            loadAll().then()
        },
        toggle: () => {
            setOpen(!open)
        }
    }
}

export {useStore}