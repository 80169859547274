import React from "react";
import {App, Divider, Modal} from "antd";
import i18n from "i18next";
import {Form, useDynamicForm} from "kaiju.core";

export const GroupEdit: React.FC<{ appId: string, id: string, onClose: any, open: boolean }> = (props) => {
    const {notification} = App.useApp()

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: false,
        saveDiff: false,
        loadRequest: {
            method: "ContentTypeBuilder.component.group.get",
            params: {
                application: props.appId,
                id: props.id
            }
        },
        saveRequest: {
            method: "ContentTypeBuilder.component.group.update",
            params: {
                application: props.appId,
                id: props.id,
            }
        },
        successCallback: () => {
            notification.success({
                message: i18n.t('common.updated'),
                placement: 'topRight',
            });
            props.onClose()
        }
    })
    return (
        <Modal title={i18n.t("component.group-edit")}
               open={props.open}
               onOk={()=>{store.submit()}}
               onCancel={props.onClose}
               okText={i18n.t("common.save")} cancelText={i18n.t("common.cancel")}>
            <Divider/>
            <Form store={store.formStore} key={"content-create"}/>
        </Modal>
    )
}