import React from 'react'
import {Request} from "kaiju.core";
import i18n from "i18next";


const useStore = (notification: any, contentId?: string) => {
    const [fullColumns, setFullColumns] = React.useState<any[]>([])
    const [columns, setColumns] = React.useState<string[]>([])

    const loadCurrentColumns = async () => {
        const columns = await Request("ContentGui.column.get", {content: contentId}).then((resp: any) => {
            return resp.result
        })
        setColumns(columns)
    }

    const loadAllColumns = async () => {
        const columns = await Request("ContentGui.column.list", {content: contentId}).then((resp: any) => {
            return resp.result.data
        })
        setFullColumns(columns)
    }

    const fullLoad = async () => {
        await loadAllColumns()
        await loadCurrentColumns()
    }

    React.useEffect(() => {
        fullLoad().then()
    }, [contentId])

    const addColumns = (columns: string[]) => {
        setColumns(columns)
    }
    const saveColumns = () => {
        Request("ContentGui.column.set", {content: contentId, columns}).then((resp: any) => {
            if (resp.result) {
                notification.info({
                    message: i18n.t("column.success-save"),
                    placement: 'topRight',
                });
            } else {
                notification.error({
                    message: i18n.t("column.error.internal"),
                    placement: 'topRight',
                });
            }
        })
    }

    const dataMap = () => {
        return fullColumns.filter((val) => (columns.includes(val.value))).sort((x, y) => (columns.indexOf(x.value) - columns.indexOf(y.value)))
    }

    const removeColumn = (value: string) => {
        setColumns(columns.filter((el)=>el !== value))
    }

    return {
        saveColumns,
        addColumns,
        removeColumn,
        dataMap,
        fullColumns,
        columns
    }
}

export {useStore}