import React from 'react'
import {HeaderComponent} from "src/components/Header";
import {useOutletContext, useParams} from "react-router-dom";
import {Permission403, Permissions} from "src/components/Permissions";
import i18n from "i18next";
import {App, Badge, Button, Card, Modal, Flex} from "antd";
import {Form, useDynamicForm, Request} from "kaiju.core";
import {PublishTag} from "src/components/Utils";
import {useStore} from "./store"


interface FormProps {
    singleDataStore: any
}

type Params = {
    singleId: string,
    appId: string
};


const SingleEdirForm: React.FC<FormProps> = (props) => {
    const {singleId, appId} = useParams<keyof Params>() as Params
    const [loading, setLoadding] = React.useState<boolean>(false)
    const {notification} = App.useApp()
    const [modal, contextHolder] = Modal.useModal();

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        loadRequest: {
            method: "SingleGui.item.attributes.get",
            params: {
                application: appId,
                id: singleId,
            }
        },
        loadExtraParams: {
            application: appId
        },
        saveRequest: {
            method: "SingleGui.item.attributes.update",
            params: {
                application: appId,
                id: singleId,
            }
        },
        successCallback: (props) => {
            notification.success({
                duration: 2,
                message: i18n.t("common.updated"),
            });
        }
    })

    const setPublish = () => {
        setLoadding(true)
        Request("SingleGui.item.publish", {
            application: appId,
            id: singleId,
        }).then(() => {
            props.singleDataStore.refresh()
            setLoadding(false)
        })
    }

    const confirm = () => {
        modal.confirm({
            content: !props.singleDataStore.singleData?.is_published ?
                i18n.t("publish.publish-warning") : i18n.t("publish.unpublish-warning"),
            onOk: () => {
                setPublish()
            },
            okText: i18n.t("common.confirm"),
            cancelText: i18n.t("common.cancel"),
        });
    };

    return <>
        <Flex justify={"space-between"} align={"center"} style={{paddingTop: 0, paddingBottom: 20}}>
            <h1 style={{marginBottom: 0, marginTop: 0}}>{props.singleDataStore.singleData?.meta?.label}</h1>

            <Flex gap={"small"} align={"center"}>
                <Permissions root={singleId} permissions={["publish"]}>
                    <Button type={"primary"} ghost
                            loading={loading} onClick={() => {
                        confirm()
                    }}>
                        {!props.singleDataStore.singleData?.is_published ? i18n.t("publish.publish") : i18n.t("publish.unpublish")}
                    </Button>
                </Permissions>

                <Permissions root={singleId} permissions={["update"]}>
                    <Button type={"primary"} onClick={() => {
                        store.submit()
                    }}>{i18n.t("common.save")}</Button>
                </Permissions>
            </Flex>

        </Flex>
        <div style={{maxWidth: 800}}>
            <Badge.Ribbon text={<b>{PublishTag(props.singleDataStore.singleData?.is_published)}</b>}
                          color={props.singleDataStore.singleData?.is_published ? "green" : "rgb(137,137,137)"}>
                <Card>
                    <Form className={"collection-attrs"} store={store.formStore}/>
                </Card>
            </Badge.Ribbon>
        </div>
        {contextHolder}
    </>
}


export const SinglePage: React.FC = () => {
    const {singleId, appId} = useParams<keyof Params>() as Params
    const singleDataStore: any = useStore({singleId, appId})
    const {breadcrumbs} = useOutletContext<{
        breadcrumbs: any
    }>()
    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("single.label"),
            },
            {
                title: singleDataStore.singleData?.meta?.label || "[...]",
            },

        ]}/>
        <Permission403 root={singleId} permissions={["update", "read"]}>
            <SingleEdirForm singleDataStore={singleDataStore} key={"single-page-" + singleId}/>
        </Permission403>
    </>
}