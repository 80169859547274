import React from 'react'
import {useOutletContext, useParams} from "react-router-dom";
import {HeaderComponent} from "src/components/Header";
import {MediaLibrary} from "src/components/MediaLibrary";

const MediaLibraryPage: React.FC = () => {
    const {mediaId} = useParams<{ mediaId: string | undefined }>()

    const {breadcrumbs} = useOutletContext<{
        breadcrumbs: any,
    }>()

    const onChoice = (values: string[]) => {

    }

    return (
        <>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs
            ]}/>
            {mediaId &&
                <MediaLibrary onChoice={onChoice} mediaId={mediaId} key={"media-lib-" + mediaId} removeAssets={true}/>
            }
        </>
    )
}

export {MediaLibraryPage}
