import React from "react";
import {Layout, Card, Flex, Input, Badge, Button, Table} from "antd"
import {useOutletContext} from "react-router-dom";
import {HeaderComponent} from "core/src/components/Header";


const columns: any[] = [
    {
        title: 'Клиент',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Депозит',
        dataIndex: 'deposit',
        key: 'deposit',
    },
    {
        title: 'Оборудование',
        dataIndex: 'eq',
        key: 'eq',
        // width: '12%',
        render: (_: any, record: any) => {
            if (record.isService) {
                return <b>{record.eq}</b>
            }
            return <>{record.eq}</>
        }
    },
    {
        title: 'Дата установки',
        dataIndex: 'ust',
        key: 'ust',
    },
    {
        title: 'Дата снятия',
        dataIndex: 'sn',
        key: 'sn',
    },
    {
        title: 'Хостинг',
        dataIndex: 'city',
        key: 'city',
    },
    {
        title: 'Тариф',
        dataIndex: 'tariff',
        key: 'tariff',
        // width: '12%',
    },
    {
        title: 'Количество часов',
        dataIndex: 'total_h',
        key: 'total_h',
    },
    {
        title: 'Простой',
        dataIndex: 'total_p',
        key: 'total_p',
        render: (_: any, record: any) => {
            if (!record.total_h) {
                return <></>
            }
            return <>{record.total_h - record.work_h}</>
        }
    },
    {
        title: 'Рабочие часы',
        dataIndex: 'work_h',
        key: 'work_h',
    },
    {
        title: 'К зачету',
        dataIndex: 'to_pay',
        key: 'to_pay',
        render: (_: any, record: any) => {
            if (record.to_pay) {
                return <b>{record.to_pay}</b>
            }

            if (record.tariff && record.total_h) {
                return <b>{Math.ceil(record.tariff / record.total_h * record.work_h)}</b>
            }

            return <></>
        }

    },
    {
        title: 'Баланс',
        dataIndex: 'balance',
        key: 'balance',
        render: (_: any, record: any) => {
            return <b>{record.balance}</b>
        }
    },
    {
        title: 'Остаток',
        dataIndex: 'ost',
        key: 'ost',
        render: (_: any, record: any) => {
            return <b>{record.ost}</b>
        }
    },

];

const data: any[] = [
    {
        key: 1,
        name: 'Фомин Дмитрий',
        deposit: 6500,
        balance: 36300,
        to_pay: 26152,
        ost: 10148,
        children: [
            {
                key: 11,
                tariff: 12100,
                total_h: 744,
                work_h: 672,
                eq: 'Antmainer L7 (JYZZDCFBDJFJB06HZ)',
                city: "Иркутск"
            },
            {
                key: 12,
                tariff: 12100,
                total_h: 744,
                work_h: 680,
                eq: 'Antmainer L7 (JYZZDCFBCABBH0582)',
                city: "Иркутск"
            },
            {
                key: 13,
                tariff: 12100,
                total_h: 744,
                work_h: 700,
                eq: 'Antmainer L7 (HXXYDCCBBJABE035C)',
                city: "Иркутск"
            },
            {
                isService: true,
                key: 1111,
                eq: 'Установка на хостинг',
                to_pay: 2000,
            }
            // {
            //     key: 14,
            //     to_pay: 36300,
            //     work_h: <b>Баланс:</b>,
            // },
            // {
            //     key: 15,
            //     to_pay: 26152,
            //     work_h: <b>К зачету:</b>,
            // },
            // {
            //     key: 16,
            //     to_pay: 10148,
            //     work_h: <b>Остаток:</b>,
            // },

        ],
    },
    {
        key: 2,
        name: 'Сулейкин Александр',
        deposit: 6500,
        balance: 36300,
        to_pay: 33375,
        ost: 2925,
        children: [
            {
                key: 22,
                tariff: 12100,
                total_h: 744,
                work_h: 722,
                eq: 'Antmainer L7 (JYZZDCCBCABBH02W6)',
                city: "Иркутск"
            },
            {
                key: 23,
                tariff: 16000,
                total_h: 744,
                work_h: 670,
                eq: 'Whatsminer (HTM32S20KA24012713268144533A14943)',
                city: "Пермь"
            },
            {
                isService: true,
                key: 11113,
                eq: 'Ремонт',
                to_pay: 4500,
            }

        ],
    },

];


export const MonitoringPage: React.FC<{}> = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()


    return <>
        <Layout
            className={"h-100"} style={{padding: "1.5rem"}}
        >
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Мониторинг",
                }
            ]}/>
            <h2>Мониторинг: 08.2024</h2>

            <Table
                expandable={{defaultExpandAllRows: true}}
                columns={columns}
                dataSource={data}
            />
        </Layout>
    </>
}