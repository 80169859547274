import React from "react";
import {Tag} from "antd";
import {ApiTwoTone} from "@ant-design/icons"
import {BulkUpdate} from "./BulkUpdate"

export const ActionList = [
    {
        key: "bulkUpdateAttributes",
        title: "bulk-action.bulk-update-attributes",
        icon: <Tag className={"icon-tag"} color={"blue"}>
            <ApiTwoTone/>
        </Tag>,
        Component: BulkUpdate
    }

]