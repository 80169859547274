import React from "react";
import {Request} from "kaiju.core";

interface Props {
    singleId: string,
    appId: string
}

interface storeInterface {
    singleData: any
    refresh: () => void
}

export const useStore = (props: Props): storeInterface => {
    const [singleData, setSingleData] = React.useState<any>()

    const loadData = (singleId: string) => {
        Request('SingleGui.get', {
            application: props.appId,
            id: props.singleId
        }).then((resp: any) => {
            setSingleData(resp.result)
        })
    }

    React.useEffect(() => {
        loadData(props.singleId)
        return () => {
        }
    }, [props.singleId])

    const refresh = () => {
        loadData(props.singleId)
    }

    return {
        singleData,
        refresh,
    }
}