import React from "react";
import {Tag, Select, Tooltip} from "antd";
import {CloseOutlined, DragOutlined} from "@ant-design/icons"
import i18n from "i18next";
import {ReactSortable} from "react-sortablejs";


const ColumnContent = ({store}: any) => {
    let tempColumns: string[] = []
    return (
        <div>
            <Select
                mode={"multiple"}
                options={store.fullColumns}
                onChange={store.addColumns}
                key={"select-columns-" + store.id}
                maxTagCount={'responsive'}
                placeholder={i18n.t("column.select-columns")}
                value={store.columns}
                style={{width: '100%'}}
                maxTagPlaceholder={(omittedValues) => {
                    return <Tooltip title={omittedValues.map(({label}) => label).join(', ')}>
                        <span>+ {omittedValues.length} ...</span>
                    </Tooltip>
                }}
            />

            <ReactSortable style={{marginTop: 20, marginBottom: 20}} list={store.dataMap()}
                           setList={(newState: any[], sortable: any) => {
                               tempColumns = newState.map((el: any) => el.value)

                           }} onEnd={() => {
                store.addColumns(tempColumns)
            }}>
                {store.columns && store.dataMap().map((item: any, index: number) => (
                    <Tag key={`${store.id}__${item.value}`} color={"geekblue"} closeIcon={<CloseOutlined/>}
                         onClose={() => {
                             store.removeColumn(item.value)
                         }} style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontSize: 14,
                        marginBottom: 5,
                        cursor: "pointer"
                    }} icon={<DragOutlined/>}>
                        # {index + 1} - {item.label}
                    </Tag>
                ))}
            </ReactSortable>
        </div>
    )
}

export {ColumnContent}
