import React from "react";
import {App, Popconfirm, Button} from "antd";
import i18n from "i18next";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Request} from "kaiju.core";
import {Permissions} from "src/components/Permissions";


import type {ActionProps} from './Types'

export const DeleteAction: React.FC<ActionProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const anyKey = Object.keys(props.row)[0]
    const {notification} = App.useApp();

    const deleteCollection = () => {
        setLoading(true)
        Request("ContentGui.item.delete", {
            collection: props.collectionId,
            id: props.row[anyKey]._id
        }).then(({result}) => {
            if (result) {
                notification.success({
                    duration: 2,
                    message: "common.deleted",
                });
                props.store.fetchData()
            }
            setLoading(false)
        })
    }
    return (
        <Permissions root={props.collectionId} permissions={["delete"]}>
            <Popconfirm
                title={""}
                description={i18n.t("common.confirm-delete")}
                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                open={open}
                onConfirm={() => {
                    deleteCollection()
                }}
                cancelText={i18n.t("common.cancel")}
                okText={i18n.t("common.delete")}
                cancelButtonProps={{loading: loading}}
                okButtonProps={{loading: loading}}
                onCancel={() => {
                    setOpen(false)
                }}
            >
                <Button type="text" shape="circle" onClick={() => {
                    setOpen(true)
                }} icon={<DeleteOutlined/>}/>
            </Popconfirm>
        </Permissions>
    )
}