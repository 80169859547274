import React from "react";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";
import {Button, Space, Drawer, App} from "antd";

export const CreateUser: React.FC<{ open: boolean, onCLose: any }> = ({open, onCLose}) => {
    const {notification} = App.useApp()
    const [store] = useDynamicForm({
        autoLoad: true,
        loadRequest: {
            method: 'users.model',
            params: {}
        },
        saveDiff: false,
        loadExtraParams: {},
        saveRequest: {
            method: 'users.create',
            params: {}
        },
        successCallback: (response: any) => {
            notification.success({
                message: i18n.t("user.success-create"),
                duration: 2
            })
            onCLose()
        }
    });
    return (
        <Drawer
            size={"large"}
            title={i18n.t("user.create")}
            open={open}
            onClose={onCLose}
            extra={<>
                <Button onClick={() => {
                    store.submit()
                }} type="primary">
                    {i18n.t("common.add")}
                </Button>
            </>}
        >
            <Form store={store.formStore}/>
        </Drawer>
    )
}