import React from 'react'
import {Request} from 'kaiju.core';
import type {GroupBase} from "react-select";
import Creatable from "react-select/creatable";
import type {CreatableProps} from "react-select/creatable";
import {withAsyncPaginate} from "react-select-async-paginate";
import type {
    UseAsyncPaginateParams,
    ComponentProps
} from "react-select-async-paginate";


type AsyncPaginateCreatableProps<
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
    UseAsyncPaginateParams<OptionType, Group, Additional> &
    ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean = false
>(
    props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => React.ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(
    Creatable
) as AsyncPaginateCreatableType;

interface SelectInterface {
    closeMenuOnSelect?: boolean
    onChange: any
    isMulti?: boolean
    defaultValue?: any
    style?: any
    className?: any
    value?: any
    required?: boolean
    renderExtraFooter?: any
    renderValue?: any
    container?: any
    cleanable?: boolean | undefined
    placeholder?: string | undefined
    optionHandler: string | undefined
    data?: any[]
    params?: any
    searchable?: boolean | undefined
    disabled?: boolean
    Component?: React.FC
}


const useData = (optionHandler: any, params: any, value?: any) => {
    const [currentValues, setCurrentValues] = React.useState<any | undefined>()
    const [loading, setLoading] = React.useState(false);
    const [cacheUniq, setCacheUniq] = React.useState<number>(0);
    const [isCreating, setIsCreating] = React.useState(false)
    const loadDefault = () => {
        const valueIsArray = Array.isArray(value)
        let nCurrentVal: any = valueIsArray ? [] : undefined
        const setCVal = (val: any) => {
            if (val.options) {
                val.options.map((nVal: any) => {
                    setCVal(nVal)
                })
            }
            if (valueIsArray) {
                if (value.includes(val.value)) nCurrentVal.push(val)
            } else {
                if (val.value === value) nCurrentVal = val
            }
        }
        Request(optionHandler, {...params, id: value}).then((resp: any) => {
            resp.result.data.forEach((val: any) => {
                setCVal(val)
            })
            setCurrentValues(nCurrentVal)
        })
    }

    React.useEffect(() => {
        if (value && value.length > 0) {
            loadDefault()
        }
    }, [value])

    const loadOptions = async (search: any, _loadedOptions: any, meta: any) => {
        const response = await Request(optionHandler, {...params, q: search, ...meta,}).then((resp: any) => {
            return resp.result
        })

        return {
            options: response.data,
            hasMore: response.pagination.page < response.pagination.pages,
            additional: {
                page: meta.page + 1,
            },
        };
    }
    const increaseUniq = (uniq: number) => uniq + 1;

    const onCreate = (inputValue: string, onCreate: any) => {
        setIsCreating(true)
        Request("WaymorrGui.customers.create", {
            "name": inputValue,
            "collection": "89178ea3-a8ac-dd21-9e53-c80d422ae2b1"
        }).then(({result}: any) => {
            if (result) {
                setCacheUniq(increaseUniq(cacheUniq))
                onCreate({
                    value: result[0].id,
                    label: inputValue
                })
            }
            setIsCreating(false)
        })
    }

    return {
        loading,
        cacheUniq,
        onCreate,
        isCreating,
        setIsCreating,
        currentValues,
        setCurrentValues,
        loadOptions,
        setLoading,
    }
};


export const SelectAsync = (props: SelectInterface) => {
    const state = useData(props.optionHandler, props.params, props.value || props.defaultValue)

    return (
        <CreatableAsyncPaginate
            createOptionPosition={"first"}
            formatCreateLabel={(inputValue: string) => {
                return <b>СОЗДАТЬ КЛИЕНТА: "{inputValue}"</b>
            }}
            closeMenuOnSelect={props.closeMenuOnSelect}
            placeholder={props.placeholder}
            isMulti={props.isMulti}
            isClearable={true}
            isDisabled={props.disabled || state.isCreating}
            isSearchable={true}
            isLoading={state.loading || state.isCreating}
            onCreateOption={(inputValue: string) => {
                state.onCreate(inputValue, (value: { label: string, value: string }) => {
                    state.setCurrentValues(value)
                    props.onChange(value)
                })
            }}
            cacheUniqs={[state.cacheUniq]}
            required={props.required}
            value={state.currentValues}
            loadOptions={state.loadOptions}
            onChange={(value: any) => {
                state.setCurrentValues(value)
                props.onChange(value)
            }}
            additional={{
                page: 1,
            }}
        />

    );
};
