import * as React from "react";
import Plugin from "src/plugin"
import {Navigate} from "react-router-dom";
import lodash from "lodash";
import {SignIn, signInLoader} from "src/pages/Auth/SignIn"
import {signOutLoader} from "src/pages/Auth/SignOut"
import {RootPage, ErrorBoundary, rootLoader} from "src/pages/Root";
import {ApplicationsPage} from "src/pages/Applications";
import {applicationRootLoader, ApplicationRoot} from "src/pages/Application";
import {ContentRootPage} from "src/pages/Application/Content"
import {SystemSettingsPage} from "src/pages/SystemSettings"
import {UsersPage} from "src/pages/SystemSettings/Users"
import {RolesPage} from "src/pages/SystemSettings/Roles"
import {ContentTypeBuilderPage, ContentTypeBuilderWelcomePage} from "src/pages/Application/ContetntTypeBuilder";
import {CTBContentPage} from "src/pages/Application/ContetntTypeBuilder/Content"
import {CTBComponentPage} from "src/pages/Application/ContetntTypeBuilder/Component"
import {CTBMediaLibraryPage} from "src/pages/Application/ContetntTypeBuilder/MediaLibrary"
import {CTBSingleTypePage} from "src/pages/Application/ContetntTypeBuilder/SingleType"
import {MediaLibraryRootPage, MediaLibraryPage} from "src/pages/Application/MediaLibrary"
import {SinglePage, singleRootLoader} from "src/pages/Application/Content/Single"
import {Dashboard} from "src/pages/Application/Dashboard";
import {
    ContentListPage,
    ContentItemPage,
    CContentRootPage,
    contentRootLoader
} from "src/pages/Application/Content/Collection";


const routes = [
    {
        id: "root",
        path: "/",
        element: <RootPage/>,
        loader: rootLoader,
        errorElement: <ErrorBoundary/>,
        children: [
            {
                id: 'applicationList',
                index: true,
                element: <ApplicationsPage/>,
            },
            {
                id: 'application',
                element: <ApplicationRoot/>,
                path: "application/:appId",
                loader: applicationRootLoader,
                children: [
                    {
                        id: "applicationDashboard",
                        path: "dashboard",
                        element: <Dashboard/>,
                    },
                    {
                        id: "MediaRoot",
                        path: "media",
                        element: <MediaLibraryRootPage/>,
                        children: [
                            {
                                id: "MediaLibrary",
                                path: ":mediaId",
                                element: <MediaLibraryPage/>
                            }
                        ]

                    },
                    {
                        id: "ContentRoot",
                        path: "content",
                        element: <ContentRootPage/>,
                        children: [
                            {
                                id: "CContentRoot",
                                path: "content/:contentId",
                                element: <CContentRootPage/>,
                                loader: contentRootLoader,
                                children: [
                                    {
                                        id: "ContentList",
                                        index: true,
                                        element: <ContentListPage/>
                                    },
                                    {
                                        id: "ContentItem",
                                        path: ":itemId",
                                        element: <ContentItemPage/>
                                    },

                                ]
                            },
                            {
                                id: "SinglePage",
                                path: "single/:singleId",
                                element: <SinglePage/>
                            }
                        ]
                    },
                    {
                        id: "contentTypeBuilder",
                        path: "content-type-builder",
                        element: <ContentTypeBuilderPage/>,
                        children: [
                            {
                                id: "contentTypeBuilderWelcome",
                                index: true,
                                element: <ContentTypeBuilderWelcomePage/>

                            },
                            {
                                id: "ctbContent",
                                path: "content/:contentId",
                                element: <CTBContentPage/>,
                                loader: CTBContentPage.loader
                            },
                            {
                                id: "ctbComponent",
                                path: "component/:componentId",
                                element: <CTBComponentPage/>,
                                loader: CTBComponentPage.loader
                            },
                            {
                                id: "ctbMedia",
                                path: "media/:mediaId",
                                element: <CTBMediaLibraryPage/>,
                            },
                            {
                                id: "ctbSingle",
                                path: "single-type/:singleId",
                                element: <CTBSingleTypePage/>,
                            }
                        ]
                    },
                ]
            },
            {
                id: 'SystemSettings',
                path: "system-settings",
                element: <SystemSettingsPage/>,
                children: [
                    {
                        index: true,
                        element: <Navigate replace to="/system-settings/users"/>
                    },
                    {
                        id: "Users",
                        path: 'users',
                        element: <UsersPage/>
                    },
                    {
                        id: "Roles",
                        path: 'roles',
                        element: <RolesPage/>
                    },
                    {
                        id: "locales",
                        path: 'locales ',
                        element: <UsersPage/>
                    },

                ]
            }
        ]
    },

    {
        id: "login",
        path: "/login",
        element: <SignIn/>,
        loader: signInLoader,
    },
    {
        id: "logout",
        path: "/logout",
        loader: signOutLoader,
    },

];

const loadPlugins = (routes: any[]) => {
    routes.forEach((route: any) => {
        if (!route.children && !route.index) {
            route["children"] = []
        }
        if (route.id) {
            Plugin.invoke("routes." + route.id, route)
        }
        if (route.children) {
            loadPlugins(route.children)
        }
    })
}

const createRouteMap = (routes: any[], parentPath: string = "") => {
    let map: { [key: string]: string } = {};

    routes.forEach((element: any) => {
        const path = lodash.trimEnd(parentPath, "/") + "/" + lodash.trimStart(element.path || "", "/");

        if (element.id) {
            map[element.id] = path[0] !== "/" ? "/" + path : path;
        }
        if (element.children) {
            const childrenMap = createRouteMap(element.children, path);
            map = {
                ...map,
                ...childrenMap,
            };
        }
    });
    return map;
};

const ROUTE = createRouteMap(routes);

export {routes, ROUTE, loadPlugins};
