import React from 'react'
import i18next from "i18next";
import {Form, Request} from 'kaiju.core'
import {Button, Drawer, Space, notification} from 'antd';
import i18n from "i18next";


const ApplicationCreate: React.FC<{ open: boolean, onClose: any }> = ({open, onClose}) => {
    const [api, contextHolder] = notification.useNotification();
    const FormConf = {
        fields: [{
            id: "create",
            fields: [
                {
                    id: "id",
                    kind: "string",
                    required: true,
                    label: i18n.t("common.id"),
                    max: 36,
                    reg_exp: "^[a-zA-Z0-9][a-zA-Z0-9_-]+$",
                    reg_exp_text: i18n.t("application.error.id")
                },
                {
                    id: "code",
                    kind: "string",
                    required: true,
                    max: 15,
                    label: i18n.t("application.code")
                },
                {
                    id: "logo",
                    kind: "single_image",
                    label: i18n.t("application.logo"),
                    maxSize: 0.200
                },
                {
                    id: "has_category",
                    kind: "boolean",
                    default: false,
                    label: i18n.t("application.has_category"),
                    helper_text: i18n.t("application.helper.has_category"),
                },
                {
                    id: "has_assets",
                    kind: "boolean",
                    default: true,
                    label: i18n.t("application.has_assets"),
                    helper_text: i18n.t("application.helper.has_assets"),

                },
                {
                    id: "has_router",
                    kind: "boolean",
                    default: true,
                    label: i18n.t("application.has_router"),
                    helper_text: i18n.t("application.helper.has_router"),

                },

            ]
        }],
        disableGroups: true,
        onSubmit: (values: any, formikState: any) => {
            Request('ApplicationGui.create', values).then((data) => {
                const error = data.error;
                if (error) {
                    const code = error.code;
                    const message = i18n.t("application.error.duplicate");
                    if (code === 409) {
                        formikState.setFieldError("id", message)
                        api.error({
                            message: message,
                            placement: 'topRight',
                            duration: 2
                        })
                    }
                } else {
                    close()
                }
            })
        }
    };

    const formStore = Form.useFormStore(FormConf)
    const close = () => {
        onClose()
        formStore.reset({})
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title={i18next.t("application.create-new")}
                width={720}
                onClose={close}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={close}>{i18next.t("common.cancel")}</Button>
                        <Button onClick={formStore.submit} type="primary">
                            {i18next.t("common.create")}
                        </Button>
                    </Space>
                }
            >
                <Form store={formStore} key={"AuthForm"}/>
            </Drawer>
        </>
    )
}

export {ApplicationCreate}