import React from "react";
import {MediaLibraryFolder, useStore} from "src/components/MediaLibraryFolder";

export const MediaLibrary: React.FC<{
    mediaId: string,
    onChoice?: any,
    removeAssets?: boolean,
    defaultCheckedValues?: string[]
}> = ({mediaId, onChoice, defaultCheckedValues, removeAssets}) => {
    const store = useStore({
        id: mediaId,
        onChoice: onChoice,
        defaultCheckedValues: defaultCheckedValues,
        uploadProps: {
            method: "PUT",
            action: "/media/" + mediaId + "/asset/load"
        },
        metaData: {
            entity: mediaId
        },
        removeAssets: removeAssets,
        requests: {
            loadAssets: {
                method: "MediaLibraryGui.asset.grid",
                params: {
                    media: mediaId
                }
            },
            createAssets: {
                method: "MediaLibraryGui.asset.create",
                params: {
                    media: mediaId
                }
            },
            deleteAssets: {
                method: "MediaLibraryGui.asset.delete",
                params: {
                    media: mediaId
                }
            },
            loadFolders: {
                method: "MediaLibraryGui.folder.info",
                params: {
                    media: mediaId
                }
            },
            deleteFolder: {
                method: "MediaLibraryGui.folder.delete",
                params: {
                    media: mediaId
                }
            },
            createFolder: {
                method: "MediaLibraryGui.folder.create",
                params: {
                    media: mediaId
                }
            }
        }
    })

    return <MediaLibraryFolder title={"Home"} store={store}/>
}
