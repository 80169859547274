import {redirect} from "react-router-dom";
import {LoadI18n} from 'src/loaders/i18n'
import user from 'src/components/User'

const rootLoader = async (props: any) => {
    await user.setData(()=> {
        return redirect('/login')
    })
    if (!user.isAuth){
        return redirect('/login')
    }
    await LoadI18n();
    return {}
};

export {rootLoader};