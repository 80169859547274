import React from "react";
import {App, Button} from "antd";
import i18n from "i18next";
import {Form, useDynamicForm} from "kaiju.core";


export const Settings: React.FC<{ componentId: string, appId: string }> = ({componentId, appId}) => {
    const {notification} = App.useApp();

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        saveDiff: true,
        loadRequest: {
            method: "ContentTypeBuilder.component.settings.get",
            params: {
                component: componentId,
                application: appId
            }
        },
        loadExtraParams: {
            application: appId
        },
        saveRequest: {
            method: "ContentTypeBuilder.component.settings.update",
            params: {
                component: componentId,
                application: appId
            }
        },
        successCallback: (props) => {
            notification.success({
                duration: 1,
                message: i18n.t("common.updated"),
            });
        }
    })
    return (
        <div style={{maxWidth: 500}}>
            <Form store={store.formStore}>
                <Button onClick={() => {
                    store.submit()
                }} type={"primary"}>{i18n.t("common.save")}</Button>
            </Form>
        </div>
    )

}