import React from "react";
import {WarehouseBasePage} from "./Base";


export const WarehouseRepairPage: React.FC = () => {
    const status = "Сервисный центр"

    const preFilters: any[] = []
    const columns = [
        "repair_date_start",
        "repair_status",
        "manufacturer_model",
        "manufacturer",
        "model",
        "hashrate",
        "id",
        "customer",
        "repair_date_end"
    ]
    const fields = [
        "status",
        "repair_date_start",
        "repair_date_end",
        "repair_status",
        "customer",
        "manufacturer_model",
        "manufacturer",
        "model",
        "hashrate",
        "repair_comment"
    ]
    return <WarehouseBasePage
        method={"WaymorrGui.collection.item.grid.service_center"}
        prefix={"repair"}
        columns={columns}
        preFilters={preFilters}
        title={"Сервисный центр"}
        createStatus={status}
        fields={fields}/>
}