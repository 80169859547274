import React from "react";


export const FolderIcon = (props: any) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 200 200"
            {...props}
        >
            <path
                fill="#FCD354"
                d="M9.57 23.064c.86-.352 1.836-.743 2.149-.86.312-.078 11.992-.195 25.976-.195 21.446-.04 25.742.039 27.617.547 1.25.313 3.32 1.094 4.61 1.759 1.289.625 3.516 2.267 4.922 3.595 1.445 1.369 2.734 2.815 2.93 3.245.195.43 3.788 5.08 8.046 10.357 5.938 7.348 8.125 9.81 11.25 11.53l102.93.196-.195 113.543-1.289 2.618c-.664 1.486-2.032 3.44-3.008 4.417-.977.977-2.93 2.345-7.031 4.299l-88.008.079c-86.602.117-88.008.117-90-.665-1.133-.391-2.93-1.329-3.985-2.032-1.054-.704-2.578-2.189-3.398-3.244-.781-1.095-1.797-2.971-3.086-6.449V34.477l.82-2.228c.47-1.25 1.407-3.049 2.11-4.026.703-.977 2.11-2.384 3.164-3.166 1.054-.742 2.617-1.641 3.476-1.993z"
            ></path>
            <path
                fill="#FFB125"
                d="M95.313 52.066c-1.368-.782-3.555-3.205-9.688-10.866l-7.93-9.85 110.782.196 2.656 1.25c1.445.704 3.398 2.072 4.375 3.05.976.977 2.187 2.618 2.734 3.713.547 1.055.977 2.306.977 2.736 0 .43.156.86.39.977.235.117.391 2.227.391 9.966l-102.93-.195-1.757-.977z"
            ></path>
        </svg>
    )
}