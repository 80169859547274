import React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {Tabs, Card, Typography} from "antd";
import i18n from "i18next";
import {Permissions} from "./Permissions";
import {Settings} from "./Settings"
import {HeaderComponent} from "src/components/Header";

const CTBMediaLibraryPage: React.FC = () => {
    const {breadcrumbs, builderStore} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const {mediaId} = useParams<{ mediaId: string, appId: string }>()
    const current = builderStore.data.media.filter((el: any) => (el._id === (mediaId ? mediaId : undefined)))[0]

    const items = [
        {
            key: 'settings',
            label: i18n.t("common.settings"),
            children: <Settings mediaId={mediaId} key={'settings-' + mediaId}/>,
        },
        {
            key: 'permissions',
            label: i18n.t("common.permissions"),
            children: <Permissions mediaId={mediaId}/>,
        },

    ];

    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("content-builder.label"),
            },
            {
                title: i18n.t("media.label"),
            },
            {
                title: current ? current.label : "[...]",
            },
        ]}/>

        <Typography.Title level={1} copyable={{text: current?.id, tooltips: current?.id}} style={{marginTop: 0}}>
            {current ? current.label : "[...]"}
        </Typography.Title>

        <p style={{marginTop: "1rem", marginBottom: "2rem", color: "rgb(140 140 140)"}}>{i18n.t("media.moto")}</p>
        <Card>
            <Tabs defaultActiveKey="settings" items={items}/>
        </Card>
    </>
}

export {CTBMediaLibraryPage}
