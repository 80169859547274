import React from "react";
import {Select, Tooltip, Divider, Alert, Flex, Button, App} from "antd";
import {Request, useDynamicForm, Form} from "kaiju.core";
import type {Props} from "./Base"
import i18n from "i18next";

interface FormProps {
    collectionId: string
    currentStep: number
    appId: string
    attributes: string[]
    setSubmit: any
    selectedState: any
    isDone: () => void
    setProcess: (state: boolean) => void
    toEdit: () => void
}

const FormComponent: React.FC<FormProps> = (props) => {
    const {notification} = App.useApp()
    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: false,
        loadRequest: {
            method: "CollectionGui.action.update.form",
            params: {
                application: props.appId,
                collection: props.collectionId,
                attributes: props.attributes
            }
        },
        loadExtraParams: {
            application: props.appId
        },
        saveRequest: {
            method: "CollectionGui.action.update",
            params: {
                application: props.appId,
                collection: props.collectionId,
                selection: {id: props.selectedState.id}
            }
        },
        errorCallback: () => {
            props.setProcess(false)
        },
        successCallback: (_) => {
            notification.success({
                duration: 2,
                message: i18n.t("common.updated"),
            });
            props.isDone()
        }
    })
    props.setSubmit(() => {
        store.formStore.formik.validateForm()
        if (props.currentStep === 2 && store.formStore.formik.isValid) {
            props.setProcess(true)
            store.submit()
        }
    })
    React.useEffect(() => {
        store.formStore.formik.validateForm()
        if (props.currentStep === 2 && !store.formStore.formik.isValid) {
            notification.error({
                duration: 2,
                message: i18n.t("bulk-action.error.invalid-form"),
            });
            props.toEdit()
        }
    }, [props, notification, store])

    return <>
        <Form store={store.formStore}/>
    </>
}

export const BulkUpdate: React.FC<Props> = (props) => {
    let submit: any = undefined
    const [attributes, setAttributes] = React.useState<any[]>([])
    const [currentAttributes, setCurrentAttributes] = React.useState<any[]>([])

    const {notification} = App.useApp()

    const onSubmit = () => {
        if (submit) {
            submit()
        }
    }

    const loadFull = (collectionId: string) => {
        Request(
            "CollectionGui.action.update.attributes",
            {collection: collectionId}
        ).then(({result}: any) => {
            setAttributes(result.data)
        })
    }
    React.useEffect(() => {
        loadFull(props.collectionId)
    }, [props.collectionId])

    React.useEffect(() => {
        if (currentAttributes.length === 0 && props.currentStep === 2) {
            notification.warning({
                message: i18n.t('bulk-action.select-for-update'),
                placement: 'topRight',
            });
            props.toEdit()
        }
    }, [props, notification, currentAttributes])

    return <>

        {props.currentStep === 2 &&
            <>
                <Alert
                    message={<b>{i18n.t("bulk-action.confirm")}</b>}
                    description={i18n.t("bulk-action.confirm-info")}
                    type="warning"
                    showIcon
                />
                <Flex justify={"end"} style={{paddingTop: 20}}>
                    <Button
                        loading={props.inProcess}
                        type={"primary"}
                        onClick={onSubmit}
                    >
                        {i18n.t("common.confirm")}
                    </Button>
                </Flex>
            </>
        }

        <div className={props.currentStep === 2 ? "is-blocked" : ""}>
            <div className={props.currentStep === 2 ? "is-disabled" : ""}>
                <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{fontSize: 20, fontWeight: 500}}>{i18n.t("bulk-action.select-for-update")}</Divider>
                <Select
                    mode={"multiple"}
                    options={attributes}
                    onChange={(value, option) => {
                        setCurrentAttributes(value)
                    }}
                    maxTagCount={'responsive'}
                    placeholder={i18n.t("bulk-action.select-for-update")}
                    style={{width: '100%', maxWidth: 1000}}
                    maxTagPlaceholder={(omittedValues) => {
                        return <Tooltip title={omittedValues.map(({label}) => label).join(', ')}>
                            <span>+ {omittedValues.length} ...</span>
                        </Tooltip>
                    }}
                />
                {currentAttributes.length > 0 && <>
                    <Divider orientation="left"
                             orientationMargin="0"
                             style={{
                                 fontSize: 20,
                                 fontWeight: 500,
                                 marginTop: 20
                             }}>{i18n.t("bulk-action.edit-attribute")}</Divider>
                    <FormComponent
                        key={"form-" + JSON.stringify(currentAttributes)}
                        {...props}
                        setSubmit={(submitFunc: any) => {
                            submit = submitFunc
                        }}
                        attributes={currentAttributes}
                    />
                </>
                }
            </div>
        </div>
    </>
}