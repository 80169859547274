import React from "react";
import {generatePath, useLoaderData, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Card, Typography, Tabs, Button, App, Popconfirm, Flex} from "antd";
import i18n from "i18next";
import {ComponentAttributes} from "./Attributes";
import {Settings} from "./Settings";
import {HeaderComponent} from "src/components/Header";
import {Request} from "kaiju.core";
import {ROUTE} from "src/routing";

interface Params {
    componentId: string
    appId: string
}

const CTBComponentPage: React.FC = () => {
    const {breadcrumbs, builderStore} = useOutletContext<{ breadcrumbs: any, builderStore: any }>()
    const {componentId, appId} = useParams<keyof Params>() as Params
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate();
    const {notification} = App.useApp()

    const componentData: any = useLoaderData()

    const items: any[] = [
        {
            key: 'attributes',
            label: i18n.t("attribute.label"),
            children: <ComponentAttributes key={"component-attrs-" + componentId} componentId={componentId}
                                           appId={appId}/>,
        },
        {
            key: 'settings',
            label: i18n.t("common.settings"),
            children: <Settings key={"component-setting-" + componentId} componentId={componentId} appId={appId}/>,
        },

    ]

    const deleteComponent = () => {
        setLoading(true)
        Request("ContentTypeBuilder.component.delete", {application: appId, id: componentId}).then(({result}) => {
            if (result) {
                notification.success({
                    message: i18n.t("common.deleted"),
                    placement: 'topRight',
                });
                builderStore.refresh()
                navigate(generatePath(ROUTE.contentTypeBuilder, {appId: appId}))
            }
            setLoading(false)
        })
    }

    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs,
            {
                title: i18n.t("content-builder.label"),
            },
            {
                title: i18n.t("component.label"),
            },
            {
                title: componentData.label ? componentData.label : "[...]",
            },
        ]}/>
        <Flex justify={"space-between"}>
            <Typography.Title level={1} copyable={{text: componentData.meta?.id, tooltips: componentData.meta?.id}}
                              style={{marginTop: 0}}>
                {componentData ? componentData.label : "[...]"}
            </Typography.Title>
            <Popconfirm
                title={componentData.label}
                description={i18n.t("component.delete-confirm")}
                onConfirm={() => {
                    deleteComponent()
                }}
                cancelButtonProps={{loading: loading}}
                okButtonProps={{loading: loading}}
                okText={i18n.t("common.delete")}
                cancelText={i18n.t("common.cancel")}
            >
                <Button danger>{i18n.t("common.delete")}</Button></Popconfirm>
        </Flex>

        <p style={{marginTop: "1rem", marginBottom: "2rem", color: "rgb(140 140 140)"}}>{i18n.t("component.moto")}</p>
        <Card>
            <Tabs defaultActiveKey="1" items={items}/>
        </Card>
    </>
}

export {CTBComponentPage}
