import React from "react"
import {CloseIcon} from "@kaiju.ui2/icons";

const ConditionFilters = ({store}: any) => {
    return (
        <>
            {store.data.map((row: any) => {
                if (Object.keys(store.filtersKind).includes(row.kind)) {
                    const Comp = store.filtersKind[row.kind]
                    return <div style={{marginBottom: "1rem", display: "flex"}} key={"filter-item-" + row.key}>
                        <div className={"rs-list-bordered p-2 pb-3"} style={{width: "100%"}}>
                            <Comp key={"filter-" + row.key} store={store} row={row}/>
                        </div>
                        <div style={{paddingLeft: 10, cursor: "pointer"}}><CloseIcon height={15} onClick={() => {
                            store.remove(row.key)
                        }}/></div>
                    </div>
                }
                return <></>
            })}
        </>
    )
}

export {ConditionFilters}
