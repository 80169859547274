import React from "react";
import {Form as KForm} from "kaiju.core"
import {Empty, Flex, Form, Button, Collapse, Dropdown, Modal, Input} from "antd"
import * as Yup from 'yup';
import {Divider} from "antd";
import {FieldArray, getIn} from "formik";
import i18n from "i18next";
import {ReactSortable} from "react-sortablejs";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import MD5 from "crypto-js/md5";


export class ComponentListFieldStore extends KForm.Fields.FieldStore {
    validator: any;
    nested: { [p: string]: any } = {}
    fields: any[] = []

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.nested = props.nested_forms

        // this.value = typeof props.value === 'object' && !Array.isArray(props.value) ? props.value : {};
        // this.validator = Yup.object().nullable();
        this.initNested(props.fields);
        // this.makeValidator(props);
    }

    initNested(fields: any[]) {
        const _validators: { [key: string]: any } = {};
        fields.map((field: any) => {
            const fStore = this.form.kindMap[field.kind] ? this.form.kindMap[field.kind] : undefined;
            if (fStore) {
                const _store = new fStore({
                    form: this.form,
                    ...field,
                    loadExtraParams: this.loadExtraParams
                });
                this.fields.push(_store);
                _validators[field.id] = _store.validator;

                // if (!(field.id in this.value)) {
                //     this.value[field.id] = _store.value
                // }
            }
        });
        // this.validator = this.validator.shape(
        //     _validators
        // )
    }

    emptyValue = () => {
        const _new: { [p: string]: any } = {
            __uuid: MD5(new Date().toString()).toString()
        }
        this.fields.forEach((store: any) => {
            _new[store.id] = null
        })
        return _new
    }


    Component: React.FC = (props: any) => {
        const [editLabel, setEditLabel] = React.useState<number | undefined>()
        const [newLabel, setNewLabel] = React.useState<string>("")

        const errors = props.errors || props.formik.errors;
        const _id = this._id(props)
        if (!getIn(props.formik.values, _id)) {
            props.formik.setFieldValue(_id, [])
        }
        const getLabel = (index: number) => {
            const _val = getIn(this.props.form.formik.values, this._id(props) + "." + index)
            return _val.__label ? _val.__label : this.label + " # " + index
        }
        const setLabel = (index: number, label: string) => {
            this.props.form.formik.setFieldValue(this._id(props) + "." + index + ".__label", label)
        }

        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Divider style={{marginTop: 5, marginBottom: 20}}/>
                    <>
                        {(!getIn(props.formik.values, _id) || getIn(props.formik.values, _id).length === 0) &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }
                        <FieldArray
                            name={this.id}
                            render={(arrayHelpers: any) => (
                                <>
                                    <ReactSortable list={getIn(arrayHelpers.form.values, this._id(props)) || []}
                                                   setList={(newState, sortable, store) => {
                                                       if (sortable && store.dragging) {
                                                           props.formik.setFieldValue(_id, newState)
                                                       }
                                                   }}>
                                        {Array.isArray(getIn(arrayHelpers.form.values, this._id(props))) && getIn(arrayHelpers.form.values, this._id(props)).map((values: any, index: number) => {
                                            const cId = _id + "." + index

                                            return (
                                                <Collapse
                                                    style={{marginBottom: 10}}
                                                    items={[{
                                                        key: "component-list-item" + cId + index,
                                                        label: getLabel(index),
                                                        extra: <Dropdown menu={{
                                                            items: [
                                                                {
                                                                    icon: <EditOutlined/>,
                                                                    key: "edit",
                                                                    label: <a onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                        setEditLabel(index)
                                                                        return false
                                                                    }}>{i18n.t("common.edit")}</a>
                                                                },
                                                                {
                                                                    danger: true,
                                                                    key: "delete",
                                                                    icon: <DeleteOutlined/>,
                                                                    label: <a onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                        const cValues = getIn(props.formik.values, _id)
                                                                        cValues.splice(index, 1)
                                                                        props.formik.setFieldValue(_id + cValues)
                                                                        return false
                                                                    }}>{i18n.t("common.delete")}</a>
                                                                }
                                                            ]
                                                        }}>
                                                            <EllipsisOutlined
                                                                onClick={(e) => e.stopPropagation()}
                                                            />
                                                        </Dropdown>,
                                                        children: (
                                                            <div className={"c-list-item"}>
                                                                {this.fields.map((fieldStore: any) => {
                                                                    return <fieldStore.Component
                                                                        key={`${props.index}-form-field-nested-${fieldStore._id()}`}
                                                                        formik={props.formik}
                                                                        parentId={cId}
                                                                        values={values}
                                                                        errors={errors}
                                                                    />
                                                                })}
                                                            </div>
                                                        )
                                                    }]}/>
                                            )
                                        })}
                                    </ReactSortable>
                                    <Divider/>
                                    <Flex justify={"center"}>
                                        <Button type={"dashed"}
                                                style={{width: "100%"}}
                                                icon={<PlusOutlined/>}
                                                onClick={() => {
                                                    const _newVal = this.emptyValue()
                                                    const cValues = getIn(props.formik.values, _id)
                                                    props.formik.setFieldValue(_id + "." + cValues.length, _newVal)
                                                }}>{i18n.t("common.add")}</Button>
                                    </Flex>
                                </>
                            )}/>


                    </>
                </Form.Item>
                <Modal
                    key={"zone-modal-" + editLabel}
                    title={i18n.t("common.edit")}
                    open={editLabel !== undefined}
                    onOk={() => {
                        if (editLabel !== undefined) setLabel(editLabel, newLabel)
                        setEditLabel(undefined)
                    }}
                    onCancel={() => setEditLabel(undefined)}
                >
                    {editLabel !== undefined &&
                        <Input
                            onChange={(e) => setNewLabel(e.target.value)}
                            defaultValue={getLabel(editLabel)}
                        />
                    }
                </Modal>
            </>
        )
    }

}

