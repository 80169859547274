import React from 'react'
import {Request} from "kaiju.core";

interface DataType {
    contents: any[]
    components: any[]
    media: any[]
    single: any[]
}

const useStore = ({appId}: any) => {
    const [data, setData] = React.useState<DataType>({
        contents: [],
        components: [],
        single: [],
        media: [],
    })
    const [open, setOpen] = React.useState<boolean>(true)

    const fetchContent = async () => {
        return await Request('SettingsGuiService.get', {application: appId}).then((resp: any) => {
            return resp.result.content || []
        })
    }

    const fetchSingle = async () => {
        return await Request('ContentTypeBuilder.single.list', {application: appId}).then((resp: any) => {
            return resp.result || []
        })
    }

    const fetchComponent = async () => {
        return await Request('ContentTypeBuilder.component.tree', {application: appId}).then((resp: any) => {
            resp.result.forEach((node: any) => {
                node["selectable"] = !node.meta?.is_group
            })
            return resp.result || []
        })
    }

    const fetchMedia = async () => {
        return await Request('ContentTypeBuilder.medialibrary.list', {application: appId}).then((resp: any) => {
            return resp.result || []
        })
    }

    const loadAll = async () => {
        const contents = await fetchContent()
        const components = await fetchComponent()
        const media = await fetchMedia()
        const single = await fetchSingle()
        setData({
            contents: contents,
            components: components,
            single: single,
            media: media,
        })
    }

    React.useEffect(() => {
        loadAll().then()
    }, [])

    return {
        data,
        open,
        setOpen,
        onClose: () => {
            setOpen(false)
        },
        refresh: () => {
            loadAll().then()
        },
        toggle: () => {
            setOpen(!open)
        }
    }
}

export {useStore}