import React from "react";
import {Button, Flex, Modal, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons"
import {Form} from "kaiju.core";
import i18n from "i18next";
import type {StoreInterface} from "./store";

interface Props {
    store: StoreInterface
}


export const CreateFolder = ({store}: Props) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const onCLose = () => {
        formStore.reset({label: ""})
        setOpen(false)
    }

    const formStore = Form.useFormStore({
        fields: [{
            id: "media-folder-create",
            fields: [
                {
                    id: "label",
                    kind: "string",
                    required: true,
                    placeholder: i18n.t("media.folder-label"),
                    label: i18n.t("media.folder-label"),
                    max: 40
                },
            ]
        }],
        onSubmit: ({label}: any) => {
            store.createFolder(label, () => onCLose())
        }
    })

    return <>
        <Flex style={{cursor: "pointer"}} vertical className={"folder-add"} onClick={() => {
            setOpen(true)
        }}>
            <div className={"text"}>
                <button type="button">
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>{i18n.t("media.folder-add")}</div>
                </button>
            </div>
        </Flex>

        <Modal title={i18n.t("media.folder-add")} onCancel={() => setOpen(false)} footer=""
               open={open}>
            <Form store={formStore} style={{paddingTop: 20}}>
                <Space>
                    <Button onClick={onCLose}>{i18n.t("common.cancel")}</Button>
                    <Button type={"primary"} onClick={formStore.submit}>{i18n.t("common.add")}</Button>
                </Space>
            </Form>
        </Modal>
    </>
}