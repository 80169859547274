import React from 'react'
import {Button, Card, Flex, Input} from "antd";
import i18n from "i18next";
import {Table} from "src/components/Table";
import {Cells} from "src/pages/Application/Content/Collection/Grid/Cells";
import {useOutletContext} from "react-router-dom";
import {HeaderComponent} from "src/components/Header";
import {SearchOutlined} from "@ant-design/icons";
import {CreateRole} from "./Create";


export const RolesPage: React.FC<{}> = () => {
    const [openCreate, setOpenCreate] = React.useState<boolean>(false)
    const {breadcrumbs,} = useOutletContext<{
        breadcrumbs: any[],
    }>()

    const store = Table.useStore("role", {
        request: {
            method: "Role.grid",
            params: {}
        },
        onClick: (row: any) => {

        }

    })

    return (
        <>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: i18n.t("role.label"),
                },
            ]}/>
            <Flex justify={"space-between"} align={"center"}>
                <h1 style={{marginBottom: 0, marginTop: 0}}>{i18n.t("role.label")}</h1>
                <Button type={"primary"} onClick={() => {
                    setOpenCreate(true)
                }}>{i18n.t("role.add")}</Button>
            </Flex>
            <div style={{padding: "20px 0"}}>
                <Input prefix={<SearchOutlined/>} style={{width: "300px"}} onChange={(event: any) => {
                    store.querySearch(event.currentTarget.value)
                }} placeholder={i18n.t("common.search")}/>
            </div>
            <Card>
                <Table store={store} cells={Cells}/>
            </Card>
            <CreateRole open={openCreate} setOpen={setOpenCreate}/>
        </>

    )
}
