import {StringFilter} from "./String";
import {IntegerFilter} from "./Integer";
import {BooleanFilter} from "./Boolean";
import {CollectionSelectFilter} from "./CollectionSelect";
import {SelectFilter} from "./Select";
import {DateFilter} from "./Date";

const filters: {[key: string]: any} = {
    string: StringFilter,
    identifier: StringFilter,
    integer: IntegerFilter,
    boolean: BooleanFilter,
    collection_select: CollectionSelectFilter,
    collection_multiselect: CollectionSelectFilter,
    date: DateFilter,
    select: SelectFilter,
    multiselect: SelectFilter,
    text: StringFilter
}
export default filters;
