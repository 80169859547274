import React from 'react'
import i18next from "i18next";
import {Form, Request, useDynamicForm} from 'kaiju.core'
import {Button, Drawer, Space, notification} from 'antd';
import i18n from "i18next";
import {useParams} from "react-router-dom";


export const CreateTask: React.FC<{ open: boolean, onClose: any, onCreate: Function }> = ({
                                                                                              open,
                                                                                              onClose,
                                                                                              onCreate
                                                                                          }) => {
    const {appId} = useParams();

    const close = () => {
        onClose()
    }

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        loadRequest: {
            method: "TaskTracker.model",
            params: {
            }
        },
        loadExtraParams: {
        },
        saveRequest: {
            method: "TaskTracker.create",
            params: {
            }
        },
        successCallback: (props) => {
            onCreate()
            console.log("SUCCES SAVE")
        }
    })

    return (
        <>
            <Drawer
                title="Создать задачу"
                width={720}
                onClose={close}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={close}>{i18next.t("common.cancel")}</Button>
                        <Button onClick={()=>{store.submit()}} type="primary">
                            {i18next.t("common.create")}
                        </Button>
                    </Space>
                }
            >
                <Form store={store.formStore} key={"content-create"}/>
            </Drawer>
        </>
    )
}

