import React from 'react'
import {BaseFilter} from "./Base";
import {getConditions} from '../utils'


const BooleanFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter row={row} store={store} availableConditions={availableConditions}/>
    </>
}

export {BooleanFilter}
