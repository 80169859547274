import React from 'react'
import {Layout} from "antd";
import {useOutletContext, useParams, Outlet, generatePath, useNavigate, useMatches} from "react-router-dom";
import {BaseMenu, useStore} from "src/components/BaseMenu";
import {Permission403} from 'src/components/Permissions'
import {ROUTE} from "src/routing";
import i18n from "i18next";


const MediaLibraryRootPage: React.FC = () => {
    const {breadcrumbs} = useOutletContext<any>();
    const {appId, mediaId} = useParams<{ appId: string, mediaId: string | undefined }>()
    const navigate = useNavigate()
    const matches = useMatches()
    const onClick = (el: any) => {
        navigate(generatePath(ROUTE.MediaLibrary, {appId: appId, mediaId: el._id}))

    }
    const isActive = (el: any) => (
        matches.some((y: any) => (y.pathname === generatePath(ROUTE.MediaLibrary, {appId: appId, mediaId: el._id})))
    )
    const store = useStore({id: appId, method: "MediaLibraryGui.list", params: {application: appId}})

    return (<>
        <Layout
            style={{borderRadius: "5px"}} className={"h-100"}
        >
            <BaseMenu store={store} onClick={onClick} isActive={isActive} title={i18n.t("assets.label")}/>
            <Layout.Content className={"settings-content"}>
                {mediaId &&
                    <Permission403 root={mediaId} permissions={["read"]} key={"media-perm" + mediaId}>
                        <Outlet context={{breadcrumbs, builderStore: store}}/>
                    </Permission403>
                }
            </Layout.Content>
        </Layout>


    </>)
}

export {MediaLibraryRootPage}