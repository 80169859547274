import React from 'react'
import i18next from "i18next";
import {Form, useDynamicForm} from 'kaiju.core'
import {Button, Drawer, Space, App} from 'antd';

export const EditTask: React.FC<{ taskId: number, onClose: any, onEdit: Function }> = ({
                                                                                           taskId,
                                                                                           onClose,
                                                                                           onEdit
                                                                                       }) => {
    const {notification} = App.useApp()

    const close = () => {
        onClose()
    }

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        saveDiff: false,
        loadRequest: {
            method: "TaskTracker.get",
            params: {
                id: taskId
            }
        },
        loadExtraParams: {},
        saveRequest: {
            method: "TaskTracker.update",
            params: {
                id: taskId
            }
        },
        successCallback: (props) => {
            onEdit()
            notification.success({
                duration: 1,
                message: "Задача обновлена",
            });
        }
    })

    return (
        <>
            <Drawer
                title="Редактирование задачи"
                width={720}
                onClose={close}
                open={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={close}>Закрыть</Button>
                        <Button onClick={() => {
                            store.submit()
                        }} type="primary">
                            Сохранить
                        </Button>
                    </Space>
                }
            >
                <Form store={store.formStore} key={"content-create"}/>
            </Drawer>
        </>
    )
}

