import React from 'react';
import {ConfigProvider, App} from 'antd';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {routes, loadPlugins} from "src/routing";
import Plugin from "src/plugin";
import {setMapping} from "src/components/FormCustom/Fields";
import packages from "packages"

setMapping()

const makePlugins = () => {
    packages.forEach((plugin: any) => {
        console.log("plugin:", plugin)
        Plugin.register(plugin)
    })
}

const Application = () => {
    makePlugins()
    loadPlugins(routes)
    Plugin.invoke("application.init")

    const router = createBrowserRouter(routes);
    return <ConfigProvider>
        <App>
            <RouterProvider router={router}/>
        </App>
    </ConfigProvider>
};
export default Application;