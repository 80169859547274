import FiltersType from "./types"
import React from "react";

//
// const StructureData = [
//     {
//         id: "key-1",
//         condition: "in_list",
//         value: ["abc", "cde", "edf"],
//         kind: "string",
//         attribute: "label",
//         label: "Current Label"
//     },
//     {
//         id: "key-2",
//         condition: "contains",
//         value: ".com",
//         kind: "string",
//         attribute: "label",
//         label: "Current Label"
//     },
//     {
//         id: "567576-2",
//         condition: "equal",
//         value: 5,
//         kind: "integer",
//         attribute: "rating",
//         label: "rating"
//     },
//     {
//         id: "23434-2",
//         condition: "in_list",
//         value: [],
//         kind: "status",
//         attribute: "status",
//         label: "status"
//     },
//
// ]

//
// class ConditionFiltersStore {
//     data: any[]
//     filtersKind: { [key: string]: any } = FiltersType
//     props: any
//
//     constructor(props: any) {
//         this.props = props;
//         makeObservable(this, {
//             data: observable.shallow,
//             changeCondition: action.bound,
//             setValue: action.bound,
//             remove: action.bound,
//             addFilter: action.bound
//         });
//         this.data = props.data || []
//     }
//
//     changeCondition(id: any, value: any) {
//         this.data = this.data.map((el: any) => {
//             if (el.id === id) {
//                 el.condition = value
//             }
//             return el
//         })
//         if (["all", "empty", "not_empty"].includes(value)) {
//             this.setValue(id, undefined)
//         }
//     }
//
//     addFilter(filter: any) {
//         this.data.push(filter)
//     }
//
//     setValue(id: any, value: any) {
//         this.data = this.data.map((el: any) => {
//             if (el.id === id) {
//                 el.value = value
//             }
//             return el
//         })
//     }
//
//     remove(id: any) {
//         this.data = this.data.filter((el: any) => {
//             if (el.id !== id) {
//                 return el
//             }
//         })
//     }
//
//     getData() {
//         return toJS(this.data)
//     }
// }

// export {ConditionFiltersStore}


interface FilterProps {
    key?: string,
    id: string,
    condition: string
    conditions?: string[]
    nested_in?: string
    value: any,
    kind: string,
    label: string
}

interface StoreProps {
    data?: FilterProps[]
}

interface StoreInterface {
    data: FilterProps[]
    remove: (id: string) => void
    clear: () => void
    activeFilters: () => any[]
    addFilter: (filter: FilterProps) => void
    setValue: (id: string, value: any) => void
    filtersKind: { [key: string]: any }
    changeCondition: (id: string, value: any) => void
}

const useFilterStore = (props: StoreProps): StoreInterface => {
    const filtersKind: { [key: string]: any } = FiltersType

    const [data, setData] = React.useState<FilterProps[]>(props.data || [])

    const remove = (key: string) => {
        setData(data.filter((el: any) => el.key !== key))
    }
    const clear = () => {
        setData([])
    }
    const addFilter = (filter: FilterProps) => {
        setData([...data, {...filter, condition: filter.conditions?.[0] || "all"}])
    }

    const setValue = (key: string, value: any) => {
        const newData = data.map((el: FilterProps) => {
            if (el.key === key) {
                el.value = value
            }
            return el
        })
        setData(newData)
    }
    const activeFilters = () => {
        return data.filter((el: FilterProps) => {
            return el.condition && el.condition.length > 0
        }).map((el: FilterProps) => ({
            id: el.id,
            value: el.value,
            condition: el.condition,
            kind: el.kind,
            key: el.key,
            nested_in: el.nested_in,
        }))
    }

    const changeCondition = (key: string, value: any) => {
        const newData = data.map((el: FilterProps) => {
            if (el.key === key) {
                el.condition = value
            }
            if (["all", "empty", "not_empty"].includes(value)) {
                el.value = undefined
            }
            return el
        })
        setData(newData)
    }

    return {
        activeFilters,
        data,
        clear,
        filtersKind,
        addFilter,
        changeCondition,
        setValue,
        remove

    }
}

export type {FilterProps, StoreProps, StoreInterface}
export {useFilterStore}

