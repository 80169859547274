import React from 'react'
import {Table, Checkbox, Divider, Button, Flex, Select} from "antd";
import i18n from "i18next";
import {useStore} from "./store";

const GetColumns = (store: any) => {
    const columns: any[] = [
        {
            title: i18n.t("permission.role"),
            dataIndex: 'role',
            key: 'role',
        }
    ]
    store.columns.forEach((val: string) => {
        columns.push({
            title: i18n.t("permission." + val),
            key: val,
            dataIndex: val,
            render: (_: any, row: any) => {
                return <Checkbox defaultChecked={row[val] || false} onChange={(value) => {
                    store.setPermission(row.role_id, val, value.target.checked)
                }}/>
            }
        })
    })

    return columns
}


export const Permissions: React.FC<{ singleId?: string, appId?: string }> = ({singleId, appId}) => {
    const store = useStore({appId, singleId})

    return (
        <div>
            <Flex justify={"space-between"}>
                <Select style={{width: "300px", marginBottom: 10}}
                        options={store.getRoles()}
                        value={[]}
                        placeholder={i18n.t("role.select-role")}
                        defaultValue={{}}
                        onChange={(value: any) => {
                            store.addRole(value)
                        }}
                />
                <Button type={"primary"} onClick={store.save}>{i18n.t("common.save")}</Button>
            </Flex>
            <Divider/>
            <Table columns={GetColumns(store)} dataSource={store.data} pagination={{position: []}}/>
        </div>
    )
}