import React from 'react'
import {Request} from "kaiju.core";

const useApplicationStore = (props: any) => {
    const [data, setData] = React.useState<any>([]);


    return [data, {
        loadData: () => {
            Request("ApplicationGui.list").then((response)=> {
                setData(response.result.data)
            })
        }

    }]
};

export {useApplicationStore}
