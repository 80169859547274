import React from "react";
import {Form as KForm} from "kaiju.core"
import {Card, Form, Collapse} from "antd"
import * as Yup from 'yup';
import {Divider} from "antd";
import {getIn} from "formik";
import './style.scss'


export class ComponentFieldStore extends KForm.Fields.FieldStore {
    validator: any;
    nested: { [p: string]: any } = {}
    fields: any[] = []

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.nested = props.nested_forms

        // this.value = typeof props.value === 'object' && !Array.isArray(props.value) ? props.value : {};
        // this.validator = Yup.object().nullable();
        this.initNested(props.fields);
        // this.makeValidator(props);
    }

    initNested(fields: any[]) {
        const _validators: { [key: string]: any } = {};
        fields.map((field: any) => {
            const fStore = this.form.kindMap[field.kind] ? this.form.kindMap[field.kind] : undefined;
            if (fStore) {
                const _store = new fStore({
                    form: this.form,
                    ...field,
                    loadExtraParams: this.loadExtraParams
                });
                this.fields.push(_store);
                _validators[field.id] = _store.validator;

                // if (!(field.id in this.value)) {
                //     this.value[field.id] = _store.value
                // }
            }
        });
        // this.validator = this.validator.shape(
        //     _validators
        // )
    }


    Component: React.FC = (props: any) => {
        const errors = props.errors || props.formik.errors;
        const _id = this._id(props)

        return (
            <>
                <Collapse
                    className={"c-collapse"}
                    items={[{
                        key: "component-form",
                        label: <>
                        <Form.Item className={"c-field-item"} label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}/></>,
                        children: (
                            <>
                                {this.fields.map((fieldStore: any) => {
                                    return <fieldStore.Component
                                        key={`${props.index}-form-field-nested-${fieldStore._id()}`}
                                        formik={props.formik}
                                        parentId={_id}
                                        values={getIn(props.formik.values, _id)}
                                        errors={errors}
                                    />
                                })}
                            </>
                        )
                    }]}
                />
                {/*<Form.Item label={this.label} required={this.props.required}*/}
                {/*           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>*/}
                {/*    <Divider style={{marginTop: 5, marginBottom: 20}}/>*/}

                {/*    <Card>*/}

                {/*    </Card>*/}
                {/*</Form.Item>*/}

            </>
        )
    }

}

