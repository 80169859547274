import React from "react";
import {Layout, Flex, Divider} from 'antd'
import {CloseOutlined} from "@ant-design/icons";
import './style.scss'


const BaseMenu: React.FC<{ store: any, title: any, onClick: any, isActive: any }> = ({
                                                                                         store,
                                                                                         title,
                                                                                         onClick,
                                                                                         isActive,
                                                                                     }) => {
    return (
        <>
            {store.open &&
                <Layout.Sider theme={"light"} collapsed={false} className={"c-builder-menu"}
                              width={250}
                >
                    <Flex justify={"space-between"}>
                        <h3>{title}</h3>
                        <CloseOutlined className={"c-builder-close"} onClick={store.onClose}/>
                    </Flex>
                    <Divider style={{margin: 0}}/>

                    <ul className="cc-ul">
                        {store.data.map((el: any) => (
                            <li
                                key={'cc-li-' + el.id}
                                className={`li-row ${(isActive && isActive(el)) ? "cc-active" : ""}`}
                                onClick={() => {
                                    onClick(el)
                                }}
                            > {el.label}</li>
                        ))}
                    </ul>
                </Layout.Sider>
            }
        </>
    )
}

export {BaseMenu}