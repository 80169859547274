import i18n from "i18next";
import {Request} from 'kaiju.core';

interface SignUpInterface {
    notification: any,
    navigateCallback: void | any
}

const SignUpStore = (props: SignUpInterface) => {
    return  {
        fields: [{
            id: "auth",
            fields: [
                {
                    id: "username",
                    kind: "string",
                    required: true,
                    label: i18n.t("auth.email")
                },
                {
                    id: "password",
                    kind: "password",
                    required: true,
                    label: i18n.t("auth.password")
                },
            ]
        }],
        disableGroups: true,
        onSubmit: (values: any, formikState: any) => {
            Request('auth.login', values).then((data) => {
                const error = data.error;

                if (error) {
                    const code = error.data.code;
                    const message = i18n.t(code);
                    props.notification.error({
                        message: i18n.t('auth.error.authentication_error'),
                        description: message,
                        placement: 'topRight',
                    });
                } else {
                    props.navigateCallback();
                }
            })
        }
    };
};

export default SignUpStore;

