import React from "react";
import {Flex, Typography, Breadcrumb, Tooltip, Divider, Button, Popconfirm} from "antd"
import type {StoreInterface} from './store'
import {FolderIcon} from "./FolderIcon";
import i18n from "i18next";
import {CreateAssets} from "./CreateAssets";
import {CreateFolder} from "./CreateFolder";
import {AssetViewer} from "./AssetView";
import './style.scss'
import store from "src/pages/Auth/SignIn/store";

interface MediaLibraryFolderProps {
    store: StoreInterface,
    title: any
}


export const MediaLibraryFolder = (props: MediaLibraryFolderProps) => {

    return <>
        <Flex justify={"space-between"} gap={"small"} align={"center"}>
            <Breadcrumb
                items={[
                    {
                        title: props.store.parents.length > 0 ? <a onClick={() => {
                            props.store.goTo(null)
                        }}>{props.title}</a> : props.title,
                    },
                    ...props.store.parents.map((val: any) => {
                        return {
                            title: val.id !== props.store.currentFolder ? <a onClick={() => {
                                props.store.goTo(val.id)
                            }}>{val.label}</a> : val.label,
                        }
                    })
                ]}
            />
            {(props.store.removeAssets && props.store.checkedValues.length > 0) &&
                <Popconfirm
                    title={i18n.t("media.delete-confirm")}
                    onConfirm={props.store.deleteAssets}
                    cancelButtonProps={{loading: props.store.isDeleting}}
                    okButtonProps={{loading: props.store.isDeleting}}
                    okText={i18n.t("common.delete")}
                    cancelText={i18n.t("common.cancel")}
                >
                    <Button danger>{i18n.t("common.delete")} {props.store.checkedValues.length}</Button>
                </Popconfirm>
            }
        </Flex>

        <Divider>{i18n.t("media.folders")}</Divider>
        <Flex wrap gap="small">
            {props.store.folders.map((folder: any) => {
                return (
                    <Flex key={"folder-flex-" + folder.id} style={{cursor: "pointer"}} vertical align={"center"}
                          onClick={() => {
                              props.store.goTo(folder.id)
                          }}>
                        <FolderIcon className="folder-icon"/>
                        <Tooltip placement={"bottom"} title={folder.label}>
                            <Typography.Paragraph ellipsis={{rows: 2}}
                                                  className="folder-text">{folder.label}</Typography.Paragraph>
                        </Tooltip>
                    </Flex>
                )
            })}
            <CreateFolder store={props.store} key={props.store.currentFolder || "root"}/>
        </Flex>
        <Divider>{i18n.t("media.assets")}</Divider>
        <Flex style={{width: "100%", padding: 20}} justify={"center"}>
            <CreateAssets store={props.store} key={"create-" + props.store.currentFolder || "root"}/>
        </Flex>
        <AssetViewer store={props.store}/>
    </>
}