import React from 'react'
import {App, Button, Space, Drawer} from "antd";
import {Form, useDynamicForm} from "kaiju.core";

interface Props {
    appId: string
    collectionId: string
    status: string
    open: boolean
    fields?: string[]
    onClose: () => void
    onSave: () => void
}

export const CreateCollectionItem: React.FC<Props> = (props) => {
    const {notification} = App.useApp()
    const onSave = props.onSave;
    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        loadRequest: {
            method: "WaymorrCollection.item.create.model",
            params: {
                application: props.appId,
                collection: props.collectionId,
                fields: props.fields
            }
        },
        loadExtraParams: {
            application: props.appId
        },
        saveRequest: {
            method: "WaymorrCollection.item.create",
            params: {
                application: props.appId,
                collection: props.collectionId,
                status: props.status,
            }
        },
        errorCallback: ({error}: any) => {
            if (error.code === 409) {
                notification.error({
                    duration: 2,
                    message: "Оборудование с серийным номером " + error.message + " сушествует!!",
                });
            }
        },
        successCallback: () => {
            notification.success({
                duration: 2,
                message: "collection.saved",
            });
            onSave()
            props.onClose()
        }
    })


    const save = () => {
        store.submit()
    }
    return (
        <>
            <Drawer
                title={"Создать оборудование"}
                width={720}
                onClose={props.onClose}
                open={props.open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={props.onClose}>Отменить</Button>
                        <Button onClick={save} type="primary">
                            Создать
                        </Button>
                    </Space>
                }
            >
                <Form className={"collection-attrs"} store={store.formStore}/>
            </Drawer>
        </>
    )
}
