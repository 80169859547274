import React from "react";
import {WarehouseBasePage} from "./Base";


export const HostingPage: React.FC = () => {
    const status = "Хостинги"

    const preFilters = [
        {
            "id": "status",
            "value": [
                status
            ],
            "condition": "in_list",
            "kind": "select",
            "key": "filter-1720784755768",
            "nested_in": "attributes"
        }
    ]
    const columns = [
        "cities",
        "customer",
        "hosting_status",
        "manufacturer_model",
        "hashrate",
        "id",
        "date_on",
        "date_off",
        "mac_address",
        "worker1",
        "pool1",
        "worker2",
        "pool2",
        "worker3",
        "pool3"
    ]
    const fields = [
        "status",
        "cities",
        "customer",
        "hosting_status",
        "manufacturer_model",
        "hashrate",
        "date_on",
        "date_off",
        "mac_address",
        "worker1",
        "pool1",
        "worker2",
        "pool2",
        "worker3",
        "pool3",
        "hosting_comment"
    ]
    return <WarehouseBasePage
        prefix={"hosting"}
        columns={columns} preFilters={preFilters} title={status} createStatus={status}
        fields={fields}/>
}