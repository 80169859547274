import React from 'react'
import {Request} from "kaiju.core";

interface StoreProps {
    id: any,
    method: string,
    params?: { [p: string]: any }
}

const useStore = ({id, method, params}: StoreProps) => {
    const [data, setData] = React.useState<any[]>([])
    const [open, setOpen] = React.useState<boolean>(true)

    const fetchData = () => {
        Request(method, params || {}).then((resp: any) => {
            setData(resp.result)
        })
    }
    React.useEffect(() => {
        fetchData()
    }, [id])

    return {
        id,
        data,
        open,
        fetchData,
        setOpen,
        onClose: () => {
            setOpen(false)
        },
        refresh: () => {
            fetchData()
        },
        toggle: () => {
            setOpen(!open)
        }
    }
}

export {useStore}