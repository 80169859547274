import React from "react";
import {LayoutOutlined, SettingOutlined, CloudServerOutlined} from "@ant-design/icons"
import {TaskBoardPage} from "packages/src/TaskTracker";
import {CustomersCreate} from "packages/src/Customers";
import {WarehouseClientPage} from "packages/src/Warehouse/Clinet"
import {WarehouseRepairPage} from "packages/src/Warehouse/Repair"
import {WarehouseSalePage} from "packages/src/Warehouse/Sale"
import {HostingPage} from "packages/src/Warehouse/Hosting"
import {RepairIcon} from "packages/src/icons/Repair"
import {CartIcon} from "packages/src/icons/Cart"
import {CustomersIcon} from "packages/src/icons/Customers"
import {initFields} from "packages/src/Components/CutomForm"
import {getFilters} from "packages/src/Components/Filters"
import {MonitoringPage, PayHistoryPage} from "packages/src/Monitoring"
import {Tag} from "antd";
import "./style.scss"


export default [
    {
        name: "Waymor",
        application: {
            init: () => {
                initFields()
                getFilters()
            },
            menu: (items: any[], appData: any) => {
                if (appData.id === 'waymorr') {
                    items.push({
                        key: "board",
                        icon: <LayoutOutlined/>,
                        label: "Задачи",
                        permissions: []
                    })
                    items.push({
                        type: 'divider',
                        permissions: []
                    })
                    items.push({
                        key: "wh-clients",
                        icon: <CustomersIcon/>,
                        label: "Отгружено клиентам",
                        permissions: []
                    })
                    items.push({
                        key: "wh-repair",
                        icon: <RepairIcon/>,
                        label: "Сервисный центр",
                        permissions: []
                    })
                    items.push({
                        key: "wh-sale",
                        icon: <CartIcon/>,
                        label: "Склад продаж",
                        permissions: []
                    })
                    items.push({
                        key: "hosting",
                        icon: <CloudServerOutlined />,
                        label: "Хостинги",
                        permissions: []
                    })

                    items.push({
                        type: 'divider',
                        permissions: []
                    })

                }
            }
        },
        routes: {
            application: (routeData: any) => {
                routeData.children.push({
                    id: "board",
                    path: "board",
                    element: <TaskBoardPage/>
                })
                routeData.children.push({
                    id: "whClients",
                    path: "wh-clients",
                    element: <WarehouseClientPage/>
                })
                routeData.children.push({
                    id: "whRepair",
                    path: "wh-repair",
                    element: <WarehouseRepairPage/>
                })
                routeData.children.push({
                    id: "whSale",
                    path: "wh-sale",
                    element: <WarehouseSalePage/>
                })
                routeData.children.push({
                    id: "hosting",
                    path: "hosting",
                    element: <HostingPage/>
                })
                routeData.children.push({
                    id: "monitoring",
                    path: "monitoring",
                    element: <MonitoringPage/>
                })
                routeData.children.push({
                    id: "history",
                    path: "history",
                    element: <PayHistoryPage/>
                })

            }
        },
        collection: {
            extraButtons: (extraButtons: any[], collectionData: any, tableStore: any) => {
                if (collectionData.id === 'customers') {
                    extraButtons.push(<CustomersCreate tableStore={tableStore}/>)
                }

            },
            attributeKind: (AttributeKinds: any[]) => {
                if (!AttributeKinds.some(el => (el.kind === 'manufacturer'))) {
                    AttributeKinds.push(
                        {
                            kind: "manufacturer",
                            label: "Производитель / модель",
                            icon: <Tag className={"attr-kind-icon"} color={"magenta"}><SettingOutlined/></Tag>,
                            description: "Список производителей с моделями"
                        },
                    )
                }
            }
        }
    },
]