import React from "react";
import {Form as KForm} from "kaiju.core"
import {Form} from "antd"
import * as Yup from 'yup';
import {getIn} from "formik";
import {SelectAsync} from "./SelectAsync"
import './style.scss'


export class CustomerFieldStore extends KForm.Fields.FieldStore {
    validator: any;
    optionHandler: string;
    params: any;

    constructor(props: any) {
        super(props);
        this.optionHandler = props.options_handler;
        this.params = props.params;
        this.defaultValue = props.default || "";
        this.validator = Yup.string().nullable();
        this.makeValidator(props)
    }

    Component: React.FC = (props: any) => {
        const errors = props.errors || props.formik.errors;
        const _id = this._id(props);
        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <SelectAsync
                        onChange={(val: any) =>{
                             props.formik.setFieldValue(_id, val ? val.value : val)
                        }}

                        defaultValue={this.defaultValue}
                        disabled={this.disabled}
                        required={this.props.required}
                        value={getIn(props.formik.values, _id)}
                        optionHandler={this.optionHandler}
                        params={{...this.params, ...this.loadExtraParams}}
                    />
                    <KForm.Error message={errors[this.id]}/>
                </Form.Item>
            </>
        )
    }

}

