import React from "react";
import {Layout, Card, Flex, Input, Badge, Button, Drawer} from "antd"
import {useOutletContext, useParams} from "react-router-dom";
import {FilterOutlined, SearchOutlined, SettingOutlined} from "@ant-design/icons";
import i18n from "i18next";
import {ConditionFilters} from "core/src/components/ConditionFilters";
import {FiltersComponent} from "core/src/pages/Application/Content/Collection/Grid/filters";
import {Table} from "core/src/components/Table";
import {isPermitted} from "core/src/components/Permissions";
import {HeaderComponent} from "core/src/components/Header";
import {EditCollectionItem} from "packages/src/EditItem"
import {CreateCollectionItem} from "./Create"
import {BulkActions} from "core/src/pages/Application/Content/Collection/Grid/BulkActions";
import {Actions} from "core/src/pages/Application/Content/Collection/Grid/Actions";
import {ColumnContent, useColumns} from "core/src/components/Columns";
import {Request} from "kaiju.core";


interface Props {
    prefix: string,
    preFilters: any[]
    columns: string[]
    fields?: string[]
    createStatus: string
    title: string
    method?: string
}

interface Params {
    contentId: string,
    appId: string,
}

interface SelectedProps {
    allSelected: boolean,
    id: string[],
    count: number
}

const cells = {
    byID: {
        manufacturer_model: (row: any) => {
            if (row.manufacturer_model?.value) {
                return <div>{row.manufacturer_model?.value?.manufacturer} / {row.manufacturer_model?.value?.model}</div>
            }
            return <></>
        }
    }
}

export const WarehouseBasePage: React.FC<Props> = (props) => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()
    const [itemId, setItemsId] = React.useState<string>()
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)
    const collectionId = "306ebcd3-6387-5664-f285-14697f65ae65"
    const [open, setOpen] = React.useState<boolean>(false)
    const [openColumns, setOpenColumns] = React.useState<boolean>(false)
    const [selectedState, setSelectedState] = React.useState<SelectedProps>({
        allSelected: false,
        id: [],
        count: 0,
    })
    const params = useParams<keyof Params>() as Params
    const store = Table.useStore("WarehouseClientsPage", {
        preFilters: props.preFilters,
        request: {
            method: props.method || "WaymorrGui.collection.item.grid",
            params: {
                prefix: props.prefix,
                columns: props.columns,
                collection: collectionId,
            }
        },
        onSelectedRows: ({allSelected, selectedRows, selectedCount}) => {
            setSelectedState({
                count: selectedCount,
                allSelected: allSelected,
                id: selectedRows.map((row: any) => {
                    const id = Object.keys(row)[0]
                    return row[id]._id
                })
            })
        },
        onClick: (row: any) => {
            if (isPermitted(collectionId, ["update"])) {
                const key = Object.keys(row)
                setItemsId(row[key[0]]._id)
            }
        }
    })

    const useActions = (row: any, index: number, column: any, id: any) => {
        return <Actions collectionId={params.contentId} row={row} column={column} id={id} index={index} store={store}/>
    }

    const bulkActionCallback = () => {
        store.fetchData()
        setSelectedState({
            allSelected: false,
            count: 0,
            id: []
        })
    }

    const columnStore = useColumns({
        id: collectionId,
        loadRequest: {
            method: "CollectionGui.column.list",
            params: {collection: collectionId}
        },
        loadCurrentRequest: {
            method: "WaymorrGui.collection.column.current.get",
            params: {
                prefix: props.prefix,
                collection: collectionId,
                columns: props.columns,
            }
        },
        onChange: (newColumns) => {
            Request("WaymorrGui.collection.column.user.set", {
                prefix: props.prefix,
                collection: collectionId,
                columns: newColumns
            }).then(() => {
                store.fetchData(true)
            })
        }
    })

    const filterStore = ConditionFilters.useFilterStore({data: []})

    const onClose = () => {
        setItemsId(undefined)
    }
    const onSave = () => {
        store.fetchData()
        setItemsId(undefined)
    }
    return <>
        <Layout
            className={"h-100"} style={{padding: "1.5rem"}}
        >
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: props.title,
                }
            ]}/>
            <Flex align={"center"} justify={"space-between"}>
                <h2>{props.title}</h2>
                <Button type={"primary"} onClick={() => {
                    setOpen(true)
                }}>Создать</Button>
            </Flex>
            <Flex justify={"space-between"}>
                <div style={{paddingBottom: 20, maxWidth: 500}}>
                    <Input prefix={<SearchOutlined/>} style={{maxWidth: 500}} onChange={(event: any) => {
                        store.querySearch(event.currentTarget.value)
                    }} placeholder={i18n.t("common.search")}/>
                </div>
                <Flex wrap gap="small">
                    <Badge count={filterStore.data?.length}>
                        <Button onClick={() => {
                            setOpenFilters(true)
                        }} icon={<FilterOutlined/>}/>
                    </Badge>
                    <Button onClick={() => {
                        setOpenColumns(true)
                    }} icon={<SettingOutlined/>}/>
                </Flex>
            </Flex>
            <Card>
                <Table store={store} cells={cells}/>
            </Card>

            {(itemId && isPermitted(collectionId, ["update"])) &&
                <EditCollectionItem onSave={onSave} fields={props.fields} onClose={onClose} open={!!itemId}
                                    key={"edit-wh-cl" + itemId}
                                    appId={"waymorr"} itemId={itemId}
                                    collectionId={collectionId}/>
            }
            {(open && isPermitted(collectionId, ["create"])) &&
                <CreateCollectionItem onSave={onSave} onClose={() => {
                    setOpen(false)
                }} open={open} key={"скуфеу-wh-cl"}
                                      appId={"waymorr"} fields={props.fields} status={props.createStatus}
                                      collectionId={collectionId}/>
            }

        </Layout>
        <FiltersComponent key={"wh-cl-filter-" + collectionId} setFilter={(filters: any[]) => {
            store.addFilters(filters)
        }} params={{content: collectionId,}} filterStore={filterStore} openFilters={openFilters}
                          setOpenFilters={setOpenFilters}/>

        <BulkActions collectionId={collectionId} appId={params.appId} selectedState={selectedState}
                     successCallback={bulkActionCallback}/>

        <Drawer
            key={"column-" + params.contentId}
            title={i18n.t("column.select-columns")}
            width={500}
            onClose={() => {
                setOpenColumns(false)
            }}
            open={openColumns}
            extra={
                <div>
                    <Button type={"default"} onClick={columnStore.reset}>
                        {i18n.t("column.reset-default")}
                    </Button>
                </div>
            }
        >
            <ColumnContent key={"column-content-" + columnStore.id} store={columnStore}/>
        </Drawer>
    </>
}