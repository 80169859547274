import React from "react";
import {Form as KForm} from "kaiju.core"
import {Card, Collapse, Divider, Dropdown, Flex, Form, Input, Modal, Typography} from "antd";
import {FieldArray, getIn} from 'formik';
import * as Yup from 'yup';
import {DeleteOutlined, EditOutlined, EllipsisOutlined} from "@ant-design/icons"
import {ReactSortable} from "react-sortablejs";
import MD5 from "crypto-js/md5";
import {StaticZoneStore} from "../StaticZone";
import i18n from "i18next";


export class DynamicZoneStore extends KForm.Fields.FieldStore {
    validator: any;
    nested: { [p: string]: any }
    fields: { [p: string]: any } = {}
    components: string[]

    constructor(props: any) {
        super(props);

        this.defaultValue = props.default || [];
        this.nested = props.nested_forms
        this.components = props.reference_components
        this.value = Array.isArray(props.value) ? props.value : [];
        this.validator = Yup.array().nullable();
        this.initNested();
        this.makeValidator(props);
    }

    initNested() {
        const _validators: { [key: string]: any } = {};
        this.value.forEach((value: any) => {
            const _filedStore = this.nested[value._component]
            this.fields[value._uid] = new StaticZoneStore({
                form: this.form,
                ..._filedStore,
                id: this.id,
                loadExtraParams: this.loadExtraParams
            })
            // _validators[_id] = _store.validator;
        })

        this.validator = this.validator.of(
            Yup.object().shape(
                _validators
            )
        )
    }

    emptyValue = (id: string) => {
        const _filedStore = this.nested[id]
        const _store = new StaticZoneStore({
            form: this.form,
            ..._filedStore,
            id: this.id,
            loadExtraParams: this.loadExtraParams
        });
        const resp: { [key: string]: any } = {
            _component: id,
            _uid: MD5((new Date()).toString()).toString()
        };
        this.fields[resp._uid] = _store

        _filedStore.fields.forEach(((val: any) => {
            resp[val.id] = val.value;
        }));
        return resp
    };

    Component: React.FC = (props: any) => {
        const changeSorting = (values: any[]) => {
            this.props.form.formik.setFieldValue(this._id(this.props), values)
        }
        const [editLabel, setEditLabel] = React.useState<number | undefined>()
        const [newLabel, setNewLabel] = React.useState<string>("")

        React.useEffect(()=>{
            setNewLabel("")
        }, [editLabel])

        const getLabel = (index: number) => {
            const _val = getIn(this.props.form.formik.values, this._id(props) + "." + index)
            return _val.__label ? _val.__label : this.fields[_val._uid].label
        }
        const setLabel = (index: number, label: string) => {
            this.props.form.formik.setFieldValue(this._id(props) + "." + index + ".__label", label)
        }
        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Divider style={{marginTop: 5, marginBottom: 20}}/>
                    <FieldArray
                        name={this.id}
                        render={(arrayHelpers: any) => (
                            <>
                                <ReactSortable list={getIn(arrayHelpers.form.values, this._id(props)) || []}
                                               setList={(newState, sortable, store) => {
                                                   if (sortable && store.dragging) {
                                                       changeSorting(newState)
                                                   }
                                               }}>
                                    {Array.isArray(getIn(arrayHelpers.form.values, this._id(props))) && getIn(arrayHelpers.form.values, this._id(props)).map((values: any, index: number) => {
                                        const fieldStore = this.fields[values._uid]
                                        return (
                                            <Collapse
                                                style={{marginBottom: 10}}
                                                items={[{
                                                    key: fieldStore.id,
                                                    label: getLabel(index),
                                                    extra: <Dropdown menu={{
                                                        items: [
                                                            {
                                                                icon: <EditOutlined/>,
                                                                key: "edit",
                                                                label: <a onClick={(e: any) => {
                                                                    e.stopPropagation();
                                                                    setEditLabel(index)
                                                                    return false
                                                                }}>{i18n.t("common.edit")}</a>
                                                            },
                                                            {
                                                                danger: true,
                                                                key: "delete",
                                                                icon: <DeleteOutlined/>,
                                                                label: <a onClick={(e: any) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(index)
                                                                    return false
                                                                }}>{i18n.t("common.delete")}</a>
                                                            }
                                                        ]
                                                    }}>
                                                        <EllipsisOutlined
                                                            onClick={(e) => e.stopPropagation()}
                                                        />
                                                    </Dropdown>,
                                                    children: <fieldStore.Component
                                                        key={`form-field-nested-${index}`}
                                                        formik={arrayHelpers.form}
                                                        values={values}
                                                        index={index}
                                                        errors={props.formik.errors[this.id]?.[index] || {}}/>
                                                }]}/>
                                        )
                                    })}
                                </ReactSortable>
                                <Flex wrap gap={"middle"}>
                                    {this.components.map((id: any) => (
                                        <Card
                                            style={{
                                                background: "whitesmoke",
                                                cursor: "pointer",
                                                border: "1px dashed #d9d9d9"
                                            }}
                                            key={"btn-" + id}
                                            onClick={() => {
                                                arrayHelpers.push(this.emptyValue(id))
                                            }}>
                                            <Typography.Text>
                                                {this.nested[id].label}
                                            </Typography.Text>
                                        </Card>
                                    ))}
                                </Flex>
                            </>
                        )}/>
                </Form.Item>

                <Modal
                    key={"zone-modal-" + editLabel}
                    title={i18n.t("common.edit")}
                    open={editLabel !== undefined}
                    onOk={() => {
                        if (editLabel !== undefined) setLabel(editLabel, newLabel)
                        setEditLabel(undefined)
                    }}
                    onCancel={() => setEditLabel(undefined)}
                >
                    {editLabel !== undefined &&
                        <Input
                            onChange={(e)=> setNewLabel(e.target.value)}
                            defaultValue={getLabel(editLabel)}
                        />
                    }
                </Modal>
            </>
        )
    }

}

