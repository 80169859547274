import React from 'react'
import {Request} from "kaiju.core";

interface RequestProps {
    method: string,
    params?: { [p: string]: any }
}

interface ColumnProps {
    loadRequest: RequestProps,
    loadCurrentRequest: RequestProps,
    id?: any,
    onChange?: (columns: string[]) => void
}

const useColumns = (props: ColumnProps) => {
    const [fullColumns, setFullColumns] = React.useState<any[]>([])
    const [columns, setColumns] = React.useState<string[]>([])

    const loadAllColumns = async () => {
        const columns = await Request(
            props.loadRequest.method,
            props.loadRequest.params
        ).then((resp: any) => {
            return resp.result.data
        })
        setFullColumns(columns)

        const currentColumns = await Request(
            props.loadCurrentRequest.method,
            props.loadCurrentRequest.params
        ).then((resp: any) => {
            return resp.result || []
        })
        setColumns(currentColumns)
    }


    React.useEffect(() => {
        loadAllColumns().then()
    }, [props.id])

    const addColumns = (columns: string[]) => {
        setColumns(columns)
        if (props.onChange) {
            props.onChange(columns)
        }
    }

    const dataMap = () => {
        return fullColumns.filter((val) => (columns.includes(val.value))).sort(
            (x, y) => (columns.indexOf(x.value) - columns.indexOf(y.value))
        )
    }
    const reset = () => {
        if (props.onChange) {
            props.onChange([])
            setColumns([])
        }
        setTimeout(() => {
            loadAllColumns().then()
        }, 200)
    }

    const removeColumn = (value: string) => {
        const _columns = columns.filter((el) => el !== value)
        setColumns(_columns)
        if (props.onChange) {
            props.onChange(_columns)
        }
    }

    return {
        id: props.id,
        reset,
        addColumns,
        removeColumn,
        dataMap,
        fullColumns,
        columns
    }
}

export {useColumns}