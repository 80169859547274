import React from 'react'
import {Drawer, Space, Button, App} from "antd";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";


interface Props {
    open: boolean
    userId: string
    onClose: () => void
    onEdit: () => void
}

export const UserEdit: React.FC<Props> = (props) => {
    const handleClose = () => {
        props.onEdit()
        props.onClose()
    }
    const {notification} = App.useApp();

    const [store] = useDynamicForm({
        autoLoad: true,
        loadRequest: {
            method: 'users.edit.get',
            params: {
                id: props.userId
            }
        },
        isNested: false,
        saveDiff: true,
        loadExtraParams: {},
        saveRequest: {
            method: 'users.edit.update',
            params: {
                id: props.userId,
            }
        },
        successCallback: (response: any) => {
            notification.success({
                message: i18n.t('common.updated'),
                placement: 'topRight',
            });
            handleClose()
        }
    });

    return <>
        <Drawer
            title={
                <>
                    <span>{i18n.t("user.edit")}</span>
                </>}
            onClose={handleClose}
            width={900}
            open={props.open}
            size={"large"}
            extra={<>
                <Button onClick={() => {
                    store.submit()
                }} type="primary">
                    {i18n.t("common.save")}
                </Button>
            </>}
        >
            <Form store={store.formStore}>
            </Form>
        </Drawer>
    </>
}
