import {Form} from "kaiju.core";
import {MediaStore} from './MediaField'
import {DynamicZoneStore} from './DymanicZone'
import {StaticZoneStore} from './StaticZone'
import {ComponentFieldStore} from './ComponentField'
import {ComponentListFieldStore} from './ComponentListField'
import {ComponentAny} from './ComponentAny'

export const setMapping = () => {
    Form.kindMap["collection_select"] = Form.kindMap["select_async"]
    Form.kindMap["collection_multiselect"] = Form.kindMap["multiselect_async"]
    Form.kindMap["media"] = MediaStore
    Form.kindMap["dynamic_zone"] = DynamicZoneStore
    Form.kindMap["identifier"] = Form.kindMap["string"]
    Form.kindMap["static_zone"] = StaticZoneStore
    Form.kindMap["component"] = ComponentFieldStore
    Form.kindMap["component_list"] = ComponentListFieldStore
    Form.kindMap["component_any"] = ComponentAny
}
