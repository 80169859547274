import React from "react";
import {App} from "antd"
import {Request} from "kaiju.core";
import {useStore as useTableStore} from 'src/components/Table/store'
import type {StoreInterface as TableStoreInterface} from 'src/components/Table/store'
import i18n from "i18next";

interface FolderProps {
    id: string,
    label: string
}

interface RequestProps {
    method: string,
    params?: { [p: string]: any }
}

interface uploadProps {
    action: string,
    method?: 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch'
    multiple?: boolean,
    withCredentials?: boolean,
    accept?: string // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
}

interface StoreProps {
    id?: string
    headers?: { [p: string]: any }
    uploadProps: uploadProps
    removeAssets?: boolean
    metaData?: { [p: string]: any }
    defaultChoice?: string[]
    onChoice?: (values: string[]) => void
    defaultCheckedValues?: string[]
    requests: {
        createFolder?: RequestProps,
        loadCheckedAssets?: RequestProps,
        loadFolders?: RequestProps,
        deleteFolder?: RequestProps,
        createAssets?: RequestProps,
        loadAssets: RequestProps
        deleteAssets?: RequestProps
    }
}


interface StoreInterface {
    uploadProps: uploadProps
    headers?: { [p: string]: any }
    metaData?: { [p: string]: any }
    folders: FolderProps[]
    parents: FolderProps[]
    onChange: (value: string, checked: boolean) => void
    setCheckedValues: (values: string[]) => void
    deleteAssets: () => void
    isChoice: boolean,
    loadingMap: boolean,
    checkedValues: any[],
    defaultCheckedValues?: string[]
    assetTableStore: TableStoreInterface,
    currentFolder: string | null
    hasFolders: boolean,
    removeAssets: boolean,
    isDeleting: boolean,
    assetMap: { [p: string]: any }
    createFolder: (label: string, successCallback?: any) => void
    createAssets: (asset: any) => any
    goTo: (id: any) => void

}

export const useStore = (props: StoreProps): StoreInterface => {
    const [folders, setFolders] = React.useState<FolderProps[]>([])
    const [currentFolder, setCurrentFolder] = React.useState<string | null>(null)
    const [parents, setParents] = React.useState<FolderProps[]>([])
    const [checkedValues, setCheckedValues] = React.useState<string[]>(props.defaultCheckedValues || [])
    const hasFolders: boolean = !!props.requests?.loadFolders
    const [assetMap, setAssetMap] = React.useState<{ [p: string]: any }>({})
    const [loadingMap, setLoadingMap] = React.useState<boolean>(false)
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
    const {notification} = App.useApp()

    const assetTableStore = useTableStore(props.id, {
        request: props.requests.loadAssets
    })

    const loadFiles = () => {
        assetTableStore.fetchData()
    }
    const loadFolders = () => {
        if (props.requests?.loadFolders?.method) {
            Request(
                props.requests.loadFolders.method,
                {
                    ...props.requests.loadFolders?.params,
                    folder: currentFolder
                }).then((resp: any) => {
                if (resp.result) {
                    setFolders(resp.result.folders)
                    setParents(resp.result.parents)
                } else {
                    setFolders([])
                }
            })
        }
    }

    React.useEffect(() => {
        if (props.defaultCheckedValues && props.defaultCheckedValues?.length > 0) {
            const needLoad = props.defaultCheckedValues.filter((id: string) => (!Object.keys(assetMap).includes(id)))
            if (needLoad.length > 0 && props?.requests?.loadCheckedAssets) {
                setLoadingMap(true)
                Request(
                    props.requests.loadCheckedAssets.method,
                    {
                        ...props.requests.loadCheckedAssets?.params,
                        id: needLoad
                    }).then((resp: any) => {
                    setLoadingMap(false)
                    if (resp.result) {
                        resp.result.forEach((asset: any) => {
                            assetMap[asset.id] = asset
                            setAssetMap({...assetMap})
                        })
                    }
                })
            }
        }
    }, [props.defaultCheckedValues])

    const createAssets = async (asset: any) => {
        if (props.requests?.createAssets) {
            const response = await Request(props.requests?.createAssets?.method, {...props.requests.createAssets.params, ...asset})
            if (response.result) {
                return response.result.endpoint
            }
        }
        return undefined
    }

    const deleteAssets = () => {
        if (props.requests?.deleteAssets?.method) {
            setIsDeleting(true)
            Request(props.requests?.deleteAssets?.method, {
                ...props.requests?.deleteAssets?.params,
                id: checkedValues
            }).then(({result}) => {
                if (result) {
                    loadFiles()
                    setCheckedValues([])
                    notification.success({
                        message: i18n.t('media.success-deleted'),
                        placement: 'topRight',
                    });
                }
                setIsDeleting(false)
            })
        }
    }

    const createFolder = (label: string, successCallback?: any) => {
        if (props.requests?.loadFolders?.method && props.requests?.createFolder?.method) {
            Request(props.requests?.createFolder?.method, {
                ...props.requests?.createFolder?.params,
                folder: currentFolder,
                label: label
            }).then((resp: any) => {
                if (resp.result) {
                    loadFolders()
                }
            })
        }

        if (successCallback) successCallback()
    }
    React.useEffect(() => {
        loadFiles()
        loadFolders()
    }, [props.id, currentFolder])

    React.useEffect(() => {
        if (props.onChoice) {
            props.onChoice(checkedValues)
        }
    }, [checkedValues])

    const goTo = (folder: string) => {
        setCurrentFolder(folder)
        assetTableStore.onChangePage(1)
        assetTableStore.updateExtraParams({folder})
    }
    const onChange = (asset: any, ch: boolean) => {
        if (ch && !checkedValues.includes(asset.id)) {
            assetMap[asset.id] = asset
            assetMap[asset.id] = asset
            setAssetMap({...assetMap})
            setCheckedValues([...checkedValues, asset.id])
        } else if (!ch) {
            setCheckedValues(checkedValues.filter((el: string) => el !== asset.id))
        }
    }

    return {
        assetTableStore,
        parents,
        createFolder,
        createAssets,
        currentFolder,
        hasFolders,
        folders,
        checkedValues,
        goTo,
        assetMap,
        isDeleting,
        loadingMap,
        deleteAssets,
        setCheckedValues,
        defaultCheckedValues: props.defaultCheckedValues,
        removeAssets: props.removeAssets || false,
        headers: props.headers,
        isChoice: !!props.onChoice,
        onChange: onChange,
        metaData: props.metaData,
        uploadProps: props.uploadProps,
    }
}

export type {FolderProps, StoreProps, StoreInterface}