import React from 'react'
import {Select, Tooltip, Tag, Alert, Button, App, Flex} from "antd";
import {useStore} from './store'
import {ReactSortable} from "react-sortablejs";
import {DragOutlined, CloseOutlined} from "@ant-design/icons"
import i18n from "i18next";

const Columns: React.FC<{ contentId?: string }> = ({contentId}) => {
    const {notification} = App.useApp();
    const store = useStore(notification, contentId)

    return (
        <div style={{maxWidth: 700}}>
            <Alert message={i18n.t("column.info")} type="info" showIcon style={{marginBottom: 20}}></Alert>
            <Select
                mode={"multiple"}
                options={store.fullColumns}
                onChange={store.addColumns}
                maxTagCount={'responsive'}
                placeholder={i18n.t("column.select-columns")}
                value={store.columns}
                style={{width: '100%'}}
                maxTagPlaceholder={(omittedValues) => {
                    return <Tooltip title={omittedValues.map(({label}) => label).join(', ')}>
                        <span>+ {omittedValues.length} ...</span>
                    </Tooltip>
                }}
            />
            <ReactSortable style={{marginTop: 20, marginBottom: 20}} list={store.dataMap()}
                           setList={(newState: any[], sortable: any) => {
                               store.addColumns(newState.map((el: any) => el.value))
                           }} onEnd={() => {

            }}>
                {store.columns && store.dataMap().map((item: any, index: number) => (
                    <Tag key={`${contentId}__${item.value}`} color={"geekblue"} closeIcon={<CloseOutlined/>}
                         onClose={()=>{
                             store.removeColumn(item.value)}} style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontSize: 14,
                        marginBottom: 5,
                        cursor: "pointer"
                    }} icon={<DragOutlined/>}>
                        # {index + 1} - {item.label}
                    </Tag>
                ))}
            </ReactSortable>
            {store.columns.length > 0 &&
                <Button onClick={store.saveColumns} type={"primary"}>{i18n.t("common.save")}</Button>
            }

        </div>
    )

}

export {Columns}
