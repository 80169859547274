import React from 'react'
import {Form, useDynamicForm} from "kaiju.core";
import {RequestInterface} from "kaiju.core/src/DynamicForm/store";
import {App, Button} from "antd";
import i18n from "i18next";

const Settings: React.FC<{ singleId?: string, appId?: string }> = ({singleId, appId}) => {
    const {notification} = App.useApp();

    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: true,
        saveDiff: false,
        loadRequest: {
            method: "ContentTypeBuilder.single.settings.get",
            params: {
                application: appId,
                id: singleId
            }
        },
        saveRequest: {
            method: "ContentTypeBuilder.single.settings.update",
            params: {
                application: appId,
                id: singleId
            }
        },
        successCallback: (props) => {
            notification.success({
                message: i18n.t('common.updated'),
                placement: 'topRight',
            });
        }
    })
    return (
        <div style={{maxWidth: 500}}>
            <Form store={store.formStore}>
                <Button onClick={() => {
                    store.submit()
                }} type={"primary"}>{i18n.t("common.save")}</Button>
            </Form>
        </div>
    )
}

export {Settings}
