import React from 'react'
import {Select, Card} from "antd"

interface BaseFilterProps {
    row: any,
    store: any,
    ValueConstructor?: any | undefined
    availableConditions?: any[]
}

const BaseFilter = (props: BaseFilterProps) => {
    return <>
        <Card title={props.row.label} extra={<>
            {(props.availableConditions && props.availableConditions.length > 0) &&
                <Select
                    style={{width: 200}}
                    onChange={(value) => {
                        props.store.changeCondition(props.row.key, value)
                    }}
                    options={props.availableConditions}
                    defaultValue={props.availableConditions.find((el: any) => (el.value === props.row.condition)) || props.availableConditions[0]}
                />
            }

        </>} styles={{body: {padding: 0}}}>
            {props.ValueConstructor &&
                <props.ValueConstructor condition={props.row.condition} row={props.row} store={props.store}/>
            }
        </Card>
    </>
}

export {BaseFilter}
