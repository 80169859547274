import React from 'react'
import {Tree, Flex} from "antd"
import {PlusOutlined, FolderOutlined, MoreOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import i18n from "i18next";
import {ComponentCreate} from "./Create";
import {ComponentGroupCreate} from "./GroupCreate";
import {GroupActions} from './GroupActions';

import "./style.scss"
import {useParams} from "react-router-dom";

interface Params {
    appId: string,
    componentId: string,
}

const ComponentsBlock: React.FC<{
    data: any[],
    isActive?: Function,
    onClick: Function,
    onCreate: Function
}> = (props) => {
    const [open, setOpen] = React.useState(false)
    const [openGroup, setOpenGroup] = React.useState(false)
    const {componentId, appId} = useParams<keyof Params>() as Params

    const onClose = () => {
        setOpen(false)
    }
    props.data.forEach((node: any) => {
        if (!node.selectable) {
            node["icon"] = <FolderOutlined/>
        }
    })


    return (
        <div style={{paddingBottom: 20}}>
            <Tree
                showIcon
                defaultExpandParent={true}
                defaultSelectedKeys={componentId ? [componentId] : []}
                defaultExpandedKeys={componentId ? [componentId] : []}
                style={{paddingTop: 10}}
                fieldNames={{
                    title: "label",
                    key: "id"
                }}
                titleRender={(node: any) => {
                    return !node.selectable && !node.parent?
                        <span>{node.label}
                            <GroupActions node={node} appId={appId} componentId={node.id} onCreate={props.onCreate}/>
                        </span>
                : <>{node.label}</>
                }}
                onSelect={(_: any, event: any) => {
                    props.onClick(event.node)
                }}
                treeData={props.data}
            />

            <Flex vertical>
                <a style={{paddingLeft: 0, marginTop: 10}} onClick={() => {
                    setOpenGroup(true)
                }}><PlusOutlined/> {i18n.t("component.add-group")}</a>
                <a style={{paddingLeft: 0, marginTop: 10}} onClick={() => {
                    setOpen(true)
                }}><PlusOutlined/> {i18n.t("component.add")}</a>
            </Flex>
            {open && <ComponentCreate open={open} onClose={onClose} onCreate={props.onCreate}/>}
            {openGroup && <ComponentGroupCreate open={openGroup} onClose={() => {
                setOpenGroup(false)
            }} onCreate={props.onCreate}/>}
        </div>
    )
}

export {ComponentsBlock}