import React from "react";
import {Form as KForm, Request} from "kaiju.core"
import {Divider, Flex, Form, Select, Space, Button, Input} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {useParams} from "react-router-dom";
import {getIn} from "formik";


interface Option {
    model: string[]
    manufacturer: string
}

export class Manufacturer extends KForm.Fields.FieldStore {
    validator: any;
    fields: any[]
    manufacturer: string[]
    models: { [p: string]: string[] }

    constructor(props: any) {
        super(props);
        this.defaultValue = props.default || [];
        this.value = props.value
        this.manufacturer = props.options.map((el: Option) => (el.manufacturer))
        this.models = {};
        this.fields = []

        props.options.forEach((el: Option) => {
            this.models[el.manufacturer] = el.model
        })
    }

    Component: React.FC = (props: any) => {
        const _id = this._id(props)
        const [currentModel, setCurrentModel] = React.useState<string[]>([])
        const [name, setName] = React.useState('');
        const [loading, setLoading] = React.useState<boolean>(false);
        const {contentId} = useParams<{ contentId: string}>()

        const addItem = () => {
            setLoading(true)
            const manufacturer = getIn(this.form.formik.values, _id + ".manufacturer")
            Request("WaymorrCollection.attribute.add_model", {
                collection: contentId,
                attribute: this.id,
                manufacturer: manufacturer,
                model: name
            }).then(({result}) => {
                if (result) {
                    this.models[manufacturer].push(name)
                    props.formik.setFieldValue(_id + ".model", name)
                    setName("")
                }
                setLoading(false)
            })

        }
        React.useEffect(() => {
            const value = getIn(this.form.formik.values, _id + ".manufacturer")
            if (value) {
                setCurrentModel(this.models[value] || [])
            }
        }, [this.form.formik.values])

        return (
            <>
                <Form.Item label={this.label} required={this.props.required}
                           tooltip={KForm.Helper({comp: this, className: "fs-16"})}>
                    <Divider style={{marginTop: 5, marginBottom: 20}}/>
                    <Flex gap={"middle"} justify={"space-between"}>
                        <Select
                            placeholder={"ПРОИЗВОДИТЕЛЬ"}
                            allowClear
                            onClear={() => {
                                props.formik.setFieldValue(_id, {
                                    manufacturer: undefined,
                                    model: undefined
                                })
                            }}
                            onChange={(value: string) => {
                                props.formik.setFieldValue(_id, {
                                    manufacturer: value,
                                    model: undefined
                                })
                            }}
                            value={getIn(this.form.formik.values, _id + ".manufacturer")}
                            options={this.manufacturer.map((el: string) => (
                                {value: el, label: el})
                            )}/>
                        <Select placeholder={"МОДЕЛЬ"}
                                onChange={(value) => {
                                    props.formik.setFieldValue(_id + ".model", value)
                                }}
                                value={getIn(this.form.formik.values, _id + ".model")}
                                options={currentModel.map((el: string) => (
                                    {value: el, label: el})
                                )}
                                loading={loading}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: '8px 0'}}/>
                                        <Space style={{padding: '0 8px 4px'}}>
                                            <Input
                                                placeholder="Модель"
                                                value={name}
                                                onChange={(event: any) => {
                                                    setName(event.target.value);
                                                }}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            <Button
                                                type="text"
                                                icon={<PlusOutlined/>}
                                                onClick={addItem}
                                                loading={loading}
                                            >
                                                Создать модель
                                            </Button>
                                        </Space>
                                    </>
                                )}
                        />
                    </Flex>
                </Form.Item>
            </>
        )
    }

}

