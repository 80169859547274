import React from "react";
import {Affix, Button, Card, Flex, Tag} from "antd";
import i18n from "i18next";
import {DeleteBulkAction} from "./Delete"
import {BulkActionMenu} from "./BukActionMenu";

interface SelectedProps {
    allSelected: boolean,
    id: string[],
    count: number
}

interface Props {
    selectedState: SelectedProps,
    collectionId: string,
    successCallback: () => void
    appId: string
}

export const BulkActions: React.FC<Props> = (props) => {
    const [openMenu, setOpenMenu] = React.useState(false)
    const onClose = () => {
        setOpenMenu(false)
    }
    const onOpen = () => {
        setOpenMenu(true)
    }
    return <>
        {(props.selectedState.allSelected || props.selectedState.id.length > 0) &&
            <>
                <Affix offsetBottom={0} style={{marginTop: 20}}>
                    <Card>
                        <Flex align={"center"} justify={"space-between"}>
                            <Flex gap={"middle"} align={"center"}>
                            <span>{i18n.t("common.count")}:
                                <Tag color={"geekblue"} style={{marginLeft: 5}}>{props.selectedState.count}</Tag>
                            </span>
                                <div>
                                    <Button type={"primary"} onClick={onOpen}>{i18n.t("common.bulk-actions")}</Button>
                                </div>
                            </Flex>
                            <DeleteBulkAction {...props}/>
                        </Flex>
                    </Card>
                </Affix>
                {openMenu && <BulkActionMenu key={"bulk-actions"} open={openMenu} collectionId={props.collectionId}
                                             appId={props.appId} onClose={onClose}
                                             selectedState={props.selectedState}
                                             successCallback={props.successCallback}
                />}
            </>
        }
    </>
}
export type {Props}