import * as React from "react";
import {Result} from 'antd'
import {Permission} from "kaiju.core";
import user from "src/components/User"
import i18n from "i18next";

interface Interface {
    children: any;
    permissions: string[];
    root: any;
}

export const isPermitted = (root: any, permissions: string[]) => {
    const forCheck = permissions.map((v: string) => (v + "___" + root))
    forCheck.push("system")
    return permissions.length? forCheck.some(v => user.permissions.includes(v)) : true
}

export const Permission403 = ({permissions, root, children}: Interface) => {
    const _isPermitted = isPermitted(root, permissions)
    return (
        <>
            {_isPermitted && children}
            {!_isPermitted && <>
                <Result
                    status="403"
                    title={<h1>403</h1>}
                    subTitle={<h2>{i18n.t("permission.403")}</h2>}
                />
            </>}
        </>
    )
};

export const Permissions = ({permissions, root, children}: Interface) => {
    const forCheck = permissions.map((v: string) => (v + "___" + root))
    forCheck.push("system")
    return <Permission userPermission={user.permissions} permission={forCheck}>{children}</Permission>
}