import React from "react";
import {Button, Drawer, Space, Tabs} from "antd"
import i18n from "i18next";
import {MediaLibraryFolder} from "src/components/MediaLibraryFolder";
import {AssetCheckedViewer} from "src/components/MediaLibraryFolder/AssetView";
import type {StoreInterface} from "src/components/MediaLibraryFolder/store";

interface MediaLibSelectorProps {
    title: string,
    mediaId: string,
    onSave: any,
    store: StoreInterface,
    getValues: () => string[]
}


export const MediaLibSelector: React.FC<MediaLibSelectorProps> = ({
                                                                      title,
                                                                      mediaId,
                                                                      onSave,
                                                                      store,
                                                                      getValues
                                                                  }) => {
    const [open, setOpen] = React.useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        store.setCheckedValues(getValues())

    };

    const items: any[] = [
        {
            key: 'browse',
            label: i18n.t("common.browse"),
            children: <MediaLibraryFolder key={"media-lib-" + mediaId} title={"Home"} store={store}/>,
        },
        {
            key: 'selected',
            label: i18n.t("common.selected"),
            children: <AssetCheckedViewer key={"asset-ch-" + mediaId} store={store}/>
        },
    ];

    return (<>
            <Button style={{marginTop: 20}} type={"primary"} onClick={onOpen}>{i18n.t("media.choice")}</Button>
            <Drawer
                open={open}
                onClose={onClose}
                width={1010}
                title={title}
                extra={
                    <Space>
                        <Button onClick={() => {
                            onSave()
                            setOpen(false);
                        }} type="primary">
                            {i18n.t("common.save")}
                        </Button>
                    </Space>
                }
            >
                <Tabs defaultActiveKey={store.checkedValues?.length === 0 ? "browse" : "selected"} items={items}/>
            </Drawer>

        </>
    )
}